import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import Info from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import Tables from '../../Components/Tables/esic';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [montarTela, setMontarTela] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(false);

    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        socilitacoes();
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      setLoading(true)
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        setLoading(false)
        if(result.data.role === 'usuario'){
          setMontarTela(true)
        }else{
          history.push('/');
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    const handleOpen = (value) => {
      setOpen(true);
      if(value === 1){
        setInfo(value)
      }else if(value === 2){
        setInfo(value)
      }
    };

    const handleClose = () => {
      setOpen(false);
    };

    const body = (
      <div className={"paperModal"}>
        <div className="simple-modal-title">
          {info === 1 ? "Como fazer o cadastro" : "Como fazer um pedido"}
          <Cancel onClick={handleClose} style={{color: '#fff', cursor: 'pointer'}} color="action" />
        </div>
        <div className="simple-modal-conteudo">
          {/* {info === 1 && (
            <>
              <p>
                1. Entre na página do Lei de Acesso e clique no link "Cadastre-se", localizado à direita da página, dentro da caixa de login;
              </p>
              <p>
                2. A tela seguinte é a de cadastro do solicitante, nela o usuário irá preencher os dados pessoais e em seguida clicar no botão salvar ao final da página para que o seu cadastro seja efetuado;
              </p>
              <p>
                3. O usuário receberá uma notificação via e-mail, portanto é de suma importância que na tela de cadastro de solicitante o usuário insira um e-mail válido. Obs: Ao entrar na caixa de entrada do seu webmail se não encontrar o e-mail de confirmação, entre na caixa “Lixo” se você inseriu todos os dados de forma correta possivelmente o e-mail de confirmação poderá estar lá;
              </p>
              <p>
                4. No e-mail de confirmação, estará contido um link no qual o usuário deverá clicar, para que só assim seu cadastro seja validado;
              </p>
              <p>
                5. O link que o solicitante clicou no passo anterior irá redirecioná-lo (em nova janela) para a página confirmação de cadastro;
              </p>
            </>
          )} */}
          {info === 2 && (
            <>
              <p>
                1. Acesse a aba "Fazer Solicitação" e descreva detalhadamente seu pedido;
              </p>
              <p>
                2. Abaixo, escolha a forma de retorno que deseja apos informar seu pedido;
              </p>
              <p>
                3. Apos descrever sua solicitação e definir a forma de retorno, o usuário deve clicar em enviar para registrar sua solicitação, uma tela de confimação deve aparecer;
              </p>
            </>
          )}
        </div>
      </div>
    );

    async function socilitacoes() {
        setLoading(true)
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('esic', config);
            console.log('Listar todos os esic', result);
            setLoading(false)
            setData(result);
        } catch (error) {
            setLoading(false)
            console.log('Erro ao ao listar pesquisa do esic', error, error.response);
        }
    }
    async function searchEsic(event) {
        setLoading(true)
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            setLoading(false)
            const { data: result } = await api.get(`/search-esic/${event.target.value}`, config);
            console.log('Resultado da pesquisa de esic', result);
            setData(result);
        } catch (error) {
            setLoading(false)
            console.log('Erro ao deletar', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado) {
        return { id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.protocolo_esic, 
              row.orgao.nome_orgao, 
              row.forma_recebimento_esic, 
              row.status_esic, 
              row.identidade_preservada,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'forma_recebimento_esic', numeric: true, disablePadding: false, label: 'Recebimento' },
        { id: 'status_esic', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            {montarTela && (
              <>
                <Header />
                <main className="content">
                    <div className="appBarSpacer" />
                    <Container maxWidth="lg" className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        className="linkBreadcrumbs"
                                        color="inherit"
                                        href="/"
                                        onClick={handleClick}>
                                        Dashboard
                                    </Link>
                                    {/* <Link
                                        className="linkBreadcrumbs"
                                        color="inherit"
                                        href="/getting-started/installation/"
                                        onClick={handleClick}>
                                        Core
                                    </Link> */}
                                    <Typography variant="body2" color="textPrimary">
                                        e-Sic
                                    </Typography>
                                </Breadcrumbs>
                                <div className="headerPage">
                                    <h2>Acesso à Informação</h2>
                                    {/* <Button className="button-add" variant="contained" color="primary">
                                        Novo
                                    </Button> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={'paper'}>
                                    <div className="btnAcoes">
                                      <section onClick={() => {rota('/nova-solicitacao')}}>
                                        <div>
                                          <Edit style={{color:"#fff", fontSize: 30}} />
                                        </div>
                                        <div>Fazer Solicitação</div>
                                      </section>
                                      <section>
                                        <div>
                                          <Info style={{color:"#fff", fontSize: 30}} />
                                        </div>
                                        <div>Lei de Acesso</div>
                                      </section>
                                      <section onClick={() => {handleOpen(2)}}>
                                        <div>
                                          <Info style={{color:"#fff", fontSize: 30}} />
                                        </div>
                                        <div>Como fazer um pedido</div>
                                      </section>
                                      
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={'paperSearch'}>
                                    <div className="paperHeader">
                                        <Typography variant="subtitle1" gutterBottom>
                                          Acompanhar Solicitação
                                        </Typography>
                                    </div>
                                    <TextField
                                        className="textField"
                                        label="Digite o protocolo da sua solicitação!"
                                        variant="outlined"
                                        type="number"
                                        onChange={searchEsic}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                  <>
                                    {data.length !== 0 && (
                                      <Paper className={'paper'}>
                                          <div className="paperHeader">
                                              <Typography variant="subtitle1" gutterBottom>
                                                  Suas solicitações
                                              </Typography>
                                          </div>
                                          <div className="paperContant">
                                            <Tables columns={columns} rows={rows} roles={roles} />
                                          </div>
                                      </Paper>
                                    )}
                                    {data.length === 0 && (
                                      <Paper className={'paper'}>
                                          <div className="paperContantDois">
                                              <div>Nenhum registro encontrado</div>
                                          </div>
                                      </Paper>
                                    )}
                                  </>
                                )}
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <Footer />
                        </Box>
                    </Container>
                    {loading && (
                      <div className="loading">
                        <CircularProgress />
                      </div>
                    )}
                </main>
              </>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              className={"modal"}
            >
              {body}
            </Modal>
        </div>
    );
}
