import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import TableFuncionario from '../../Components/Tables/funcionario-site';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Breadcrumbs } from '@material-ui/core';
import { CSVLink, CSVDownload } from "react-csv";
import ReactExport from "react-export-excel";

import Funcionarios from '../../Components/PDF/Funcionarios';


// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
  title: 'Salários dos Servidores',
  // description:
  //     "A Ouvidoria é um canal para você apresentar manifestações como reclamações, denúncias, solicitações e sugestões.",
  image: 'https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png',
  imgText: 'main image description',
};

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openResetSenha, setOpenResetSenha] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [roles, setRoles] = useState('');
    const [nome, setNome] = useState('Nome');
    const [mes, setMes] = useState(6);
    // const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [situacao, setSituacao] = useState('');
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [total, setTotal] = useState(0);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let history = useHistory();
    // const classes = useStyles();

    useEffect(() => {
      carregarFuncionarios(ano, mes, nome);
      //
    }, []);

    const visualizarImpressao = (val) => {
      console.log(val)
    }

    function filtrar(){
      ///:licitacao/:situacao/:ano/:modalidade/:tipo
      carregarFuncionarios(ano, mes, nome === 'Nome' ? 'Nome': encodeURI(nome))
    }

    function handleChangeNome(event){
      setNome(event.target.value);
    }
    function handleChangeModalidade(event){
      setModalidade(event.target.value);
    }
    function handleChangeTipo(event){
      setTipo(event.target.value);
    }
    function handleChangeMes(event){
      setMes(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeSituacao(event){
      setSituacao(event.target.value);
    }

    function gerarAno(t, v){
      const result = new Date().getFullYear();
      var result2
      if(t === 'add'){
        result2 = parseInt(result) + v;
      }else{
        result2 = parseInt(result) - v;
      }
      return result2; 
    }

    async function carregarFuncionarios(anoV, mesV, nomeV, pagina = page, porPagina = perPage) {
      ///:licitacao/:situacao/:ano/:modalidade/:tipo
      console.log('anoV', mesV, nomeV, pagina, porPagina)
      setLoading(true)
      try {
          const { data: result } = await api.get(`funcionario/${anoV}/${mesV}/${nomeV}/${pagina}/${porPagina}`);
          console.log('Result Funcionários', result);
          setData(result.data);
          setLastPage(result.lastPage);
          setPage(result.page);
          setTotal(result.total);
          setLoading(false)
      } catch (error) {
          console.log('Erro ao buscar funcionarios', error, error.response);
          setLoading(false)
      }
    }

    function mascararCPF(cpfV){
      var necValue = cpfV.split('');
      var cpfMontato = '****'+necValue[4]+necValue[5]+necValue[6]+necValue[7]+'***'
      return cpfMontato
    }

    function chamou(newPagina, newPerPage){
      // console.log("newPagina, newPerPage ", newPagina, newPerPage)
      carregarFuncionarios(ano, mes, nome, newPagina, newPerPage)
    }

    //table
    function createData(
      id,
        funcionario_cargo,
        funcionario_cpf,
        funcionario_lotacao,
        funcionario_descontos,
        funcionario_funcao,
        funcionario_liquido,
        funcionario_matricula,
        funcionario_nome,
        funcionario_proventos,
    ) {
        return { 
          id,
          funcionario_cargo,
          funcionario_cpf,
          funcionario_lotacao,
          funcionario_descontos,
          funcionario_funcao,
          funcionario_liquido,
          funcionario_matricula,
          funcionario_nome,
          funcionario_proventos,
        };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.funcionario_cargo, 
              row.funcionario_cpf,
              row.funcionario_lotacao, 
              row.funcionario_descontos, 
              row.funcionario_funcao,
              row.funcionario_liquido,
              row.funcionario_matricula,
              row.funcionario_nome,
              row.funcionario_proventos,
            );
        });
    }

    const columns = [
        { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
        { id: 'funcionario_cargo', numeric: false, disablePadding: true, label: 'Cargo/Função' },
        { id: 'funcionario_cpf', numeric: true, disablePadding: false, label: 'CPF' },
        { id: 'funcionario_lotacao', numeric: true, disablePadding: false, label: 'Lotação' },
        { id: 'funcionario_proventos', numeric: true, disablePadding: false, label: 'R$ Proventos' },
        { id: 'funcionario_descontos', numeric: true, disablePadding: false, label: 'R$ Desconto' },
        { id: 'funcionario_liquido', numeric: true, disablePadding: false, label: 'R$ Liquído' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    function rota(rota){
      history.push(rota);
    }
 
    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <span color="textPrimary">Salários dos Servidores</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperConteudo">
                            <Grid style={{background: '#f7f7f7', padding: 10}} container spacing={3}>
                              <Grid item xs={12} md={8}>
                                <div>
                                  <a href='https://pinheiro.ma.gov.br/padrao-remuneratorio-dos-cargos-e-funcoes/' target={'_blank'}>Tabela com o padrão remuneratório dos cargos e funções</a>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid style={{background: '#f7f7f7', padding: 10}} container spacing={3}>
                              <Grid item xs={12} md={6}>
                                <TextField 
                                fullWidth 
                                id="outlined-funcionario" 
                                label="Nome do Funcionário" 
                                variant="outlined" 
                                value={nome === 'Nome' ? '' : nome} 
                                onChange={handleChangeNome} 
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="ano-label">Ano</InputLabel>
                                  <Select
                                    labelId="ano-label"
                                    id="ano-label"
                                    value={ano}
                                    onChange={handleChangeAno}
                                    label="Ano"
                                  >
                                    {/* <MenuItem value={'v'}>Todos</MenuItem> */}
                                    <MenuItem value={gerarAno('add', 0)}>{gerarAno('add', 0)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 1)}>{gerarAno('sub', 1)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 2)}>{gerarAno('sub', 2)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 3)}>{gerarAno('sub', 3)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 4)}>{gerarAno('sub', 4)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 5)}>{gerarAno('sub', 5)}</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="mes-label">Mês</InputLabel>
                                  <Select
                                    labelId="mes-label"
                                    id="mes-label"
                                    value={mes}
                                    onChange={handleChangeMes}
                                    label="Ano"
                                  >
                                    {/* <MenuItem value={'v'}>Todos</MenuItem> */}
                                    <MenuItem value={1}>Jan</MenuItem>
                                    <MenuItem value={2}>Fev</MenuItem>
                                    <MenuItem value={3}>Mar</MenuItem>
                                    <MenuItem value={4}>Abr</MenuItem>
                                    <MenuItem value={5}>Mai</MenuItem>
                                    <MenuItem value={6}>Jun</MenuItem>
                                    <MenuItem value={7}>Jul</MenuItem>
                                    <MenuItem value={8}>Ago</MenuItem>
                                    <MenuItem value={9}>Set</MenuItem>
                                    <MenuItem value={10}>Out</MenuItem>
                                    <MenuItem value={11}>Nov</MenuItem>
                                    <MenuItem value={12}>Dez</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                  <Button onClick={() => {filtrar()}} variant="outlined" color="primary">
                                    Consultar
                                  </Button>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <div style={{display: 'flex', height: '100%', alignItems: 'center', flexDirection: 'row'}}>
                                  <CSVLink style={{fontStyle: 'none', textDecoration: 'none', background: 'gray', color: 'white', padding: 10, height: 40, cursor: 'pointer'}} data={data}>SCV</CSVLink>
                                  <ExcelFile element={<button style={{fontStyle: 'none', textDecoration: 'none', background: 'gray', color: 'white', padding: 10, border: 'none', height: 40, marginLeft: 5, cursor: 'pointer'}} data={data}>EXCEL</button>}>
                                    <ExcelSheet data={data} name="Employees">
                                        <ExcelColumn label="Nome" value="funcionario_nome"/>
                                        <ExcelColumn label="Cargo" value="funcionario_cargo"/>
                                        <ExcelColumn label="Função" value="funcionario_funcao"/>
                                        <ExcelColumn label="CPF" value={(col) => mascararCPF(col.funcionario_cpf)}/>
                                        <ExcelColumn label="Lotação" value="funcionario_lotacao"/>
                                        <ExcelColumn label="R$ Proventos" value="funcionario_proventos"/>
                                        <ExcelColumn label="R$ Desconto" value="funcionario_descontos"/>
                                        <ExcelColumn label="R$ Liquído" value="funcionario_liquido"/>
                                    </ExcelSheet>
                                  </ExcelFile>
                                  <button style={{fontStyle: 'none', textDecoration: 'none', background: 'gray', color: 'white', padding: 10, border: 'none', height: 40, marginLeft: 5, cursor: 'pointer'}} onClick={() => {Funcionarios(data)}}>
                                    PDF
                                  </button>
                                </div>
                              </Grid>
                            </Grid>
                            {rows.length != 0 && (
                              <TableFuncionario 
                              columns={columns}
                              rows={rows} 
                              roles={roles} 
                              lastPage={lastPage}
                              page={page}
                              perPage={perPage}
                              total={total}
                              chamou={chamou}
                              />
                            )}
                            {rows.length === 0 && (
                              <div className="nadaEncontrado">
                                <p style={{fontWeight: 'bold'}}>Nenhuma licitação foi encontrada</p>
                                <p style={{color: 'green'}}>Tente outros termos na consulta</p>
                              </div>
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                </main>
                
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
                <span>Carregando Funcionários</span>
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
