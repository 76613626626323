import axios from 'axios';
export const linkApi = 'https://webativa.com.br/apipinheiro';
// export const linkApi = 'http://127.0.0.1:3333';
const api = axios.create({
    baseURL: 'https://webativa.com.br/apipinheiro',
    // baseURL: 'http://webapp248175.ip-45-79-39-83.cloudezapp.io',
    // baseURL: 'http://127.0.0.1:3333',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export default api;
