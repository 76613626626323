import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/global.css';
/* eslint-disable */
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Visibility from '@material-ui/icons/Visibility';
import Refresh from '@material-ui/icons/Refresh';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos orgaos', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = props.columns

    const rows = props.rows

    const roles = props.roles

    function verFatura(){
      alert("Em desenvolvimento")
    }

    function verSolicitacao(id){
      // history.push('/fatura-create');
      if(props.roles === 'usuario'){
        history.push({
            pathname: '/ver-solicitacao',
            // search: '?query=abc',
            state: { type: 'view', id: id }
        });
      }else if(props.roles === 'ouvidoria'){
        history.push({
            pathname: '/portal/admin/esic/solicitacao',
            // search: '?query=abc',
            state: { type: 'view', id: id }
        });
      }
      
    }
    
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/fatura/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Fatura deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <Paper className={'paper'}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    align={key === 3 || key >= 4 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell component="th" scope="row">
                                              {row.protocolo_esic}
                                          </TableCell>
                                          <TableCell align="left">{row.orgao}</TableCell>
                                          <TableCell align="left">{row.recebimento}</TableCell>
                                          {row.status === 'Aberto' &&(
                                            <TableCell align="right">
                                              <Chip style={{background: 'blue', color:'white'}} label="Aberto" />
                                            </TableCell>
                                          )}
                                          {row.status === 'Respondido' &&(
                                            <TableCell align="right">
                                              <Chip style={{background:'green', color: 'white'}} label="Atendido" />
                                            </TableCell>
                                          )}
                                          {row.status === 'Indeferido' &&(
                                            <TableCell align="right">
                                              <Chip style={{background:'red', color: 'white'}}  label="Indeferido" />
                                            </TableCell>
                                          )}

                                          {row.preservado === 'sim' &&(
                                            <TableCell align="right"> <Chip style={{background: 'red', color:'white'}} label="Sim" /></TableCell>
                                          )}
                                          {row.preservado === 'nao' &&(
                                            <TableCell align="right"> <Chip style={{background: 'green', color:'white'}} label="Não" /></TableCell>
                                          )}
                                          <TableCell align="right">
                                            <div>Criado: { format(new Date(row.criado), 'dd/MM/yyyy')} </div>
                                            {format(new Date(row.criado), 'dd/MM/yyyy') != format(new Date(row.atualizado), 'dd/MM/yyyy') && (
                                              <div>Atualizado: { format(new Date(row.atualizado), 'dd/MM/yyyy')} </div>
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            <Visibility className="btnView" onClick={() => verSolicitacao(row.id)} />
                                          </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Paper>
    );
}
