import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import TableLicitacao from '../../Components/Tables/licitacaoSite';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Breadcrumbs } from '@material-ui/core';
// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
  title: 'Licitações',
  // description:
  //     "A Ouvidoria é um canal para você apresentar manifestações como reclamações, denúncias, solicitações e sugestões.",
  image: 'https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png',
  imgText: 'main image description',
};

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openResetSenha, setOpenResetSenha] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState('');
    const [licitacaoNu, setLicitacaoNu] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [situacao, setSituacao] = useState('');

    let history = useHistory();
    // const classes = useStyles();

    useEffect(() => {
      carregarLicitacoes('v', 'v', 'v', 'v', 'v');
      //
    }, []);

    function filtrar(){
      ///:licitacao/:situacao/:ano/:modalidade/:tipo
      carregarLicitacoes(
        licitacaoNu ? encodeURI(licitacaoNu) : 'v', 
        situacao ? encodeURI(situacao) : 'v',
        ano ? encodeURI(ano) : 'v',
        modalidade ? encodeURI(modalidade): 'v',
        tipo ? encodeURI(tipo) : 'v'
      )
    }

    function handleChangeLicitacao(event){
      setLicitacaoNu(event.target.value);
    }
    function handleChangeModalidade(event){
      setModalidade(event.target.value);
    }
    function handleChangeTipo(event){
      setTipo(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeSituacao(event){
      setSituacao(event.target.value);
    }

    function gerarAno(t, v){
      const result = new Date().getFullYear();
      var result2
      if(t === 'add'){
        result2 = parseInt(result) + v;
      }else{
        result2 = parseInt(result) - v;
      }
      return result2; 
    }

    async function carregarLicitacoes(licitacaoV, situacaoV, anoV, modalidadeV, tipoV) {
      ///:licitacao/:situacao/:ano/:modalidade/:tipo
      setLoading(true)
      try {
          const { data: result } = await api.get(`search-licitacao-site/${licitacaoV}/${situacaoV}/${anoV}/${modalidadeV}/${tipoV}`);
          console.log('Result licitações', result);
          setData(result);
          setLoading(false)
      } catch (error) {
          console.log('Erro ao deletar', error, error.response);
          setLoading(false)
      }
    }

    //table
    function createData(
      id, 
      licitacao_situacao, 
      licitacao_numero_documento, 
      licitacao_ano, 
      licitacao_modalidade, 
      licitacao_tipo, 
      licitacao_data_abertura, 
      licitacao_data_fechamento,
      licitacao_local,
      licitacao_valor,
      licitacao_descricao,
      criado, 
      atualizado
    ) {
        return { 
          id, 
          licitacao_situacao, 
          licitacao_numero_documento, 
          licitacao_ano, 
          licitacao_modalidade, 
          licitacao_tipo, 
          licitacao_data_abertura, 
          licitacao_data_fechamento,
          licitacao_local,
          licitacao_valor,
          licitacao_descricao,
          criado, 
          atualizado 
        };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.licitacao_situacao, 
              row.licitacao_numero_documento, 
              row.licitacao_ano, 
              row.licitacao_modalidade, 
              row.licitacao_tipo,
              row.licitacao_data_abertura,
              row.licitacao_data_fechamento,
              row.licitacao_local,
              row.licitacao_valor,
              row.licitacao_descricao,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'doc', numeric: false, disablePadding: true, label: 'Licitação' },
        { id: 'modalidade', numeric: false, disablePadding: true, label: 'Modalidade' },
        { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
        // { id: 'local', numeric: false, disablePadding: true, label: 'Local' },
        { id: 'licitacao_situacao', numeric: false, disablePadding: true, label: 'Situação' },
        { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data abertura' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];

    function rota(rota){
      history.push(rota);
    }
 
    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <span color="textPrimary">Licitações</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperConteudo">
                            <Grid style={{background: '#f7f7f7', padding: 10}} container spacing={3}>
                              <Grid item xs={12} md={2}>
                                <TextField fullWidth id="outlined-licitacao" label="LIcitação" variant="outlined" value={licitacaoNu} onChange={handleChangeLicitacao} />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="situacao-label">Situação</InputLabel>
                                  <Select
                                    labelId="situacao-label"
                                    id="situacao-label"
                                    value={situacao}
                                    onChange={handleChangeSituacao}
                                    label="Situação"
                                  >
                                    <MenuItem value={'v'}>Todos</MenuItem>
                                    <MenuItem value={'Aberta'}>Aberta</MenuItem>
                                    <MenuItem value={'Fechada'}>Fechada</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="ano-label">Ano</InputLabel>
                                  <Select
                                    labelId="ano-label"
                                    id="ano-label"
                                    value={ano}
                                    onChange={handleChangeAno}
                                    label="Ano"
                                  >
                                    <MenuItem value={'v'}>Todos</MenuItem>
                                    <MenuItem value={gerarAno('add', 0)}>{gerarAno('add', 0)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 1)}>{gerarAno('sub', 1)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 2)}>{gerarAno('sub', 2)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 3)}>{gerarAno('sub', 3)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 4)}>{gerarAno('sub', 4)}</MenuItem>
                                    <MenuItem value={gerarAno('sub', 5)}>{gerarAno('sub', 5)}</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="modalidade-label">Modalidade</InputLabel>
                                  <Select
                                    labelId="modalidade-label"
                                    id="modalidade-label"
                                    value={modalidade}
                                    onChange={handleChangeModalidade}
                                    label="Modalidade"
                                  >
                                    <MenuItem value={'v'}>Todos</MenuItem>
                                    <MenuItem value={'Carta Convite'}>Carta Convite</MenuItem>
                                    <MenuItem value={'Concorrência'}>Concorrência</MenuItem>
                                    <MenuItem value={'Concurso'}>Concurso</MenuItem>
                                    <MenuItem value={'Dispensa'}>Dispensa</MenuItem>
                                    <MenuItem value={'Credenciamento'}>Credenciamento</MenuItem>
                                    <MenuItem value={'Inexigibilidade'}>Inexigibilidade</MenuItem>
                                    <MenuItem value={'Leilão'}>Leilão</MenuItem>
                                    <MenuItem value={'Pregão Eletrônico'}>Pregão Eletrônico</MenuItem>
                                    <MenuItem value={'Pregão Presencial'}>Pregão Presencial</MenuItem>
                                    <MenuItem value={'Tomada de Preços'}>Tomada de Preços</MenuItem>
                                    <MenuItem value={'Chamada Pública'}>Chamada Pública</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel id="tipo-label">Tipo</InputLabel>
                                  <Select
                                    labelId="tipo-label"
                                    id="tipo-label"
                                    value={tipo}
                                    onChange={handleChangeTipo}
                                    label="Tipo"
                                  >
                                    <MenuItem value={'v'}>Todos</MenuItem>
                                    <MenuItem value={'Menor Preço'}>Menor Preço</MenuItem>
                                    <MenuItem value={'Melhor Técnica'}>Melhor Técnica</MenuItem>
                                    <MenuItem value={'Técnica e Preço'}>Técnica e Preço</MenuItem>
                                    <MenuItem value={'Maior lance ou Oferta'}>Maior lance ou Oferta</MenuItem>
                                    <MenuItem value={'Por Item'}>Por Item</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                  <Button onClick={filtrar} variant="outlined" color="primary">
                                    Consultar
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                            {rows.length != 0 && (
                              <TableLicitacao columns={columns} rows={rows} roles={roles} />
                            )}
                            {rows.length === 0 && (
                              <div className="nadaEncontrado">
                                <p style={{fontWeight: 'bold'}}>Nenhuma licitação foi encontrada</p>
                                <p style={{color: 'green'}}>Tente outros termos na consulta</p>
                              </div>
                            )}
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                </main>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
                <span>Carregando licitações</span>
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
