import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function mascararCPF(cpfV) {
    var necValue = cpfV.split('');
    var cpfMontato = '****' + necValue[4] + necValue[5] + necValue[6] + necValue[7] + '***';
    return cpfMontato;
}

function Funcionarios(clientes) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Salários dos Servidores',
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45] // left, top, right, bottom
        }
    ];

    const dados = clientes.map((cliente) => {
        return [
            { text: cliente.funcionario_nome, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_cargo, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_funcao, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: mascararCPF(cliente.funcionario_cpf), fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_lotacao, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_proventos, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_descontos, fontSize: 9, margin: [0, 2, 0, 2] },
            { text: cliente.funcionario_liquido, fontSize: 9, margin: [0, 2, 0, 2] }
        ];
    });

    const details = [
        {
            table: {
                headerRows: 1,
                widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
                body: [
                    [
                        { text: 'Nome', style: 'tableHeader', fontSize: 10 },
                        { text: 'Cargo', style: 'tableHeader', fontSize: 10 },
                        { text: 'Função', style: 'tableHeader', fontSize: 10 },
                        { text: 'CPF', style: 'tableHeader', fontSize: 10 },
                        { text: 'Lotação', style: 'tableHeader', fontSize: 10 },
                        { text: 'R$ Provento', style: 'tableHeader', fontSize: 10 },
                        { text: 'R$ Des.', style: 'tableHeader', fontSize: 10 },
                        { text: 'R$ Liq.', style: 'tableHeader', fontSize: 10 }
                    ],
                    ...dados
                ]
            },
            layout: 'lightHorizontalLines' // headerLineOnly
        }
    ];

    function Rodape(currentPage, pageCount) {
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ];
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],
        pageOrientation: 'landscape',

        header: [reportTitle],
        content: [details],
        footer: Rodape
    };

    pdfMake.createPdf(docDefinitios).download();
}

export default Funcionarios;
