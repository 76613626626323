import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Info from '@material-ui/icons/Info';
import Hearing from '@material-ui/icons/Hearing';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Handshake from '@material-ui/icons/HdrWeakRounded';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CardMedia from '@material-ui/core/CardMedia';
// import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        height: 250,
        position: 'relative',
        '--color-1': '#0ea759',
        '--color-2': '#016130',
        background: `
          linear-gradient(
            170deg,
            var(--color-1),
            var(--color-2) 80%
          )
        `,
        '&:hover' :{
          cursor: 'pointer',
          background: 'red',
          '--color-1': '#f50057',
          '--color-2': '#610022',
          background: `
            linear-gradient(
              170deg,
              var(--color-1),
              var(--color-2) 80%
            )
          `,
          '& svg' :{
            color: '#610022'
          }
        },
        '& svg' :{
          color: '#016130'
        }
    },
    cardDetails: {
        flex: 1,
        position: 'absolute',
        bottom: 0,

        '& h2':{
          color: '#fff',
          fontWeight: 'bold'
        },
        '& p':{
          color: '#fff',
        },
    },
    // cardDetailsText:{
    //   background: 'red'
    // },
    icon: {
        flex: 1,
        position: 'absolute',
        right: 0,
        top: 0,
        fontSize: 100,
        // color: '#00391c'
    },

});

export default function FeaturedPost(props) {
    const classes = useStyles();
    const { post } = props;

    function folhadepagamento(){
      alert('Este serviço está em desenvolvimento!')
      // toast.infor('🦄 Este serviço está em desenvolvimento!', {
      //   position: "bottom-left",
      //   autoClose: 6000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }

    return (
      <>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="http://cm.cloudpjd.com.br:8095/pmpinheiro/Default.aspx?AcessoIndividual=lnkReceitaOrcamentaria">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Receitas'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Tenha acesso a todos os recebimentos do município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="http://cm.cloudpjd.com.br:8095/pmpinheiro/Default.aspx?AcessoIndividual=lnkDespesasPor_NotaEmpenho">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Despesas'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Tenha acesso a todos os gastos do município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://licitacoes.pinheiro.ma.gov.br/contratos">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Contratos'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Tenha acesso a todos os contratos do município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="http://cm.cloudpjd.com.br:8095/pmpinheiro/Default.aspx?AcessoIndividual=lnkDiarias">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Diárias'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Diárias dos servidores'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
              <Card className={classes.card} onClick={() => props.rota('/esic')}>
                  <Info className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'e-Sic'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'O Serviço de Informações ao Cidadão (e-SIC) Online.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/sic">
              <Card className={classes.card} >
                  <Info className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Sic'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'O Serviço de Informações ao Cidadão (SIC) presencial'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/ouvidoria/">
              <Card className={classes.card}>
                  <Hearing className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Ouvidoria'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'A Ouvidoria é um canal para você apresentar manifestações como reclamações, denúncias, solicitações e sugestões.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
              <Card className={classes.card} onClick={() => props.rota('/salarios-servidores')}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Salários dos Servidores'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Consulte aqui as informações e dados sobre a folha de pagamento da Prefeitura.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <a target="_blank" href="https://licitacoes.pinheiro.ma.gov.br/licitacoes">
              {/* <Card className={classes.card} onClick={() => props.rota('/licitacoes')}> */}
              <Card className={classes.card} >
                  <Info className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Licitações'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Consulte aqui todas as licitações do Município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a href="/perguntas-respostas">
              <Card className={classes.card}>
                  <ContactSupportIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Perguntas e respostas'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Listamos aqui as principais perguntas com suas respostas.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="http://pinheiro.ma.gov.br/organograma/">
              <Card className={classes.card}>
                  <AccountTreeIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Estrutura do Governo'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Toda a estrutura governamental do município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="http://nfse2.adtrcloud.com.br:8088/issweb/home.jsf">
              <Card className={classes.card}>
                  <DescriptionIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Nota Fiscal Eletrônica'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Emissor de Nota Fiscal Eletrônica do município.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/portal-servidor/">
              <Card className={classes.card}>
                  <DescriptionIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Portal do Servidor'}
                          </Typography>
                          <Typography variant="subtitle1" paragraph>
                              {'Emissão e Impressão de Holerites.'}
                          </Typography>
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/padrao-remuneratorio-dos-cargos-e-funcoes/">
              <Card className={classes.card}>
                  <DescriptionIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Padrão remuneratório dos cargos e funções'}
                          </Typography>
                          {/* <Typography variant="subtitle1" paragraph>
                              {''}
                          </Typography> */}
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/demonstrativos-fiscais-lrf/">
              <Card className={classes.card}>
                  <DescriptionIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Demonstrativos Fiscais - LRF'}
                          </Typography>
                          {/* <Typography variant="subtitle1" paragraph>
                              {''}
                          </Typography> */}
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://portaldatransparencia.gov.br/convenios/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=desc&periodoLiberacaoRecursosDe=01%2F01%2F2016&periodoLiberacaoRecursosAte=31%2F07%2F2023&periodoVigenciaDe=01%2F01%2F2016&periodoVigenciaAte=31%2F07%2F2023&periodoPublicacaoDe=01%2F01%2F2016&periodoPublicacaoAte=31%2F07%2F2023&uf=MA&nomeMunicipio=Pinheiro&colunasSelecionadas=linkDetalhamento%2CnumeroConvenio%2Cuf%2CmunicipioConvenente%2Csituacao%2CtipoTransferencia%2Cobjetivo%2CorgaoSuperior%2Corgao%2Cconcedente%2Cconvenente%2CdataInicioVigencia%2CdataFimVigencia%2CdataPublicacao%2CvalorLiberado%2CvalorCelebrado&ordenarPor=orgao&direcao=desc">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Convênios'}
                          </Typography>
                          {/* <Typography variant="subtitle1" paragraph>
                              {''}
                          </Typography> */}
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://portaldatransparencia.gov.br/transferencias/consulta?paginacaoSimples=true&tamanhoPagina=&offset=&direcaoOrdenacao=asc&de=01%2F01%2F2023&ate=31%2F08%2F2023&uf=MA&nomeMunicipio=Pinheiro&colunasSelecionadas=linkDetalhamento%2Cuf%2Cmunicipio%2Ctipo%2CtipoFavorecido%2Cacao%2ClinguagemCidada%2CgrupoDespesa%2CelementoDespesa%2CmodalidadeDespesa%2Cvalor">
              <Card className={classes.card}>
                  <MonetizationOnIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Transferências de Recursos'}
                          </Typography>
                          {/* <Typography variant="subtitle1" paragraph>
                              {''}
                          </Typography> */}
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} >
          <Paper>
            <a target="_blank" href="https://pinheiro.ma.gov.br/tabela-de-diarias/">
              <Card className={classes.card}>
                  <DescriptionIcon className={classes.icon} color="action" />
                  <div className={classes.cardDetails}>
                      <CardContent>
                          <Typography className={classes.cardDetailsText} component="h2" variant="h5">
                              {'Tabela de Diárias'}
                          </Typography>
                          {/* <Typography variant="subtitle1" paragraph>
                              {''}
                          </Typography> */}
                      </CardContent>
                  </div>
              </Card>
            </a>
          </Paper>
        </Grid>
        <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
      </>
      
        // <Grid item xs={12} md={4}>
        //     <CardActionArea component="a" href="#">
        //         <Card className={classes.card}>
        //             <div className={classes.cardDetails}>
        //                 <CardContent>
        //                     <Typography component="h2" variant="h5">
        //                         {post.title}
        //                     </Typography>
        //                     <Typography variant="subtitle1" color="textSecondary">
        //                         {post.date}
        //                     </Typography>
        //                     <Typography variant="subtitle1" paragraph>
        //                         {post.description}
        //                     </Typography>
        //                     <Typography variant="subtitle1" color="primary">
        //                         Continue reading...
        //                     </Typography>
        //                 </CardContent>
        //             </div>
        //         </Card>
        //     </CardActionArea>
        // </Grid>
    );
}

FeaturedPost.propTypes = {
    post: PropTypes.object
};
