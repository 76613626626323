import React, { useState, useEffect } from 'react';
/* eslint-disable */
import '../../../styles/global.css';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import api from '../../../services/api';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [dataOuvidoria, setDataOuvidoria] = useState([]);
    const [dataEsic, setDataEsic] = useState([]);
    const [esicAberto, setEsicAberto] = useState(0);
    const [ouvidoriaAberto, setOuvidoriaAberto] = useState(0);
    let history = useHistory();
    useEffect(() => {
      socilitacoesOuvidoria();
      socilitacoesEsic();
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    async function socilitacoesOuvidoria() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`ouvidoria-status/Aberto`, config);
            console.log('Listar todos as solicitações de ouvidoria', result);
            setDataOuvidoria(result);
            setOuvidoriaAberto(result.length)
        } catch (error) {
            console.log('Erro ao ao listar ouvidoria', error, error.response);
        }
    }

    async function socilitacoesEsic() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`esic-status/Aberto`, config);
            console.log('Listar todos os esic', result);
            setEsicAberto(result.length)
            setDataEsic(result);
        } catch (error) {
            console.log('Erro ao ao listar pesquisa do esic', error, error.response);
        }
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    return (
      <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
              <Breadcrumbs aria-label="breadcrumb">
                  <Link
                      className="linkBreadcrumbs"
                      color="inherit"
                      href="/"
                      onClick={handleClick}>
                      Dashboard
                  </Link>
                  {/* <Link
                      className="linkBreadcrumbs"
                      color="inherit"
                      href="/getting-started/installation/"
                      onClick={handleClick}>
                      Core
                  </Link> */}
                  {/* <Typography variant="body2" color="textPrimary">
                    Dashboard
                  </Typography> */}
              </Breadcrumbs>
              <div className="headerPage">
                  <h2>Dashboard</h2>
                  {/* <Button className="button-add" variant="contained" color="primary">
                      Novo
                  </Button> */}
              </div>
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
              <Paper className={'paperSearch'}>
                  <TextField
                      className="textField"
                      id="outlined-secondary"
                      label="Faça uma busca aqui!"
                      variant="outlined"
                      color="secondary"
                  />
              </Paper>
          </Grid> */}
          <Grid item xs={12} md={3} lg={3}>
              {skeleton && <Skeleton variant="rect" className="paper" />}
              {!skeleton && (
                  <Paper onClick={() => {history.push('/portal/admin/ouvidoria')}} className={'paperDashboard'}>
                      <Icon className={'fa fa-assistive-listening-systems'} style={{fontSize: 20, width: 'auto', margin: 10}} />
                      <div style={{fontSize: 15}}>Solicitação ouvidoria aberta</div>
                      <div style={{fontSize: 25}}>{ouvidoriaAberto}</div>
                  </Paper>
              )}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
              {skeleton && <Skeleton variant="rect" className="paper" />}
              {!skeleton && (
                  <Paper onClick={() => {history.push('/portal/admin/esic')}} className={'paperDashboard'}>
                      <Icon className={'fa fa-info'} style={{fontSize: 20, width: 'auto', margin: 10}} />
                      <div style={{fontSize: 15}}>Solicitação e-Sic aberta</div>
                      <div style={{fontSize: 25}}>{esicAberto}</div>
                  </Paper>
              )}
          </Grid>
          {/* <Grid item xs={12} md={12} lg={12}>
              {skeleton && <Skeleton variant="rect" className="paper" />}
              {!skeleton && (
                  <Paper className={'paper'}>
                      <div className="paperHeader">
                          <Typography variant="subtitle1" gutterBottom>
                              Title Paper
                          </Typography>
                      </div>
                      <div className="paperContant">{'Content'}</div>
                  </Paper>
              )}
          </Grid> */}
      </Grid>
    );
}
