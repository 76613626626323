import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import Upload from '../../Components/Upload';
import filesize from 'filesize';
import { Breadcrumbs } from '@material-ui/core';
// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'Ouvidoria',
    description:
        "A Ouvidoria é um canal para você apresentar manifestações como reclamações, denúncias, solicitações e sugestões.",
    image: 'https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png',
    imgText: 'main image description',
};

const featuredPosts = [
    {
        title: 'Portal da Transparência',
        description:
            'Acesso ao sistema externo do portal da transparência do município.',
    },
    {
        title: 'e-Sic',
        description:
            'Através deste formulário, pessoas físicas ou jurídicas podem encaminhar pedidos de acesso à informação.',
    }
];

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [logado, setLogado] = useState(false);
    const [openResetSenha, setOpenResetSenha] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [senha, setSenha] = useState('');
    const [dadosPerfil, setDadosPerfil] = useState('');
    const [tipo, setTipo] = useState('');
    const [orgaos, setOrgaos] = useState([]);
    const [orgao, setOrgao] = useState('');
    const [assunto, setAssunto] = useState('');
    const [textSolicitacao, setTextSolicitacao] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [process, setProcess] = useState(0);
    const [state, setState] = useState({
      checkedA: true,
      checkedB: false,
    });
    let history = useHistory();
    let uploadArray = [];
    // const classes = useStyles();

    useEffect(() => {
      carregarOrgaos()
      const authenticated = localStorage.getItem('@tokenTransparencia');
      if(authenticated){
        setLogado(true)
        buscarPerfil()
        toast.success(`Voaê está logado no sistema.`, {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });  
        setTimeout(function () {
            // history.push('/portal/esic')
        }, 6000);
      }else{
        setLogado(false)
      }
    }, []);

    function handleChangeOrgao(event) {
        setOrgao(event.target.value)
    }

    async function carregarOrgaos() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('orgaos', config);
            console.log('Result orgãos', result);
            setOrgaos(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    async function buscarPerfil() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('perfil', config);
            console.log('Buscar seu perfil', result);
            setDadosPerfil(result)
        } catch (error) {
            console.log('Erro ao buscar perfil', error, error.response);
        }
    }

    function tipoOuvidoria(value){
      setTipo(value)
    }

    function rota(rota){
      history.push(rota);
    }
    const handleOpen = (value) => {
      setOpen(true);
      if(value === 1){
        setInfo(value)
      }else if(value === 2){
        setInfo(value)
      }
    };
    const handleChangeAssunto = (event) => {
      setAssunto(event.target.value);
    };
    function handleChangeSolicitacao(event) {
      setTextSolicitacao(event.target.value)
    }
    const handleOpenResetSenha = (value) => {
      setOpenResetSenha(true);
    };

    function handleChangeCpfCnpj(event) {
        setCpfCnpj(event.target.value);
    }

    function handleChangeSenha(event) {
        setSenha(event.target.value);
    }
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleCloseResetSenha = () => {
      setOpenResetSenha(false);
    };

    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

    async function login(event) {
        event.preventDefault();
        if(cpfCnpj === ''){
          toast.error(`O CPF ou CNPJ é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        if(senha === ''){
          toast.error(`Sua senha é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        setLoading(true);
        try {
            const response = await api.post('/sessions', {
                cpf_cnpj: cpfCnpj.trim(),
                password: senha
            });
            console.log('Sucesso, ', response.data.token);
            //Alert.alert("Sucesso", response.data.token);
            localStorage.setItem('@tokenTransparencia', response.data.token);
            // buscarPerfil(response.data.token);
            // await AsyncStorage.setItem('@tokenEmpresa', response.data.token);
            setLoading(false);
            history.go(0)
        } catch (error) {
            setLoading(false);
            // setOpenAlert(true);
            // setTypeAlert('error');
            // setMsgAlert('Login ou senha, incorretos!');
            console.log('Error', error, error.response);
            if(error.response.data[0].field === 'cpf_cnpj' && error.response.data[0].message === 'Cannot find user with provided cpf_cnpj'){
              toast.error(`O CPF ou CNPJ nao foi encontrado.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
            if(error.response.data[0].field === 'password' && error.response.data[0].message === 'Invalid user password'){
              toast.error(`Você está usando uma senha inválida.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
        }
    }

    const body = (
      <div className={"paperModal"}>
        <div className="simple-modal-title">
          {info === 1 ? "Como fazer o cadastro" : "Como fazer um pedido"}
          <Cancel onClick={handleClose} style={{color: '#fff', cursor: 'pointer'}} color="action" />
        </div>
        <div className="simple-modal-conteudo">
          {info === 1 && (
            <>
              <p>
                1. Entre na página do Lei de Acesso e clique no link "Cadastre-se", localizado à direita da página, dentro da caixa de login;
              </p>
              <p>
                2. A tela seguinte é a de cadastro do solicitante, nela o usuário irá preencher os dados pessoais e em seguida clicar no botão salvar ao final da página para que o seu cadastro seja efetuado;
              </p>
              <p>
                3. O usuário receberá uma notificação via e-mail, portanto é de suma importância que na tela de cadastro de solicitante o usuário insira um e-mail válido. Obs: Ao entrar na caixa de entrada do seu webmail se não encontrar o e-mail de confirmação, entre na caixa “Lixo” se você inseriu todos os dados de forma correta possivelmente o e-mail de confirmação poderá estar lá;
              </p>
              <p>
                4. No e-mail de confirmação, estará contido um link no qual o usuário deverá clicar, para que só assim seu cadastro seja validado;
              </p>
              <p>
                5. O link que o solicitante clicou no passo anterior irá redirecioná-lo (em nova janela) para a página confirmação de cadastro;
              </p>
            </>
          )}
          {info === 2 && (
            <>
              <p>
              1. Faça seu cadastro no sistema, e acesse o sistema com seu nome de usuário e senha;
              </p>
              <p>
                2. Acesse a aba "Fazer Solicitação" e descreva detalhadamente seu pedido;
              </p>
              <p>
                3. Abaixo, escolha a forma de retorno que deseja apos informar seu pedido;
              </p>
              <p>
                4. Apos descrever sua solicitação e definir a forma de retorno, o usuário deve clicar em enviar para registrar sua solicitação, uma tela de confimação deve aparecer;
              </p>
              <p>
                5. Acesse a aba "Solicitações Realizadas" para acompanhar suas solicitações e aguarde ate que seu pedido seja respondido.
              </p>
            </>
          )}
        </div>
      </div>
    );

    async function solicitarSenha() {
      setLoading(true)

      await api
          .get(`/reset-senha/${cpfCnpj}`, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Resetando senha", res.data)
              setLoading(false);
              setOpenResetSenha(false)
              toast.info(res.data, {
                position: "bottom-left",
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
          })
          .catch(error => {
            setLoading(false);
            // console.log("error: ", error, error.response)
            // console.log("response: ", error.response.data.message)
            toast.error(`Ops! erro ao solicitar nova senha, ${error.response.data.message}`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          });
    }

    function limparCampos(){
      setOrgao('')
      setOrgaos([])
      setTipo('')
      setAssunto('')
      setTextSolicitacao('')
      setUploadedFiles([])
    }
    async function enviaDadosBanco(link){
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('user_id', logado ? dadosPerfil.id : '');
      form_data.append('orgao_id', orgao);
      form_data.append('ouvidoria_tipo', tipo);
      form_data.append('ouvidoria_assunto', assunto);
      form_data.append('ouvidoria_privado', state.checkedB ? 'Sim' : 'Não');
      form_data.append('ouvidoria_manifestacao', textSolicitacao);
      // form_data.append('ouvidoria_manifestacao_resposta', statusEsic);
      form_data.append('ouvidoria_file', link === '' ? '' : link );

      await api
        .post(`/ouvidoria/`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Adicionando esic", res.data)
            setLoading(false);
            toast.success('🦄 Solicitação cadastrada com sucesso!', {
              position: "bottom-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            limparCampos()
            // enviaInteracao(res.data.id)
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error: ", error, error.response)
          // console.log("response: ", error.response.data.message)
          toast.error(`Ops!, Erro ao cadastrar solicitação`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }

    //envia o file
    async function enviarFiles(){
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-ouvidoria/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                // Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
            },

          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviaDadosBanco(res.data.link)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            throw error;
          });
      });
    }

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        uploadArray.push({
          file,
          // id: uniqueId(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: null,
          status: 1,
          type: file.type,
          albun: []
        })
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

    }

    async function submit(event) {
      setLoading(true);
      event.preventDefault();
      if(uploadedFiles.length === 0){
        enviaDadosBanco('')
      }else{
        enviarFiles()
      }
    }
    


    //validações
    const validation = [];
    validation.push('...');
    validation.push(tipo);
    validation.push(orgao);
    validation.push(assunto);
    validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <span color="textPrimary">Ouvidoria</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Bem-Vindo
                          </div>
                          <div className="paperConteudo">
                            <p>
                            Receber e apurar denúncias, reclamações, críticas, comentários e pedidos de informação sobre atos considerados ilegais comissivos e/ou omissivos, arbitrários, desonestos, indecorosos, ou que contrariem o interesse público, praticados por servidores públicos do Município ou agentes públicos;

Diligenciar junto às unidades da Administração competentes para a prestação por estes, de informações e esclarecimentos sobre atos praticados ou de sua responsabilidade, objeto de reclamações ou pedidos de informação;

Recomendar aos órgãos da Administração a adoção de mecanismos que dificultem e impeçam a violação do patrimônio público e outras irregularidades comprovadas;

Coordenar ações integradas com os diversos órgãos da municipalidade, a fim de encaminhar, de forma intersetorial, as reclamações dos munícipes que envolvam mais de um órgão da administração direta e indireta.
                            </p>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    {!logado && (
                    <Grid container spacing={3}>
                      {!state.checkedB && (
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperContant">
                              <Grid  item xs={12} md={12}>
                                  <Paper className="paper">
                                    <div className="formLogin">
                                    <div className="formLoginHeader">
                                      Acesse o sistema
                                    </div>
                                   
                                    <form onSubmit={login} noValidate>
                                      <FormControl fullWidth className="formControl" variant="outlined">
                                        {/* <InputLabel htmlFor="outlined-setCpfCnpj">Seu CPF ou CNPJ</InputLabel> */}
                                        <CpfCnpj
                                          className="customizedInput"
                                          placeholder="Digite um CPF ou CNPJ"
                                          type="tel"
                                          value={cpfCnpj}
                                          onChange={(event, type) => {
                                            setCpfCnpj(event.target.value);
                                            setMask(type === "CPF");
                                          }}
                                        />
                                        {/* <OutlinedInput
                                          id="outlined-setCpfCnpj"
                                          value={cpfCnpj}
                                          required
                                          onChange={handleChangeCpfCnpj}
                                          startAdornment={
                                            <InputAdornment position="start"><AssignmentInd color="action" /></InputAdornment>
                                          }
                                          label={'Seu CPF ou CNPJ'}
                                        /> */}
                                      </FormControl>
                                      <FormControl fullWidth className="formControl"  variant="outlined">
                                        <InputLabel htmlFor="outlined-senha">Senha de acesso</InputLabel>
                                        <OutlinedInput
                                          id="outlined-senha"
                                          value={senha}
                                          required
                                          onChange={handleChangeSenha}
                                          startAdornment={
                                            <InputAdornment position="start">
                                              <LockOpen color="action" />
                                            </InputAdornment>
                                          }
                                          label={'Senha de acesso'}
                                        />
                                      </FormControl>
                                      <div className="btns">
                                        <Button type="submit" variant="contained" color="primary" className={"btnEntrar"}>
                                          Entrar
                                        </Button>
                                        <Button onClick={() => {rota('/cadastro-unico')}} variant="contained" color="primary" className={"btnEntrar"}>
                                          Cadastro único
                                        </Button>
                                      </div>
                                    </form>
                                    <a style={{cursor: 'pointer', padding: 5}} onClick={() => {handleOpenResetSenha()}}>Esqueci a senha</a>
                                    </div>
                                  </Paper>
                              </Grid>
                          </div>
                        </Paper>
                      </Grid>
                      )}
                    </Grid>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperHeader">
                              <Typography variant="subtitle1" gutterBottom>
                                  Cadastre uma solicitação
                              </Typography>
                          </div>
                          <div className="paperContant">
                              {/* {logado && ( */}
                                <Grid className="infoPrivado" item xs={12} md={12}>
                                  <Switch
                                    checked={state.checkedB}
                                    onChange={handleChange}
                                    color="primary"
                                    name="checkedB"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                  />
                                  <div className="info-privado">
                                    Gostaria de ter a minha identidade preservada neste pedido, conforme o disposto no art. 10, § 7º da Lei nº 13.460/2017.
                                  </div>
                                </Grid>
                              {/* )} */}
                              <Grid item xs={12} md={12}>
                                <Grid container style={{padding: 10}} spacing={3}>
                                  <Grid item xs={12} md={3}>
                                    <div className={`tipoOuvidoria ouvUm`} onClick={() => tipoOuvidoria('DENÚNCIA')}>
                                      {tipo === 'DENÚNCIA' &&(
                                        <div className="maskBack"></div>
                                      )}
                                      <h2 style={{color: '#fff'}}>DENÚNCIA</h2>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <div className={`tipoOuvidoria ouvDois`} onClick={() => tipoOuvidoria('RECLAMAÇÃO')}>
                                      {tipo === 'RECLAMAÇÃO' &&(
                                        <div className="maskBack"></div>
                                      )}
                                      <h2 style={{color: '#fff'}}>RECLAMAÇÃO</h2>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <div className={`tipoOuvidoria ouvTres`} onClick={() => tipoOuvidoria('ELOGIO')}>
                                      {tipo === 'ELOGIO' &&(
                                        <div className="maskBack"></div>
                                      )}
                                      <h2 style={{color: '#fff'}}>ELOGIO</h2>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <div className={`tipoOuvidoria ouvQuatro`} onClick={() => rota('/portal/esic')}>
                                      {tipo === 'SOLICITAÇÃO' &&(
                                        <div className="maskBack"></div>
                                      )}
                                      <h2 style={{color: '#fff'}}>SOLICITAÇÃO</h2>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperContant">
                              <Grid item xs={12} md={12}>
                                {state.checkedB && (
                                  <div className="dadosSolicitanteOuvidoria">
                                    <Alert variant="filled" severity="success">
                                      Você está fazendo uma solicitação anônima
                                    </Alert>
                                  </div>
                                )}
                                {logado && (
                                  <div className="dadosSolicitanteOuvidoria">
                                    <Alert variant="filled" severity="success">
                                      Você está logado com o cadastro único, você não precisa se cadastrar
                                    </Alert>
                                    <h2>Dados do solicitante</h2>
                                    <div>Solicitante: <strong>{dadosPerfil.name}</strong></div>
                                    <div>Telefone: <strong>{dadosPerfil.telefone}</strong></div>
                                  </div>
                                )}
                              </Grid>
                              <form onSubmit={submit} noValidate>
                              <Grid container spacing={3}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl fullWidth className="formControl" variant="outlined">
                                      <InputLabel id="demo-simple-select-outlined-label">Orgão Público</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={orgao}
                                        onChange={handleChangeOrgao}
                                        label="Orgão Público"
                                      >
                                        <MenuItem value="">Selecione o orgão</MenuItem>
                                        {orgaos.map((item) => (
                                          <MenuItem key={item.id} value={item.id}>{item.nome_orgao}</MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      fullWidth
                                      id="outlined-multiline-static"
                                      label="Assunto"
                                      placeholder={'Assunto da demanda'}
                                      variant="outlined"
                                      value={assunto}
                                      onChange={handleChangeAssunto}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <TextField
                                      fullWidth
                                      id="outlined-multiline-static"
                                      label="Descreva de forma detalhada sua solicitação"
                                      multiline
                                      rows={4}
                                      placeholder={'Descreva de forma detalhada sua solicitação'}
                                      variant="outlined"
                                      value={textSolicitacao}
                                      onChange={handleChangeSolicitacao}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    {uploadedFiles.length === 0 && (
                                      <Upload onUpload={handleUpload} />
                                    )}
                                    <div className="files-carregados">
                                      {uploadedFiles.map((item, key) => (
                                        <img key={key} style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.type === 'image/jpeg' || item.type === 'image/png' || item.type === 'image/jpg' ? item.preview : 'https://images.vexels.com/media/users/3/135458/isolated/preview/79ca0179926d5e59f959e7fdb28f5513-iacute-cone-de-arquivo-azul-by-vexels.png'} alt=""/>
                                      ))}
                                    </div>
                                    <div className="oquefalta">
                                      {tipo === '' &&(
                                        <p style={{fontSize: 11, color: 'red'}}>Falta o tipo: DENÚNCIA, RECLAMAÇÃO, ELOGIO, SOLICITAÇÃO</p>
                                      )}
                                      {orgao === '' &&(
                                        <p style={{fontSize: 11, color: 'red'}}>Falta o Orgão Público</p>
                                      )}
                                      {assunto === '' &&(
                                        <p style={{fontSize: 11, color: 'red'}}>Falta o Assunto</p>
                                      )}
                                      {textSolicitacao === '' &&(
                                        <p style={{fontSize: 11, color: 'red'}}>Falta o Texto</p>
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} md={12}>
                                    <Button disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                      Enviar
                                    </Button>
                                  </Grid>
                                  </Grid>
                                </form>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                </main>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={"modal"}
                >
                  {body}
                </Modal>
                <Modal
                  open={openResetSenha}
                  onClose={handleCloseResetSenha}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={"modal"}
                >
                  <div className={"paperModal"}>
                    <div className="simple-modal-title">
                      Solicitar nova senha
                      <Cancel onClick={handleCloseResetSenha} style={{color: '#fff', cursor: 'pointer'}} color="action" />
                    </div>
                    <div className="simple-modal-conteudo">
                      <FormControl fullWidth className="formControl" variant="outlined">
                        <CpfCnpj
                          className="customizedInput"
                          placeholder="Digite um CPF ou CNPJ"
                          type="tel"
                          value={cpfCnpj}
                          onChange={(event, type) => {
                            setCpfCnpj(event.target.value);
                            setMask(type === "CPF");
                          }}
                        />
                      </FormControl>
                      <Button onClick={solicitarSenha} style={{marginTop: 10}} variant="contained" color="secondary">
                        Enviar
                      </Button>
                    </div>
                  </div>
                </Modal>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
