/* eslint-disable */
import api from './services/api';
export const isAuthenticated = () => {
    const token = localStorage.getItem('@tokenTransparencia');
    if (token) {
        return true;
    } else {
        return false;
    }
};

export const isAuthenticatedV2 = (props) => {
    console.log('Props02', props)
    const token = localStorage.getItem('@tokenTransparencia');
    var config = {
      headers: { Authorization: 'bearer ' + token }
    };
    const result = api.get('menu', config);
    // console.log("resultresult ",result)
    return result;
};

export const veryAuth = () => {
  // verifica 
  var token = localStorage.getItem('@tokenTransparencia');
  var config = {
      headers: { Authorization: 'bearer ' + token }
  };
  const result = api.get('very-auth', config);
  // console.log("resultresult ",result)
  return result;
  // console.log('props auth', res);
};

