import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Tables from '../../../Components/Tables/licitacao';
import api, {linkApi} from '../../../services/api';
import {veryAuth} from '../../../auth';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {
  MuiPickersUtilsProvider, 
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@material-ui/core/Modal';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Close from '@material-ui/icons/Close';
import ModalView from '../../../Components/Modal';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
import CircularProgress from '@material-ui/core/CircularProgress';
import Moeda from '../../../Components/Mask/Moeda';
import Switch from '@material-ui/core/Switch';

export default function Index() {
    let history = useHistory();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [skeleton, seSkeleton] = useState(true);
    const [tipoCrud, setTipoCrud] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [title, setTitle] = useState('Cadastrar nova Comunidade');
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const [documento, setDocumento] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [dataAbertura, setDataAbertura] = useState();
    const [dataFechamento, setDataFechamento] = useState('');
    const [local, setLocal] = useState('');
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState('Aberta');
    const [objeto, setObjeto] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nomeFile, setNomeFile] = useState('');
    const [arquivosLicitacao, setArquivosLicitacao] = useState([]);
    const [avisoFile, setAvisoFile] = useState(false);
    const [orgaos, setOrgaos] = useState([]);
    const [orgao, setOrgao] = useState('');
    const [contador, setContador] = useState(0);
    const [process, setProcess] = useState(0);

    const [situacaoComunicacao, setSituacaoComunicacao] = useState('');
    const [possuiEscola, setPossuiEscola] = useState('');

    const [stateSwitch, setStateSwitch] = useState({
      checkedA: true,
      checkedB: false,
    });
    const [stateReligiao, setStateReligiao] = useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });
    let count = 0;
    useEffect(() => {
        console.log("linkApi", linkApi)
        carregarOrgaos()
        verificarPermissao()
        if(location.state){
          setTipoCrud(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Editar licitação')
        }else{
          // setLoading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }


    function gerarAno(t, v){
      const result = new Date().getFullYear();
      var result2
      if(t === 'add'){
        result2 = parseInt(result) + v;
      }else{
        result2 = parseInt(result) - v;
      }
      return result2; 
    }

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    //recebe o id do esic
    async function enviarFiles(id, tipoCad){
      // setLoading(true)
      setProcess(0)
      console.log("uploadArray >>", id, uploadArray)
      var token = localStorage.getItem('@tokenTransparencia');
      if(uploadedFiles.length === 0){
        history.push('/portal/admin/licitacao');
      }
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-licitacao/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
              // Old version
              onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total))
                console.log("Progresso: ", progress)
                setProcess(progress)
                // updateFile(uploadedF.id, {
                //   progress,
                // })
              },
            },
            // config,
          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviarDadosFile(res.data, id, tipoCad)
            editar(id)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            throw error;
          });
      });
    }
    async function enviarDadosFile(res, id, tipoCad) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('licitacao_id', id);
      form_data.append('name_file', prepararFileName(res.file.clientName));
      form_data.append('type_file', res.file.type+ '/' + res.file.subtype);
      form_data.append('size_file', res.file.size);
      form_data.append('arquivo_esic_link', res.link);

      // form_data_file.append('avatar', {
      //   name: avatar.fileName,
      //   type: avatar.type,
      //   uri: avatar.uri,
      // });

      await api
        .post(`/files-licitacao`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            setLoading(false)
            setUploadedFiles([])
            // editar(parseInt(location.state.id))
            console.log("Dados do arquivo adicionado com sucesso ", res.data)
            toast.info(`Arquivo ${res.data.name_file} enviado com sucesso`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if(tipoCad === 'new'){
              toast.success(`Licitação cadastrada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(function () {
                  limpaCampos()
                  history.push('/portal/admin/licitacao');
              }, 3000);
            }else{
              toast.success(`Licitação editada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error dados do arquivo: ", error, error.response)
          console.log("response: ", error.response.data.message)
        });

    }

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        if(file.type === "application/pdf"){
          setAvisoFile(false)
          setNomeFile(prepararFileName(file.name))
          uploadArray.push({
            file,
            // id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            status: 1,
            type: file.type,
            albun: []
          })
        }else{
          setAvisoFile(true)
        }
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

      // uploadArray.forEach(element => {
      //   console.log("files dentro do array", element)
      //   // processUpload(element)
      // });
    }

    const conteudoModal = (
      <Paper className={'paper'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                label="Nome do arquivo"
                value={nomeFile}
                onChange={handleChangeNomeFile}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>Somente arquivo pdf</div>
            {avisoFile && (
              <div style={{color: 'red'}}>Seu aquivo não é pdf</div>
            )}
          </Grid>
          {uploadedFiles.length === 0 &&(
            <Grid item xs={12} sm={12}>
              <Upload onUpload={handleUpload} />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            {uploadedFiles.map((item, key) => (
              <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <PictureAsPdf />
                <div style={{margin: 10}}>{item.name}</div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={() => setUploadedFiles([]) } variant="contained" color="secondary" disableElevation>
                Remover arquivo
              </Button>
            )}
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={handleClose} variant="contained" color="primary" disableElevation>
                OK
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    function handleChangeSituacaoComunicacao(event){
      setSituacaoComunicacao(event.target.value);
    }
    function handleChangePossuiEscola(event){
      setPossuiEscola(event.target.value);
    }

    function handleChangeDocumento(event){
      setDocumento(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeModalidade(event){
      setModalidade(event.target.value);
    }
    function handleChangeTipo(event){
      setTipo(event.target.value);
    }
    const handleDateChangeDataAbertura = (date) => {
      setDataAbertura(date);
    };
    const handleDateChangeDataFechamento = (date) => {
      setDataFechamento(date);
    };
    function handleChangeLocal(event){
      setLocal(event.target.value);
    }
    function handleChangeValor(event){
      setValor(event);
    }
    function handleChangeSituacao(event){
      setSituacao(event.target.value);
    }
    function handleChangeObjeto(event){
      setObjeto(event.target.value);
    }
    
    function handleChangeNomeFile(event){
      setNomeFile(prepararFileName(event.target.value));
    }

    function handleChangeOrgao(event) {
      setOrgao(event.target.value)
    }

    const handleChangeSwitch = (event) => {
      setDataFechamento(new Date())
      setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
    };
    const handleChangeReligiao = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

    function prepararFileName(name){
      var resultado = name.replace(".pdf", "");
      console.log("resultado prepararFileName", resultado)
      return resultado
    }
    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }

    async function carregarOrgaos() {
      var token = localStorage.getItem('@tokenTransparencia');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('orgaos', config);
          console.log('Result orgãos', result);
          setOrgaos(result);
      } catch (error) {
          console.log('Erro ao deletar', error, error.response);
      }
    }

    async function editar(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: resultEdit} = await api.get(`/licitacao/${id}`, config);
        console.log("edit aqui", resultEdit)
        var edit = resultEdit[0];
        // setLoading(false)
        setOrgao(edit.orgao_id);
        setDocumento(edit.licitacao_numero_documento);
        setAno(edit.licitacao_ano);
        setModalidade(edit.licitacao_modalidade);
        setTipo(edit.licitacao_tipo)
        setDataAbertura(edit.licitacao_data_abertura);
        setDataFechamento(edit.licitacao_data_fechamento);
        setLocal(edit.licitacao_local);
        setValor(edit.licitacao_valor);
        setSituacao(edit.licitacao_situacao);
        setObjeto(edit.licitacao_descricao);
        setArquivosLicitacao(edit.fileLicitacao)
        toast.info('🦄 Carregando dados!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setLoading(false);
      }
    }
    

    async function salvar(event) {
      event.preventDefault();
      console.log("dataFechamento", dataFechamento,  dataFechamento ? 'teste1' : 'teste2')
      console.log("event", event)
      setLoading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("orgao_id", orgao);
      form_data.append("licitacao_situacao", situacao);
      form_data.append("licitacao_numero_documento", documento);
      form_data.append("licitacao_ano", ano);
      form_data.append("licitacao_modalidade", modalidade);
      form_data.append("licitacao_tipo", tipo);
      form_data.append("licitacao_data_abertura", dataAbertura ? dataAbertura : '0000-00-00');
      form_data.append("licitacao_data_fechamento", dataFechamento ? dataFechamento : '0000-00-00');
      form_data.append("licitacao_local", local);
      form_data.append("licitacao_valor", valor);
      form_data.append("licitacao_descricao", objeto);

      if(tipoCrud === 'new'){
        await api
          .post(`/licitacao`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando cliente", res.data)
              if(uploadedFiles.length === 0){
                limpaCampos();
                setLoading(false);
                toast.success('🦄 Licitação cadastrada com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }else(
                enviarFiles(res.data.id, 'new')
              )

          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Licitação, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
            // throw error;
          });
  
      }else if(tipoCrud === 'edit'){
  
        await api
          .post(`/licitacao/${parseInt(location.state.id)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cliente editado", res.data)
              // editar(res.data.id)
              if(uploadedFiles.length === 0){
                editar(res.data.id)
                setLoading(false);
                toast.success('🦄 Licitação editada com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                
              }else{
                enviarFiles(res.data.id, 'edit')
              }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
            toast.error('🦄 Erro ao editar Licitação, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
  
      }
  
    }

    async function deletarRegistroFile(bucket, linkFile, id) {     
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/files-licitacao/${id}`, config);
        console.log("prod", prod)
        removeFile(bucket, linkFile)
        toast.success('🦄 Arquivo deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        console.log("Erro ao deletar Arquivo", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao deletar arquivo, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function removeFile(bucket, linkFile) {

      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("bucket", bucket);
      form_data.append("linkFile", linkFile);
      await api
        .post(`/file-remove`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            editar(parseInt(location.state.id))
            console.log("Arquivo removido", res.data)
            toast.success('🦄 Arquivo removido!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`🦄 Erro ao remover file, ${error.response.data.message}!`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          // throw error;
        });
    }

    function limpaCampos(){
      setDocumento('');
      setAno('')
      setModalidade('')
      setTipo('')
      setDataAbertura('');
      setDataFechamento('');
      setLocal('');
      setValor('');
      setSituacao('');
      setObjeto('');
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(documento && ano && modalidade && tipo && dataAbertura && local && situacao && objeto);
    // validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/comunidade">
                                    Comunidade
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Cadastrar
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                  <div className="paperContant">
                                    <form noValidate autoComplete="off" onSubmit={salvar}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Nome da Comunidade Quilombola</span>
                                          <TextField
                                              autoComplete="fname"
                                              name="fname"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Nome da Comunidade Quilombola"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Município</span>
                                          <TextField
                                              autoComplete="fname"
                                              name="fmunicipio"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Município"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Estado</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="ano-label">Estado</InputLabel> */}
                                            <Select
                                              labelId="ano-label"
                                              id="ano-label"
                                              value={ano}
                                              onChange={handleChangeAno}
                                              // label="Estado"
                                            >
                                                <MenuItem value="">- UF -</MenuItem>	
                                                <MenuItem value="AC">AC</MenuItem>
                                                <MenuItem value="AL">AL</MenuItem>
                                                <MenuItem value="AM">AM</MenuItem>
                                                <MenuItem value="AP">AP</MenuItem>
                                                <MenuItem value="BA">BA</MenuItem>
                                                <MenuItem value="CE">CE</MenuItem>
                                                <MenuItem value="DF">DF</MenuItem>
                                                <MenuItem value="ES">ES</MenuItem>
                                                <MenuItem value="GO">GO</MenuItem>
                                                <MenuItem value="MA">MA</MenuItem>
                                                <MenuItem value="MG">MG</MenuItem>
                                                <MenuItem value="MS">MS</MenuItem>
                                                <MenuItem value="MT">MT</MenuItem>
                                                <MenuItem value="PA">PA</MenuItem>
                                                <MenuItem value="PB">PB</MenuItem>
                                                <MenuItem value="PE">PE</MenuItem>
                                                <MenuItem value="PI">PI</MenuItem>
                                                <MenuItem value="PR">PR</MenuItem>
                                                <MenuItem value="RJ">RJ</MenuItem>
                                                <MenuItem value="RN">RN</MenuItem>
                                                <MenuItem value="RO">RO</MenuItem>
                                                <MenuItem value="RR">RR</MenuItem>
                                                <MenuItem value="RS">RS</MenuItem>
                                                <MenuItem value="SC">SC</MenuItem>
                                                <MenuItem value="SE">SE</MenuItem>
                                                <MenuItem value="SP">SP</MenuItem>
                                                <MenuItem value="TO">TO</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Distância entre a Comunidade e a Sede</span>
                                          <TextField
                                              autoComplete="fdistancia"
                                              name="distancia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Distância entre a Comunidade e a Sede"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Dimensão territorial da Comunidade</span>
                                          <TextField
                                              autoComplete="fdimessao"
                                              name="dimessao"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Dimensão territorial da Comunidade"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Acesso à Comunidade</span>
                                          <TextField
                                              autoComplete="facesso"
                                              name="acesso"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Acesso à Comunidade"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Tempo de existência da Comunidade</span>
                                          <TextField
                                              autoComplete="ftempoexistencia"
                                              name="tempoexistencia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Tempo de existência da Comunidade"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Qantidade de Familia da Comunidade</span>
                                          <TextField
                                              autoComplete="fqtofamilia"
                                              name="fqtofamilia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Qantidade de Familia da Comunidade"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Nome do Presidente da Comunidade</span>
                                          <TextField
                                              autoComplete="fnomepresidente"
                                              name="nomepresidente"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Nome do Presidente da Comunidade"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Tempo da vigência da Presidência</span>
                                          <TextField
                                              autoComplete="ftempovirgencia"
                                              name="tempovirgencia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Tempo da vigência da Presidência"
                                              value={documento}
                                              onChange={handleChangeDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Situação da Comunidade</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="situacao-label">Situação da Comunidade</InputLabel> */}
                                            <Select
                                              labelId="situacao-label"
                                              id="situacao-label"
                                              value={situacaoComunicacao}
                                              onChange={handleChangeSituacaoComunicacao}
                                              // label="Situação da Comunidade"
                                            >
                                              <MenuItem value={''}>Nenhuma</MenuItem>
                                              <MenuItem value={'certificada'}>Certificada</MenuItem>
                                              <MenuItem value={'titulada'}>Titulada</MenuItem>
                                              <MenuItem value={'emprocesso'}><option value="emprocesso">Em processo de certificação</option></MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {situacaoComunicacao === 'certificada' && (
                                          <>
                                            <Grid item xs={12} sm={3}>
                                            <span style={{fontSize: 12}}>Data da Certificação</span>
                                              <TextField
                                                fullWidth
                                                id="dateCertificacao"
                                                label="-"
                                                type="date"
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Número do DOU</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <span style={{fontSize: 12}}>Data</span>
                                              <TextField
                                                fullWidth
                                                id="dateCertificacao"
                                                label="-"
                                                type="date"
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Portaria</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                          </>
                                        )}
                                        {situacaoComunicacao === 'titulada' && (
                                          <>
                                            <Grid item xs={12} sm={3}>
                                            <span style={{fontSize: 12}}>Situação da Comunidade</span>
                                              <FormControl fullWidth variant="outlined">
                                                {/* <InputLabel id="situacao-label">Situação da Comunidade</InputLabel> */}
                                                <Select
                                                  labelId="situacao-label"
                                                  id="situacao-label"
                                                  // value={situacaoComunicacao}
                                                  // onChange={handleChangeSituacaoComunicacao}
                                                  // label="Situação da Comunidade"
                                                >
                                                  <MenuItem value={'Iterma'}>Iterma</MenuItem>
                                                  <MenuItem value={'Incra'}>Incra</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <span style={{fontSize: 12}}>Data de Intitulação</span>
                                              <TextField
                                                fullWidth
                                                id="dateCertificacao"
                                                label="-"
                                                type="date"
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              />
                                            </Grid>
                                          </>
                                        )}
                                        {situacaoComunicacao === 'emprocesso' && (
                                          <Grid item xs={12} sm={12}>
                                            <span style={{fontSize: 12}}>Observações Situacionais</span>
                                              <TextField
                                                  autoComplete="flocal"
                                                  name="local"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  label="Observações"
                                                  value={local}
                                                  onChange={handleChangeLocal}
                                              />
                                          </Grid>
                                        )}
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Possui escola da Comunidade</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="temcomunidade-label">Possui escola da Comunidade</InputLabel> */}
                                            <Select
                                              labelId="temcomunidade-label"
                                              id="temcomunidade-label"
                                              value={possuiEscola}
                                              onChange={handleChangePossuiEscola}
                                              // label="Possui escola da Comunidade"
                                            >
                                              <MenuItem value={'sim'}>Sim</MenuItem>
                                              <MenuItem value={'nao'}>Não</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {possuiEscola === 'sim' && (
                                          <>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Nome da Escola</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                            <span style={{fontSize: 12}}>Tipo de Construção</span>
                                              <FormControl fullWidth variant="outlined">
                                                {/* <InputLabel id="temcomunidade-label">Possui escola da Comunidade</InputLabel> */}
                                                <Select
                                                  labelId="temcomunidade-label"
                                                  id="temcomunidade-label"
                                                  value={tipo}
                                                  onChange={handleChangeTipo}
                                                  // label="Possui escola da Comunidade"
                                                >
                                                  <MenuItem value={'Alvenaria'}>Alvenaria</MenuItem>
                                                  <MenuItem value={'Adobe'}>Adobe</MenuItem>
                                                  <MenuItem value={'Madeira'}>Madeira</MenuItem>
                                                  <MenuItem value={'Pau a Pique'}>Pau a Pique</MenuItem>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Nome do Diretor (a)</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Contato do Diretor (a)</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                              <span style={{fontSize: 12}}>Número de sala de aula</span>
                                              <TextField
                                                  autoComplete="fnomepresidente"
                                                  name="nomepresidente"
                                                  variant="outlined"
                                                  required
                                                  fullWidth
                                                  // label="Nome do Presidente da Comunidade"
                                                  value={documento}
                                                  onChange={handleChangeDocumento}
                                              />
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                              <div>Séries </div>
                                              <FormGroup row>
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={stateReligiao.checkedB}
                                                      onChange={handleChangeReligiao}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Fundamental Menor"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={stateReligiao.checkedB}
                                                      onChange={handleChangeReligiao}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="Fundamental Maior"
                                                />
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={stateReligiao.checkedB}
                                                      onChange={handleChangeReligiao}
                                                      name="checkedB"
                                                      color="primary"
                                                    />
                                                  }
                                                  label="EJA"
                                                />
                                              </FormGroup> 
                                            </Grid>
                                          </>
                                        )}
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Possui posto de Saúde</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="possuiposto-label">Possui posto de Saúde</InputLabel> */}
                                            <Select
                                              labelId="possuiposto-label"
                                              id="possuiposto-label"
                                              value={tipo}
                                              onChange={handleChangeTipo}
                                              // label="Possui posto de Saúde"
                                            >
                                              <MenuItem value={'sim'}>Sim</MenuItem>
                                              <MenuItem value={'nao'}>Não</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Possui a declação de aptidão do PRONAF - DAP?</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="possuiaptidao-label">Possui a declação de aptidão do PRONAF - DAP?</InputLabel> */}
                                            <Select
                                              labelId="possuiaptidao-label"
                                              id="possuiaptidao-label"
                                              value={tipo}
                                              onChange={handleChangeTipo}
                                              // label="Possui a declação de aptidão do PRONAF - DAP?"
                                            >
                                              <MenuItem value={'sim'}>Sim</MenuItem>
                                              <MenuItem value={'nao'}>Não</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Programa de Saúde existentes na Comunidade</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="programasaude-label">Programa de Saúde existentes na Comunidade</InputLabel> */}
                                            <Select
                                              labelId="programasaude-label"
                                              id="programasaude-label"
                                              value={tipo}
                                              onChange={handleChangeTipo}
                                              // label="Programa de Saúde existentes na Comunidade"
                                            >
                                              <MenuItem value={'simprogramadesaude'}>Listados</MenuItem>
                                              <MenuItem value={'naoprogramadesaude'}>Outros</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 10}}>Produtos agrícolas produzidos pela Comuinidade</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="produtosagricolas-label">Produtos agrícolas produzidos pela Comuinidade</InputLabel> */}
                                            <Select
                                              labelId="produtosagricolas-label"
                                              id="produtosagricolas-label"
                                              value={tipo}
                                              onChange={handleChangeTipo}
                                              // label="Produtos agrícolas produzidos pela Comuinidade"
                                            >
                                              <MenuItem value={'simprodutosagrucolas'}>Selecionar Produtos</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          <div>Religião Praticada na Comunidade </div>
                                          <FormGroup row>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Candomblé"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Terecô"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Mina"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Católico"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Evangélica Protestante"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={stateReligiao.checkedB}
                                                  onChange={handleChangeReligiao}
                                                  name="checkedB"
                                                  color="primary"
                                                />
                                              }
                                              label="Umbanda"
                                            />
                                          </FormGroup> 
                                        </Grid>
                                  
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                autoComplete="flocal"
                                                name="local"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Diga aqui qual a igreja(as):"
                                                value={local}
                                                onChange={handleChangeLocal}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                autoComplete="flocal"
                                                name="local"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Quais as manifestações culturais existentes na Comunidade?:"
                                                value={local}
                                                onChange={handleChangeLocal}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                autoComplete="flocal"
                                                name="local"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Calendário festivo da Comunidade"
                                                value={local}
                                                onChange={handleChangeLocal}
                                            />
                                        </Grid>
                                        
                                        
                                        
                                        <Grid item xs={12} sm={12}>
                                          {arquivosLicitacao.length != 0 &&(
                                            <div>Arquivos cadastrados: </div>
                                          )}
                                          {arquivosLicitacao.map((item, key) => (
                                              <div 
                                                key={key} 
                                                style={{
                                                  display: 'flex', 
                                                  justifyContent: 'flex-start', 
                                                  alignItems: 'center',
                                                  width: '100%',
                                                  padding: 5,
                                                  background: '#f7f7f7',
                                                  marginTop: 10,
                                                  marginBottom: 15
                                                }}
                                              >
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>
                                                <a href={linkApi + '/files/' + item.arquivo_esic_link} target="_blank" title={item.name_file}>{item.name_file}</a>
                                              </div>
                                              <div style={{margin: 10}}>
                                                <Close 
                                                  onClick={() => 
                                                    deletarRegistroFile('licitacao', item.arquivo_esic_link, item.id)
                                                    // removeFile('licitacao', item.name_file)
                                                  }
                                                  style={{
                                                    cursor: 'pointer',
                                                    color: 'red'
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {uploadedFiles.length != 0 &&(
                                            <div>Novos arquivos: </div>
                                          )}
                                          {uploadedFiles.map((item, key) => (
                                            <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>{item.name}</div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid style={{background: '#f7f7f7', padding: 5}} item xs={12} sm={12}>
                                          <Button style={{margin: 5}} disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                            {txtBtn}
                                          </Button>
                                          <Button disabled={!validForm} onClick={handleOpen} variant="contained" color="secondary" disableElevation>
                                            Enviar arquivos
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </form>
                                    <ToastContainer
                                      position="bottom-left"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                    />
                                  </div>
                              </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                <ModalView 
                  open={open} 
                  handleClose={handleClose}
                  conteudoModal={conteudoModal}
                />


            </main>
            {loading && (
              <div className="loading">
                <CircularProgress />
                <div style={{fontSize: 21}}>Aguarde, estamos enviando seu arquivo ... {process} {'%'}</div>
              </div>
            )}
        </div>
    );
}
