import React from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../style.css';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    },
    rootCard: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
}));

export default function Index(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false
    });

    var handleProps = props;

    // handleChangePrioridades={handleChangePrioridades}

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        if (event.target.checked) {
            // console.log('event.target.name true', event.target.name);
            handleProps.montarArrayPrioridade(event.target.name);
        } else {
            // console.log('event.target.name false', event.target.name);
            handleProps.removerItemArrayPrioridade(event.target.name);
        }
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div className="header-topo">Questionário</div>
                        <br />
                        <br />
                        <div className="content-view">
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                  <div style={{ paddingBottom: 10, textAlign: 'center' }}>
                                  Na sua opinião quais seriam as prioridades para o seu bairro ou região?
                                  </div>
                                  <div  className="header-topo">Campos obrigatórios *</div>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                      <FormControl
                                          component="fieldset"
                                          className={classes.formControl}>
                                          {/* <FormLabel component="legend">Área</FormLabel> */}
                                          <FormGroup>
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Educação"
                                                      />
                                                  }
                                                  label="Educação"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Saúde"
                                                      />
                                                  }
                                                  label="Saúde"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Assistência e Desenvolvimento Social"
                                                      />
                                                  }
                                                  label="Assistência e Desenvolvimento Social"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Administração"
                                                      />
                                                  }
                                                  label="Administração"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Infraestrutura"
                                                      />
                                                  }
                                                  label="Infraestrutura"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Segurança"
                                                      />
                                                  }
                                                  label="Segurança"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Agricultura, Pecuária e Pesca"
                                                      />
                                                  }
                                                  label="Agricultura, Pecuária e Pesca"
                                              />
                                          </FormGroup>
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <FormControl
                                          component="fieldset"
                                          className={classes.formControl}>
                                          {/* <FormLabel component="legend">Área</FormLabel> */}
                                          <FormGroup>
                                              
                                          <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Cultura"
                                                      />
                                                  }
                                                  label="Cultura"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Turismo"
                                                      />
                                                  }
                                                  label="Turismo"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Esporte"
                                                      />
                                                  }
                                                  label="Esporte"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Juventude"
                                                      />
                                                  }
                                                  label="Juventude"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Meio Ambiente"
                                                      />
                                                  }
                                                  label="Meio Ambiente"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Comunicação"
                                                      />
                                                  }
                                                  label="Comunicação"
                                              />
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          onChange={handleChange}
                                                          name="Direitos Humanos, Família e Mulher"
                                                      />
                                                  }
                                                  label="Direitos Humanos, Família e Mulher"
                                              />
                                          </FormGroup>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                   
                                </Grid>
                                {/* <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Educação
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-educacao"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaEducacao}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Saúde
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-saude"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaSaude}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Assistência Social
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-assistencia"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaAssitenciaSocial}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Administração/Finanças
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-admin"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaAdministracao}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Infraestrutura
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-infraestrutura"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaInfraestrutura}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Segurança
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-seguranca"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaSeguranca}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Agricultura, Pecuária e
                                        Pesca
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-agricultura"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaAgricultura}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Cultura e Turismo
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-cultura"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaCultura}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar o Esporte e Lazer
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-esporte"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaEsporte}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar as Políticas para a
                                        Juventude
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-juventude"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaJuventude}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar o Meio Ambiente
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-meioambiente"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaMeioAmbiente}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar o Saneamento Básico
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-sanemento"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaSaneamentoBasico}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Sua(s) proposta(s) para melhorar a Comunicação
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-comunicacao"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaComunicacao}
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Quais as suas ideias para melhorar a política municipal de
                                        direitos Humanos, Família e Mulher
                                    </div>
                                    <TextField
                                        fullWidth
                                        id="proposta-direitoshumanos"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaDireitosHumanos}
                                    />
                                </Grid> */}
                                <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>Relacione as sugestões referentes as prioridades selecionadas acima: *</div>
                                    <TextField
                                        fullWidth
                                        id="proposta-outros"
                                        label="Resposta"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        onChange={handleProps.handleChangePropostaOutras}
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
