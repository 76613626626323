import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Tables from '../../../Components/Tables/licitacao';
import api, {linkApi} from '../../../services/api';
import {veryAuth} from '../../../auth';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import {format, parseISO} from 'date-fns';
import {
  MuiPickersUtilsProvider, 
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@material-ui/core/Modal';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Close from '@material-ui/icons/Close';
import ModalView from '../../../Components/Modal';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
import CircularProgress from '@material-ui/core/CircularProgress';
import Moeda from '../../../Components/Mask/Moeda';
import Switch from '@material-ui/core/Switch';

export default function Index() {
    let history = useHistory();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [skeleton, seSkeleton] = useState(true);
    const [tipoCrud, setTipoCrud] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [title, setTitle] = useState('Cadastrar nova licitação');
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const [documento, setDocumento] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [dataAbertura, setDataAbertura] = useState();
    const [dataFechamento, setDataFechamento] = useState('');
    const [local, setLocal] = useState('');
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState('Aberta');
    const [objeto, setObjeto] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nomeFile, setNomeFile] = useState('');
    const [arquivosLicitacao, setArquivosLicitacao] = useState([]);
    const [avisoFile, setAvisoFile] = useState(false);
    const [orgaos, setOrgaos] = useState([]);
    const [orgao, setOrgao] = useState('');
    const [contador, setContador] = useState(0);
    const [process, setProcess] = useState(0);
    const [stateSwitch, setStateSwitch] = useState({
      checkedA: true,
      checkedB: false,
    });
    let count = 0;
    useEffect(() => {
        console.log("linkApi", linkApi)
        carregarOrgaos()
        verificarPermissao()
        if(location.state){
          setTipoCrud(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Editar licitação')
        }else{
          // setLoading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }


    function gerarAno(t, v){
      const result = new Date().getFullYear();
      var result2
      if(t === 'add'){
        result2 = parseInt(result) + v;
      }else{
        result2 = parseInt(result) - v;
      }
      return result2; 
    }

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    //recebe o id do esic
    async function enviarFiles(id, tipoCad){
      // setLoading(true)
      setProcess(0)
      console.log("uploadArray >>", id, uploadArray)
      var token = localStorage.getItem('@tokenTransparencia');
      if(uploadedFiles.length === 0){
        history.push('/portal/admin/licitacao');
      }
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-licitacao/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
              // Old version
              onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total))
                console.log("Progresso: ", progress)
                setProcess(progress)
                // updateFile(uploadedF.id, {
                //   progress,
                // })
              },
            },
            // config,
          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviarDadosFile(res.data, id, tipoCad)
            editar(id)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            throw error;
          });
      });
    }
    async function enviarDadosFile(res, id, tipoCad) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('licitacao_id', id);
      form_data.append('name_file', prepararFileName(res.file.clientName));
      form_data.append('type_file', res.file.type+ '/' + res.file.subtype);
      form_data.append('size_file', res.file.size);
      form_data.append('arquivo_esic_link', res.link);

      // form_data_file.append('avatar', {
      //   name: avatar.fileName,
      //   type: avatar.type,
      //   uri: avatar.uri,
      // });

      await api
        .post(`/files-licitacao`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            setLoading(false)
            setUploadedFiles([])
            // editar(parseInt(location.state.id))
            console.log("Dados do arquivo adicionado com sucesso ", res.data)
            toast.info(`Arquivo ${res.data.name_file} enviado com sucesso`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if(tipoCad === 'new'){
              toast.success(`Licitação cadastrada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(function () {
                  limpaCampos()
                  history.push('/portal/admin/licitacao');
              }, 3000);
            }else{
              toast.success(`Licitação editada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error dados do arquivo: ", error, error.response)
          console.log("response: ", error.response.data.message)
        });

    }

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        if(file.type === "application/pdf"){
          setAvisoFile(false)
          setNomeFile(prepararFileName(file.name))
          uploadArray.push({
            file,
            // id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            status: 1,
            type: file.type,
            albun: []
          })
        }else{
          setAvisoFile(true)
        }
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

      // uploadArray.forEach(element => {
      //   console.log("files dentro do array", element)
      //   // processUpload(element)
      // });
    }

    const conteudoModal = (
      <Paper className={'paper'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                label="Nome do arquivo"
                value={nomeFile}
                onChange={handleChangeNomeFile}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>Somente arquivo pdf</div>
            {avisoFile && (
              <div style={{color: 'red'}}>Seu aquivo não é pdf</div>
            )}
          </Grid>
          {uploadedFiles.length === 0 &&(
            <Grid item xs={12} sm={12}>
              <Upload onUpload={handleUpload} />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            {uploadedFiles.map((item, key) => (
              <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <PictureAsPdf />
                <div style={{margin: 10}}>{item.name}</div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={() => setUploadedFiles([]) } variant="contained" color="secondary" disableElevation>
                Remover arquivo
              </Button>
            )}
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={handleClose} variant="contained" color="primary" disableElevation>
                OK
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    function handleChangeDocumento(event){
      setDocumento(event.target.value);
    }
    function handleChangeAno(event){
      setAno(event.target.value);
    }
    function handleChangeModalidade(event){
      setModalidade(event.target.value);
    }
    function handleChangeTipo(event){
      setTipo(event.target.value);
    }
    const handleDateChangeDataAbertura = (date) => {
      console.log("dataAbertura", date.target.value)
      setDataAbertura(date.target.value);
    };
    const handleDateChangeDataFechamento = (date) => {
      setDataFechamento(date);
    };
    function handleChangeLocal(event){
      setLocal(event.target.value);
    }
    function handleChangeValor(event){
      setValor(event);
    }
    function handleChangeSituacao(event){
      setSituacao(event.target.value);
    }
    function handleChangeObjeto(event){
      setObjeto(event.target.value);
    }
    
    function handleChangeNomeFile(event){
      setNomeFile(prepararFileName(event.target.value));
    }

    function handleChangeOrgao(event) {
      setOrgao(event.target.value)
    }

    const handleChangeSwitch = (event) => {
      setDataFechamento(new Date())
      setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
    };

    function prepararFileName(name){
      var resultado = name.replace(".pdf", "");
      console.log("resultado prepararFileName", resultado)
      return resultado
    }
    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }

    async function carregarOrgaos() {
      var token = localStorage.getItem('@tokenTransparencia');
      var config = {
          headers: { Authorization: 'bearer ' + token }
      };
      try {
          const { data: result } = await api.get('orgaos', config);
          console.log('Result orgãos', result);
          setOrgaos(result);
      } catch (error) {
          console.log('Erro ao deletar', error, error.response);
      }
    }

    async function editar(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: resultEdit} = await api.get(`/licitacao/${id}`, config);
        console.log("edit aqui", resultEdit)
        var edit = resultEdit[0];
        // setLoading(false)
        setOrgao(edit.orgao_id);
        setDocumento(edit.licitacao_numero_documento);
        setAno(edit.licitacao_ano);
        setModalidade(edit.licitacao_modalidade);
        setTipo(edit.licitacao_tipo)
        setDataAbertura(edit.licitacao_data_abertura);
        setDataFechamento(edit.licitacao_data_fechamento);
        setLocal(edit.licitacao_local);
        setValor(edit.licitacao_valor);
        setSituacao(edit.licitacao_situacao);
        setObjeto(edit.licitacao_descricao);
        setArquivosLicitacao(edit.fileLicitacao)
        toast.info('🦄 Carregando dados!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setLoading(false);
      }
    }
    

    async function salvar(event) {
      event.preventDefault();
      console.log("dataFechamento", dataFechamento,  dataFechamento ? 'teste1' : 'teste2')
      console.log("event", event)
      setLoading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("orgao_id", orgao);
      form_data.append("licitacao_situacao", situacao);
      form_data.append("licitacao_numero_documento", documento);
      form_data.append("licitacao_ano", ano);
      form_data.append("licitacao_modalidade", modalidade);
      form_data.append("licitacao_tipo", tipo);
      form_data.append("licitacao_data_abertura", dataAbertura ? dataAbertura : '0000-00-00');
      form_data.append("licitacao_data_fechamento", dataFechamento ? dataFechamento : '0000-00-00');
      form_data.append("licitacao_local", local);
      form_data.append("licitacao_valor", valor);
      form_data.append("licitacao_descricao", objeto);

      if(tipoCrud === 'new'){
        await api
          .post(`/licitacao`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando cliente", res.data)
              if(uploadedFiles.length === 0){
                limpaCampos();
                setLoading(false);
                toast.success('🦄 Licitação cadastrada com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }else(
                enviarFiles(res.data.id, 'new')
              )

          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Licitação, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
            // throw error;
          });
  
      }else if(tipoCrud === 'edit'){
  
        await api
          .post(`/licitacao/${parseInt(location.state.id)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cliente editado", res.data)
              // editar(res.data.id)
              if(uploadedFiles.length === 0){
                editar(res.data.id)
                setLoading(false);
                toast.success('🦄 Licitação editada com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                
              }else{
                enviarFiles(res.data.id, 'edit')
              }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
            toast.error('🦄 Erro ao editar Licitação, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
  
      }
  
    }

    async function deletarRegistroFile(bucket, linkFile, id) {     
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/files-licitacao/${id}`, config);
        console.log("prod", prod)
        removeFile(bucket, linkFile)
        toast.success('🦄 Arquivo deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        console.log("Erro ao deletar Arquivo", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao deletar arquivo, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function removeFile(bucket, linkFile) {

      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("bucket", bucket);
      form_data.append("linkFile", linkFile);
      await api
        .post(`/file-remove`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            editar(parseInt(location.state.id))
            console.log("Arquivo removido", res.data)
            toast.success('🦄 Arquivo removido!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`🦄 Erro ao remover file, ${error.response.data.message}!`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          // throw error;
        });
    }

    function limpaCampos(){
      setDocumento('');
      setAno('')
      setModalidade('')
      setTipo('')
      setDataAbertura('');
      setDataFechamento('');
      setLocal('');
      setValor('');
      setSituacao('');
      setObjeto('');
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(documento && ano && modalidade && tipo && dataAbertura && local && situacao && objeto);
    // validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/licitacao">
                                    Licitação
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Cadastrar
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                  <div className="paperContant">
                                    <form noValidate autoComplete="off" onSubmit={salvar}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="fname"
                                                name="nome"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="N° Doc"
                                                value={documento}
                                                onChange={handleChangeDocumento}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl fullWidth variant="outlined">
                                            <InputLabel id="ano-label">Ano</InputLabel>
                                            <Select
                                              labelId="ano-label"
                                              id="ano-label"
                                              value={ano}
                                              onChange={handleChangeAno}
                                              label="Ano"
                                            >
                                              <MenuItem value={gerarAno('add', 0)}>{gerarAno('add', 0)}</MenuItem>
                                              <MenuItem value={gerarAno('sub', 1)}>{gerarAno('sub', 1)}</MenuItem>
                                              <MenuItem value={gerarAno('add', 1)}>{gerarAno('add', 1)}</MenuItem>
                                              <MenuItem value={gerarAno('add', 2)}>{gerarAno('add', 2)}</MenuItem>
                                              <MenuItem value={gerarAno('add', 3)}>{gerarAno('add', 3)}</MenuItem>
                                              <MenuItem value={gerarAno('add', 4)}>{gerarAno('add', 4)}</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl fullWidth variant="outlined">
                                            <InputLabel id="modalidade-label">Modalidade</InputLabel>
                                            <Select
                                              labelId="modalidade-label"
                                              id="modalidade-label"
                                              value={modalidade}
                                              onChange={handleChangeModalidade}
                                              label="Modalidade"
                                            >
                                              <MenuItem value={'Carta Convite'}>Carta Convite</MenuItem>
                                              <MenuItem value={'Concorrência'}>Concorrência</MenuItem>
                                              <MenuItem value={'Concurso'}>Concurso</MenuItem>
                                              <MenuItem value={'Dispensa'}>Dispensa</MenuItem>
                                              <MenuItem value={'Credenciamento'}>Credenciamento</MenuItem>
                                              <MenuItem value={'Inexigibilidade'}>Inexigibilidade</MenuItem>
                                              <MenuItem value={'Leilão'}>Leilão</MenuItem>
                                              <MenuItem value={'Pregão Eletrônico'}>Pregão Eletrônico</MenuItem>
                                              <MenuItem value={'Pregão Presencial'}>Pregão Presencial</MenuItem>
                                              <MenuItem value={'Tomada de Preços'}>Tomada de Preços</MenuItem>
                                              <MenuItem value={'Chamada Pública'}>Chamada Pública</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl fullWidth variant="outlined">
                                            <InputLabel id="tipo-label">Tipo</InputLabel>
                                            <Select
                                              labelId="tipo-label"
                                              id="tipo-label"
                                              value={tipo}
                                              onChange={handleChangeTipo}
                                              label="Tipo"
                                            >
                                              <MenuItem value={'Menor Preço'}>Menor Preço</MenuItem>
                                              <MenuItem value={'Melhor Técnica'}>Melhor Técnica</MenuItem>
                                              <MenuItem value={'Técnica e Preço'}>Técnica e Preço</MenuItem>
                                              <MenuItem value={'Maior lance ou Oferta'}>Maior lance ou Oferta</MenuItem>
                                              <MenuItem value={'Por Item'}>Por Item</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <FormControl fullWidth className="formControl" variant="outlined">
                                            <InputLabel id="demo-simple-select-outlined-label">Orgão</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-outlined-label"
                                              id="demo-simple-select-outlined"
                                              value={orgao}
                                              onChange={handleChangeOrgao}
                                              label="Orgão"
                                            >
                                              <MenuItem value="">Selecione o orgão</MenuItem>
                                              {orgaos.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.nome_orgao}</MenuItem>
                                              ))}
                                              
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          {/* <InputLabel id="date-picker-dialog-abertura">Data abertura</InputLabel> */}
                                          <TextField
                                              autoComplete="fname"
                                              name="date-picker-dialog-abertura"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              label="Data abertura"
                                              value={dataAbertura}
                                              onChange={handleDateChangeDataAbertura}
                                              type="date"
                                              // defaultValue="2017-05-24"
                                          />
                                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <InputLabel id="date-picker-dialog-abertura">Data abertura</InputLabel>
                                            <span>{dataAbertura}</span>
                                            <KeyboardDatePicker
                                                style={{marginBottom: 0, marginTop: 0}}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                id="date-picker-dialog-abertura"
                                                format="dd/MM/yyyy"
                                                value={dataAbertura}
                                                onChange={handleDateChangeDataAbertura}
                                                KeyboardButtonProps={{
                                                  'aria-label': 'change date',
                                                }}
                                                invalidDateMessage={'Data invalida'}
                                            />
                                          </MuiPickersUtilsProvider> */}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          {!stateSwitch.checkedB && (
                                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                              <Switch
                                                checked={stateSwitch.checkedB}
                                                onChange={handleChangeSwitch}
                                                color="primary"
                                                name="checkedB"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                              />
                                              <span>Habilidar data de fechamento</span>
                                            </div>
                                          )}
                                          {stateSwitch.checkedB && (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                              <InputLabel id="date-picker-dialog-fechamento">Data fechamento</InputLabel>
                                              <KeyboardDatePicker
                                                  style={{marginBottom: 0, marginTop: 0}}
                                                  fullWidth
                                                  variant="filled"
                                                  margin="normal"
                                                  id="date-picker-dialog-fechamento"
                                                  // label="Data fechamento"
                                                  format="dd/MM/yyyy"
                                                  value={dataFechamento}
                                                  onChange={handleDateChangeDataFechamento}
                                                  KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                  }}
                                                  // inputValue={'00/00/0000'}
                                                  invalidDateMessage={'Data invalida'}
                                              />
                                            </MuiPickersUtilsProvider>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {/* <TextField
                                                autoComplete="fname"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Local"
                                                value={local}
                                                onChange={handleChangeLocal}
                                            /> */}
                                            <TextField
                                                autoComplete="flocal"
                                                name="local"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Local"
                                                value={local}
                                                onChange={handleChangeLocal}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            {/* <TextField
                                                autoComplete="fname"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="R$ Valor"
                                                value={valor}
                                                onChange={handleChangeValor}
                                            /> */}
                                            <Moeda 
                                              value={valor}
                                              currency={'BRL'}
                                              placeholder="R$ 0.00"
                                              handleChangeValor={handleChangeValor}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <FormControl fullWidth variant="outlined">
                                            <InputLabel id="situacao-label">Situação</InputLabel>
                                            <Select
                                              labelId="situacao-label"
                                              id="situacao-label"
                                              value={situacao}
                                              onChange={handleChangeSituacao}
                                              label="Situação"
                                            >
                                              <MenuItem value={'Aberta'}>Aberta</MenuItem>
                                              <MenuItem value={'Fechada'}>Fechada</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                              label="Objeto"
                                              multiline
                                              fullWidth
                                              rows={4}
                                              value={objeto}
                                              onChange={handleChangeObjeto}
                                              variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {arquivosLicitacao.length != 0 &&(
                                            <div>Arquivos cadastrados: </div>
                                          )}
                                          {arquivosLicitacao.map((item, key) => (
                                              <div 
                                                key={key} 
                                                style={{
                                                  display: 'flex', 
                                                  justifyContent: 'flex-start', 
                                                  alignItems: 'center',
                                                  width: '100%',
                                                  padding: 5,
                                                  background: '#f7f7f7',
                                                  marginTop: 10,
                                                  marginBottom: 15
                                                }}
                                              >
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>
                                                <a href={linkApi + '/files/' + item.arquivo_esic_link} target="_blank" title={item.name_file}>{item.name_file}</a>
                                              </div>
                                              <div style={{margin: 10}}>
                                                <Close 
                                                  onClick={() => 
                                                    deletarRegistroFile('licitacao', item.arquivo_esic_link, item.id)
                                                    // removeFile('licitacao', item.name_file)
                                                  }
                                                  style={{
                                                    cursor: 'pointer',
                                                    color: 'red'
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {uploadedFiles.length != 0 &&(
                                            <div>Novos arquivos: </div>
                                          )}
                                          {uploadedFiles.map((item, key) => (
                                            <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>{item.name}</div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid style={{background: '#f7f7f7', padding: 5}} item xs={12} sm={12}>
                                          <Button style={{margin: 5}} disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                            {txtBtn}
                                          </Button>
                                          <Button disabled={!validForm} onClick={handleOpen} variant="contained" color="secondary" disableElevation>
                                            Enviar arquivos
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </form>
                                    <ToastContainer
                                      position="bottom-left"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                    />
                                  </div>
                              </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                <ModalView 
                  open={open} 
                  handleClose={handleClose}
                  conteudoModal={conteudoModal}
                />


            </main>
            {loading && (
              <div className="loading">
                <CircularProgress />
                <div style={{fontSize: 21}}>Aguarde, estamos enviando seu arquivo ... {process} {'%'}</div>
              </div>
            )}
        </div>
    );
}
