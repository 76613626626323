import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../Components/Tables/esic';
import api, {linkApi} from '../../services/api';
import {veryAuth} from '../../auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import {format} from 'date-fns';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function Index() {
    const location = useLocation();
    const [montarTela, setMontarTela] = useState(false);
    const [roles, setRoles] = useState('');
    const [loading, setLoading] = useState(false);
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [dataInteracoes, setDataInteracoes] = useState([]);
    const [meuId, setMeuId] = useState('');


    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        verSocilitacao(parseInt(location.state.id))
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      setLoading(true)
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        setLoading(false)
        if(result.data.role === 'usuario'){
          setMontarTela(true)
        }else{
          history.push('/');
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function verSocilitacao(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: dados} = await api.get(`/esic/${id}`, config);
        console.log("result edit", dados)
        setData(dados)
        
        verInterecaoes(parseInt(location.state.id))
        toast.success('🦄 Buscando dados da solicitação!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados da solicitação", error, error.response);
        toast.error('🦄 Erro ao buscar dados da solicitação, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    async function verInterecaoes(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: dados} = await api.get(`/interacoes-esic/${id}`, config);
        console.log("Carregar Interações", dados[0])
        setMeuId(dados[0].user.id)
        setSolicitacao(dados[0])
        setDataInteracoes(dados)
      } catch (error) {
        console.log("Erro buscar dados da interações", error, error.response);
      }
    }


    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
  
    //table
    function createData(id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado) {
        return { id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.protocolo_esic, 
              row.orgao.nome_orgao, 
              row.forma_recebimento_esic, 
              row.status_esic, 
              row.identidade_preservada,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'forma_recebimento_esic', numeric: true, disablePadding: false, label: 'Recebimento' },
        { id: 'status_esic', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            {montarTela && (
              <>
                <Header />
                <main className="content">
                    <div className="appBarSpacer" />
                    <Container maxWidth="lg" className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                      className="linkBreadcrumbs"
                                      color="inherit"
                                      // href="/"
                                      onClick={() => {rota('/')}}
                                    >
                                        Dashboard
                                    </Link>
                                    <Link
                                      className="linkBreadcrumbs"
                                      color="inherit"
                                      // href="/esic"
                                      onClick={() => {rota('/portal/esic')}}
                                    >
                                        e-Sic
                                    </Link>
                                    <Typography variant="body2" color="textPrimary">
                                        Solicitação
                                    </Typography>
                                </Breadcrumbs>
                                <div className="headerPage">
                                    <h2>Acesso à Informação</h2>
                                    {/* <Button className="button-add" variant="contained" color="primary">
                                        Novo
                                    </Button> */}
                                </div>
                            </Grid>
                          
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper className={'paper'}>
                                      {data.map((item, key) => (
                                        <div key={key}>
                                          <div className="paperHeader">
                                              <Typography variant="subtitle1" gutterBottom>
                                                  Sua solicitação. Protocolo <strong>{item.protocolo_esic}</strong>
                                              </Typography>
                                          </div>
                                          <div className="paperContant">
                                            <div className="privado-solicitacao">
                                              <div className="privado">
                                                <p>Gostaria de ter a minha identidade preservada neste pedido, conforme o disposto no art. 10, § 7º da Lei nº 13.460/2017.</p>
                                                {item.identidade_preservada === 'nao' &&(
                                                  <Chip label="NÃO" style={{background: 'green', color:'#ffff', marginLeft: 10}} />
                                                )}
                                                {item.identidade_preservada === 'sim' &&(
                                                  <Chip label="SIM" style={{background: 'red', color:'#ffff', marginLeft: 10}} />
                                                )}
                                              </div>
                                            </div>
                                            <div className="dadosSolicitante">
                                              <div className="infoSolicitacao">
                                                Solicitante: <strong>{'  '} {item.user.name}</strong>
                                              </div>
                                              <div className="infoSolicitacao">
                                                Orgão: <strong>{'  '} {item.orgao.nome_orgao}</strong>
                                              </div>
                                              <div className="infoSolicitacao">
                                                Criado em: <strong>{'  '} { format(new Date(item.created_at), 'dd/MM/yyyy')}</strong>
                                              </div>
                                              {format(new Date(item.created_at), 'dd/MM/yyyy') != format(new Date(item.updated_at), 'dd/MM/yyyy') && (
                                                <div className="infoSolicitacao">
                                                  Atualidado em: <strong>{'  '} { format(new Date(item.updated_at), 'dd/MM/yyyy')}</strong>
                                                </div>
                                              )}
                                              <div className="infoSolicitacao">
                                                {item.status_esic === 'aberto' &&(
                                                  <Chip style={{background: 'blue', color:'white'}} label="Aberto" />
                                                )}
                                                {item.status_esic === 'respondido' &&(
                                                  <Chip style={{background:'green', color: 'white'}} label="Atendido" />
                                                )}
                                                {item.status_esic === 'indeferido' &&(
                                                  <Chip style={{background:'red', color: 'white'}}  label="Indeferido" />
                                                )}
                                              </div>
                                            </div>
    {/*                                         
                                            <div className="txt-solicitacao">
                                              <div className="txt">
                                                <strong style={{width: '100%'}}>Solicitação: </strong>
                                                <p>{solicitacao.interacao}</p>
                                                {solicitacao.fileEsic.map((file) => (
                                                  <div key={item.id} className="files">
                                                    <a href={file.arquivo_esic_link} title={file.name_file} target='new'>{file.name_file} </a>
                                                  </div>
                                                ))}
                                              </div>
                                            </div> */}
                                            <div className="resposta-solicitacao">
                                              <div className="txt">
                                                {dataInteracoes.map((item) => (
                                                  <div className="txt-dois">
                                                    <strong style={{width: '100%'}}>{meuId === item.user.id ?  'Solicitação: ' : 'Resposta'}</strong>
                                                    <div key={item.id} className="respostas">
                                                      <p>{item.interacao}</p>
                                                        {item.fileEsic.map((file) => (
                                                          <div key={item.id} className="files">
                                                            <a href={linkApi + '/files/' + file.arquivo_esic_link} title={file.name_file} target='new'>{file.name_file} </a>
                                                          </div>
                                                        ))}
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <Footer />
                        </Box>
                    </Container>
                    <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                    {loading && (
                      <div className="loading">
                        <CircularProgress />
                      </div>
                    )}
                </main>
              </>
            )}
        </div>
    );
}
