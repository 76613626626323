import React, { useState, useEffect } from 'react';
/* eslint-disable */
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import {veryAuth} from '../../auth';
import { useHistory } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';

import TelaOuvidoria from './TelasUsuario/ouvidoria';
import TelaSecretaria from './TelasUsuario/secretaria';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [roles, setRoles] = useState('');
    const [montarTela, setMontarTela] = useState(false);
    const [rolesPermission, setRolesPermission] = useState({
      'admin': false,
      'ouvidor': false, 
      'secretaria': false, 
    });
    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        // Atualiza o titulo do documento usando a API do browser
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);
    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        if(result.data.role === 'ouvidoria' || result.data.role === 'admin' || result.data.role === 'secretaria'){
          setMontarTela(true)
          if(result.data.role === 'ouvidoria' || result.data.role === 'admin' || result.data.role === 'secretaria'){
            setRolesPermission({
              'admin': result.data.role === 'admin' ? true : false,
              'ouvidor': result.data.role === 'ouvidoria' ? true : false, 
              'secretaria': result.data.role === 'secretaria' ? true : false, 
            })
          }

        }else{
          history.push('/');
        }
        
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    {rolesPermission.admin && (
                      <TelaOuvidoria />
                    )}
                    {rolesPermission.ouvidor && (
                      <TelaOuvidoria />
                    )}
                    {rolesPermission.secretaria && (
                      <TelaSecretaria />
                    )}
                    {/* <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/"
                                    onClick={handleClick}>
                                    Dashboard
                                </Link>
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                  Dashboard
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Dashboard</h2>
                                <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <TextField
                                    className="textField"
                                    id="outlined-secondary"
                                    label="Faça uma busca aqui!"
                                    variant="outlined"
                                    color="secondary"
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper onClick={() => {history.push('/portal/esic')}} className={'paperDashboard'}>
                                    <Icon className={'fa fa-info'} style={{fontSize: 40, width: 'auto', margin: 10}} />
                                    <div style={{fontSize: 21}}>Solicitação aberta</div>
                                </Paper>
                            )}
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                    <div className="paperHeader">
                                        <Typography variant="subtitle1" gutterBottom>
                                            Title Paper
                                        </Typography>
                                    </div>
                                    <div className="paperContant">{'Content'}</div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid> */}
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
