import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../../Components/Tables/esic';
import api from '../../../services/api';
import {veryAuth} from '../../../auth';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');

    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        socilitacoes();
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function socilitacoes() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('esic', config);
            console.log('Listar todos os esic', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao ao listar pesquisa do esic', error, error.response);
        }
    }
    async function searchEsic(event) {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`/search-esic/${event.target.value}`, config);
            console.log('Resultado da pesquisa de esic', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado) {
        return { id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.protocolo_esic, 
              row.orgao.nome_orgao, 
              row.forma_recebimento_esic, 
              row.status_esic, 
              row.identidade_preservada,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'forma_recebimento_esic', numeric: true, disablePadding: false, label: 'Recebimento' },
        { id: 'status_esic', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    e-Sic
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Acesso à Informação</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                       
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <div className="paperHeader">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Filtrar Solicitação
                                    </Typography>
                                </div>
                                <TextField
                                    className="textField"
                                    label="Digite o protocolo da solicitação!"
                                    variant="outlined"
                                    type="number"
                                    onChange={searchEsic}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Suas solicitações
                                          </Typography>
                                      </div>
                                      <div className="paperContant">
                                        <Tables columns={columns} rows={rows} roles={roles} />
                                      </div>
                                  </Paper>
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
