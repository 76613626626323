import React from 'react';
/* eslint-disable */
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated, isAuthenticatedV2 } from './auth';

import Login from './pages/Login';
import Cadastro from './pages/Site/Cadastro';
// import Dashboard from './pages/Dashboard';
import ModeloUm from './pages/ModeloUm';
import Table from './pages/Table';
import Site from './pages/Site';
import Estatistica from './pages/Site/Estatistica';
import EsicForm from './pages/Site/Esic';
import Esic from './pages/Esic';
import Licitacoes from './pages/Site/Licitacoes';
import VerLicitacao from './pages/Site/VerLicitacao';
import Dashboard from './pages/Dashboard';
import EsicNovaSolicitacao from './pages/Esic/nova-solicitacao';
import EsicSolicitacao from './pages/Esic/solicitacao';
import Ouvidoria from './pages/Site/Ouvidoria';
import OuvidoriaForm from './pages/Ouvidoria';
import VerOuvidoria from './pages/Ouvidoria/ver-ouvidoria';
import Perfil from './pages/Perfil';
import PerguntasRespostas from './pages/Site/PerguntasRespostas';
import Salarios from './pages/Site/Salarios';
import ConsultaPPA from './pages/Site/ConsultaPPA';
//admin
import Admin from './pages/Admin';
import EsicAdmin from './pages/Admin/Esic';
import EsicAdminSolicitacao from './pages/Admin/Esic/solicitacao';
import OuvidoriaAdmin from './pages/Admin/Ouvidoria';
import EsicAdminOuvidoria from './pages/Admin/Ouvidoria/solicitacao';
import AdminLicitacao from './pages/Admin/Licitacao';
import CrudLicitacao from './pages/Admin/Licitacao/crud';
import AdminRH from './pages/Admin/RH';
import AdminComunidade from './pages/Admin/Comunidade';
import CrudComunidade from './pages/Admin/Comunidade/crud';
import AdminDocumentos from './pages/Admin/Documentos';
import CrudDocumentos from './pages/Admin/Documentos/crud';
import PPA from './pages/Admin/PPA';

/*eslint-disable */
const PrivateRoute = ({ component: Component, ...rest }) => ( 
    <Route
        {...rest}
        render={(props) => 
    
          // isAuthenticatedV2(props)
          //   .then((result) => {
          //     console.log("Resultado menus em auth", result);
          //     console.log("Resultado menus em auth", result.data.length);
          //     isAuthenticated() ? (
          //       <Component {...props} />
          //     ) : (
          //       <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          //     )
          //   })
          //   .catch((error) => {
          //     console.error('Opa! Houve um erro ao buscar permissão:', error.message);
          //   })

          isAuthenticated() ? (
              <Component {...props} />
          ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
          
        }
    />
);
/*eslint-enable */

export default function Routes(props) {
    console.log('Props dentro Routes, ', props);
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Site} />
                <Route path="/esic" component={EsicForm} />
                <Route path="/estatistica" component={Estatistica} />
                <Route path="/login" component={Login} />
                <Route path="/cadastro-unico" component={Cadastro} />
                <Route path="/ouvidoria" component={Ouvidoria} />
                <Route path="/licitacoes" component={Licitacoes} />
                <Route path="/licitacao" component={VerLicitacao} />
                <Route path="/perguntas-respostas" component={PerguntasRespostas} />
                <Route path="/salarios-servidores" component={Salarios} />
                <Route path="/consulta-ppa" component={ConsultaPPA} />
                {/* <PrivateRoute path="/" exact component={Dashboard} /> */}
                {/* Rotas privadas */}
                <PrivateRoute path="/dashboard" exact component={Dashboard} />
                {/* <PrivateRoute path="/portal" exact component={Admin} /> */}
                <PrivateRoute path="/portal/esic" exact component={Esic} />
                <PrivateRoute path="/nova-solicitacao" component={EsicNovaSolicitacao} />
                <PrivateRoute path="/ver-solicitacao" component={EsicSolicitacao} />
                <PrivateRoute path="/portal/ouvidoria" component={OuvidoriaForm} />
                <PrivateRoute path="/ver-ouvidoria" component={VerOuvidoria} />
                <PrivateRoute path="/perfil" component={Perfil} />
                <PrivateRoute path="/modelo-um" component={ModeloUm} />
                <PrivateRoute path="/table" component={Table} />

                {/* //Rotas amin */}
                <PrivateRoute path="/portal/admin/dashboard" exact component={Admin} />
                <PrivateRoute path="/portal/admin/esic" exact component={EsicAdmin} />
                <PrivateRoute
                    path="/portal/admin/esic/solicitacao"
                    exact
                    component={EsicAdminSolicitacao}
                />
                <PrivateRoute path="/portal/admin/ouvidoria" exact component={OuvidoriaAdmin} />
                <PrivateRoute
                    path="/portal/admin/ouvidoria/solicitacao"
                    exact
                    component={EsicAdminOuvidoria}
                />
                <PrivateRoute path="/portal/admin/licitacao" exact component={AdminLicitacao} />
                <PrivateRoute path="/portal/admin/licitacao/crud" exact component={CrudLicitacao} />
                <PrivateRoute path="/portal/admin/rh" exact component={AdminRH} />
                <PrivateRoute path="/portal/admin/comunidade" exact component={AdminComunidade} />
                <PrivateRoute
                    path="/portal/admin/comunidade/crud"
                    exact
                    component={CrudComunidade}
                />
                <PrivateRoute path="/portal/admin/documentos" exact component={AdminDocumentos} />
                <PrivateRoute
                    path="/portal/admin/documento/crud"
                    exact
                    component={CrudDocumentos}
                />
                <PrivateRoute path="/portal/admin/ppa" exact component={PPA} />
                {/* <Route path="/galerias/:slug" exact component={Galerias} > 
          <BlogPost />
        </Route> */}
                {/* <Route path="/galerias/" exact component={Galerias} />
        <PrivateRoute path="/admin-clientes" component={Clientes} />
        <PrivateRoute path="/admin-categorias" component={Categoria} />
        <PrivateRoute path="/admin-depoimentos" component={Depoimento} />
        <PrivateRoute path="/admin-albuns" component={Albuns} />
        <PrivateRoute path="/admin-albuns-fotos" component={AlbumFotos} /> */}
            </Switch>
        </BrowserRouter>
    );
}
