import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import api from '../../services/api';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import Select from '@material-ui/core/Select';
import {veryAuth} from '../../auth';

export default function Index() {
    const [roles, setRoles] = useState('');
    const [skeleton, seSkeleton] = useState(true);
    const [loading, setloading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [cep, setCep] = useState('');
    const [pais, setPais] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [whatsapp, setWhatsApp] = useState('');
    const [sexo, setSexo] = useState('');
    const [password, setPassWord] = useState('');
    const [title, setTitle] = useState('Clientes');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      verificarPermissao();
      editar();
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function editar(){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };

      try {
        const {data: edit} = await api.get(`/perfil/`, config);
        console.log("edit ===", edit)
        // setloading(false);
        setName(edit.name)
        setEmail(edit.email)
        setCpfCnpj(edit.cpf_cnpj)
        setCep(edit.cep)
        setPais(edit.pais)
        setEstado(edit.estado)
        setCidade(edit.cidade)
        setRua(edit.rua)
        setNumero(edit.numero)
        setBairro(edit.bairro)
        setTelefone(edit.telefone)
        setWhatsApp(edit.celular_whatsapp)
        // setPassWord(edit.password)
  
        toast.info('🦄 Dados do seu perfil carregado', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setloading(false);
      }
    }

    function handleChangeName(event){
      setName(event.target.value);
    }
    function handleChangeEmail(event){
      setEmail(event.target.value);
    }
    function handleChangeCpfCnpj(event){
      setCpfCnpj(event.target.value);
    }
    function handleChangeCep(event){
      setCep(event.target.value);
    }
    function handleChangePais(event){
      setPais(event.target.value);
    }
    function handleChangeEstado(event){
      setEstado(event.target.value);
    }
    function handleChangeCidade(event){
      setCidade(event.target.value);
    }
    function handleChangeRua(event){
      setRua(event.target.value);
    }
    function handleChangeNumero(event){
      setNumero(event.target.value);
    }
    function handleChangeBairro(event){
      setBairro(event.target.value);
    }
    function handleChangeTelefone(event){
      setTelefone(event.target.value);
    }
    function handleChangeWhatsApp(event){
      setWhatsApp(event.target.value);
    }
    function handleChangeSexo(event){
      setSexo(event.target.value);
    }
    function handleChangePassword(event){
      setPassWord(event.target.value);
    }

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    async function salvar(event) {
      event.preventDefault();

      setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');
  
      // var config = {
      //   headers: {Authorization: 'bearer ' + token},
      // };
  
      let form_data = new FormData();
  
      form_data.append("name", name ? name : '');
      form_data.append("email", email ? email : '');
      form_data.append("password", password ? password : '');
      form_data.append("cpf_cnpj", cpfCnpj ? cpfCnpj : '');
      form_data.append("cep", cep ? cep : '');
      form_data.append("pais", pais ? pais : '');
      form_data.append("estado", estado ? estado : '');
      form_data.append("cidade", cidade ? cidade : '');
      form_data.append("rua", rua ? rua : '');
      form_data.append("numero", numero ? numero : '');
      form_data.append("bairro", bairro ? bairro : '');
      form_data.append("telefone", telefone ? telefone : '');
      form_data.append("celular_whatsapp", whatsapp ? whatsapp : '');
      form_data.append("sexo", sexo ? sexo : '');
      // form_data.append("status", 1);

      await api
          .post(`/perfil/0`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Perfil editado", res.data)
              setloading(false);
              toast.success('🦄 Perfil editado com sucesso!', {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              editar()
          })
          .catch(error => {
            console.log("error: ", error, error.response)
            toast.error('🦄 Erro ao editar perfil, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setloading(false);
          });

    }
     //validações
     const validation = [];
     validation.push(email && email.includes("@") && email.includes(".com"));
     // validation.push(password && password.length >= 6);
     validation.push(cpfCnpj && cpfCnpj.length >= 11);
 
     // if (tipo) {
     //   validation.push(nome && nome.trim());
     //   validation.push(repetirSenha);
     //   validation.push(senha === repetirSenha);
     // }
 
     const validForm = validation.reduce((all, v) => all && v);
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href={roles === 'usuario' ? '/dashboard' : '/portal/admin/dashboard' }> 
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Perfil
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Perfil</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                <div className="paperContant">
                                  <form noValidate autoComplete="off" onSubmit={salvar}>
                                      <Grid container spacing={3}>
                                          <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="name">Nome do cliente</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!name ? true : false}
                                                    id="name"
                                                    label={'Nome do cliente'}
                                                    value={name ? name : ''}
                                                    onChange={handleChangeName}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="email">Email</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!email ? true : false}
                                                    id="email"
                                                    label={'Email'}
                                                    value={email ? email : ''}
                                                    onChange={handleChangeEmail}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cpgcnpj">CPF / CNPJ</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    error={!cpfCnpj ? true : false}
                                                    id="cpgcnpj"
                                                    label={'CPF / CNPJ'}
                                                    value={cpfCnpj ? cpfCnpj : cpfCnpj}
                                                    onChange={handleChangeCpfCnpj}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cep">CEP</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="cep"
                                                    label={'CEP'}
                                                    value={cep}
                                                    onChange={handleChangeCep}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="pais">País</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="pais"
                                                    label={'País'}
                                                    value={pais ? pais : ''}
                                                    onChange={handleChangePais}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="outlined-adornment-amount">Estado</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="outlined-adornment-amount"
                                                    label={'Estado'}
                                                    value={estado}
                                                    onChange={handleChangeEstado}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="cidade">Cidade</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="cidade"
                                                    label={'Cidade'}
                                                    value={cidade ? cidade : ''}
                                                    onChange={handleChangeCidade}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="rua">Rua</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="rua"
                                                    label={'Rua'}
                                                    value={rua ? rua : ''}
                                                    onChange={handleChangeRua}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="numero">Número</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="numero"
                                                    label={'Número'}
                                                    value={numero ? numero : ''}
                                                    onChange={handleChangeNumero}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="bairro">Bairro</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="bairro"
                                                    label={'Bairro'}
                                                    value={bairro ? bairro : ''}
                                                    onChange={handleChangeBairro}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="telefone">Telefone</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="telefone"
                                                    label={'Telefone'}
                                                    value={telefone ? telefone : ''}
                                                    onChange={handleChangeTelefone}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="whatsapp">WhatsApp</InputLabel>
                                                  <OutlinedInput
                                                    disabled={disabled}
                                                    id="whatsapp"
                                                    label={'WhatsApp'}
                                                    value={whatsapp ? whatsapp : ''}
                                                    onChange={handleChangeWhatsApp}
                                                    labelWidth={60}
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                              <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-age-native-simple">Sexo</InputLabel>
                                                <Select
                                                  native
                                                  value={sexo}
                                                  onChange={handleChangeSexo}
                                                  label="Sexo"
                                                >
                                                  <option aria-label="None" value="" />
                                                  <option value={'Masculino'}>Masculino</option>
                                                  <option value={'Feminino'}>Feminino</option>
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth variant="outlined">
                                                  <InputLabel htmlFor="password">Senha</InputLabel>
                                                  <OutlinedInput
                                                    id="password"
                                                    label={'Password'}
                                                    value={password ? password : ''}
                                                    onChange={handleChangePassword}
                                                    labelWidth={60}
                                                    type="password"
                                                  />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Button
                                                  disabled={!validForm}
                                                  style={{maxWidth: 150, height: 50, marginRight: 10}}
                                                  type="submit" variant="outlined" color="primary"
                                                >
                                                  Atualizar dados
                                                </Button>
                                                {/* {tipo === 'edit' &&(
                                                  <Button
                                                    disabled={!validForm}
                                                    style={{maxWidth: 150, height: 50}}
                                                    variant="outlined" color="secondary"
                                                    onClick={deletar}
                                                  >
                                                    Deletar
                                                  </Button>
                                                ) } */}
                                            </Grid>
                                          </Grid>
                                      </form>
                                      <ToastContainer
                                          position="bottom-left"
                                          autoClose={5000}
                                          hideProgressBar={false}
                                          newestOnTop={false}
                                          closeOnClick
                                          rtl={false}
                                          pauseOnFocusLoss
                                          draggable
                                          pauseOnHover
                                        />
                                    </div>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
