import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import Info from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import Tables from '../../Components/Tables/ouvidoria';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [montarTela, setMontarTela] = useState(false);
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(false);

    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        socilitacoes();
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        if(result.data.role === 'usuario'){
          setMontarTela(true)
        }else{
          history.push('/');
        }
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    const handleOpen = (value) => {
      setOpen(true);
      if(value === 1){
        setInfo(value)
      }else if(value === 2){
        setInfo(value)
      }
    };

    const handleClose = () => {
      setOpen(false);
    };

    async function socilitacoes() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('ouvidoria', config);
            console.log('Listar solicitação ouvidoria', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao ao listar ouvidoria', error, error.response);
        }
    }
    async function searchEsic(event) {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`/search-ouvidoria/${event.target.value}`, config);
            console.log('Resultado da pesquisa de ouvidoria', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(id, ouvidoria_protocolo, orgao, ouvidoria_tipo, ouvidoria_assunto, ouvidoria_privado, ouvidoria_status, criado, atualizado) {
        return { id, ouvidoria_protocolo, orgao, ouvidoria_tipo, ouvidoria_assunto, ouvidoria_privado, ouvidoria_status, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.ouvidoria_protocolo, 
              row.orgao.nome_orgao, 
              row.ouvidoria_tipo, 
              row.ouvidoria_assunto, 
              row.ouvidoria_privado,
              row.ouvidoria_status,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'tipo', numeric: true, disablePadding: false, label: 'Tipo' },
        { id: 'assunto', numeric: true, disablePadding: false, label: 'Assunto' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            {montarTela && (
              <>
                <Header />
                <main className="content">
                    <div className="appBarSpacer" />
                    <Container maxWidth="lg" className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        className="linkBreadcrumbs"
                                        color="inherit"
                                        href="/"
                                        onClick={handleClick}>
                                        Dashboard
                                    </Link>
                                    {/* <Link
                                        className="linkBreadcrumbs"
                                        color="inherit"
                                        href="/getting-started/installation/"
                                        onClick={handleClick}>
                                        Core
                                    </Link> */}
                                    <Typography variant="body2" color="textPrimary">
                                        Ouvidoria
                                    </Typography>
                                </Breadcrumbs>
                                <div className="headerPage">
                                    <h2>Acesso à Informação</h2>
                                    {/* <Button className="button-add" variant="contained" color="primary">
                                        Novo
                                    </Button> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={'paper'}>
                                    <div className="btnAcoes">
                                      <section onClick={() => {rota('/ouvidoria')}}>
                                        <div>
                                          <Edit style={{color:"#fff", fontSize: 30}} />
                                        </div>
                                        <div>Enviar Solicitação</div>
                                      </section>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={'paperSearch'}>
                                    <div className="paperHeader">
                                        <Typography variant="subtitle1" gutterBottom>
                                          Acompanhar Solicitação
                                        </Typography>
                                    </div>
                                    <TextField
                                        className="textField"
                                        label="Digite o protocolo da sua solicitação!"
                                        variant="outlined"
                                        type="number"
                                        onChange={searchEsic}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                  <>
                                    {data.length !== 0 && (
                                      <Paper className={'paper'}>
                                          <div className="paperHeader">
                                              <Typography variant="subtitle1" gutterBottom>
                                                  Suas solicitações
                                              </Typography>
                                          </div>
                                          <div className="paperContant">
                                            <Tables columns={columns} rows={rows} roles={roles} />
                                          </div>
                                      </Paper>
                                    )}
                                    {data.length === 0 && (
                                      <Paper className={'paper'}>
                                          <div className="paperContantDois">
                                              <div>Nenhum registro encontrado</div>
                                          </div>
                                      </Paper>
                                    )}
                                  </>
                                )}
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <Footer />
                        </Box>
                    </Container>
                </main>
              </>
            )}
        </div>
    );
}
