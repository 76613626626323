import React from 'react';
/* eslint-disable */
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import '../../styles/global.css';
/* eslint-disable */
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Visibility from '@material-ui/icons/Visibility';
import Refresh from '@material-ui/icons/Refresh';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,

    },
  },
}))(TableRow);

export default function Index(props) {
    // console.log('Propos funcionarios', props);
    const classes = useStyles();
    let history = useHistory();
    const [rowsPerPage, setRowsPerPage] = React.useState(props.perPage);

    const columns = props.columns
    const rows = props.rows
    const roles = props.roles
    var lastPage = props.lastPage
    var page = parseInt(props.page)
    var perPage = props.perPage
    var total = props.total

    const handleChangePage = (event, newPage) => {
      page = newPage
      // console.log("newPage", newPage, lastPage)
      props.chamou(page, rowsPerPage)
    };

    const handleChangeRowsPerPage = (event) => {
      // console.log("+event.target.value", +event.target.value)
      setRowsPerPage(+event.target.value);
      props.chamou(page, +event.target.value)
      // setPage(0);
    };

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/fatura/${id}`, config);
        // console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Fatura deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        // console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    function mascararCPF(cpfV){
      var necValue = cpfV.split('');
      var cpfMontato = '****'+necValue[4]+necValue[5]+necValue[6]+necValue[7]+'***'
      return cpfMontato
    }
    // function verLicitacao(id){
    //   history.push({
    //       pathname: '/licitacao',
    //       // search: '?query=abc',
    //       state: { id: id }
    //   });
    // }

    return (
        <Paper className={'paper'}>

          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  {columns.map((item, key) => (
                    //align={key === 3 || key >= 4 ? 'right' : 'left'}
                    // <div key={key} style={{width: key == 0 ? 250 : 100, textAlign: 'left'}} className="it">
                    //   {item.label}
                    // </div>
                    <StyledTableCell
                      key={key}
                      align={key === 0 || key === 1 || key === 2 || key === 3 ? 'left' : 'right'}
                    >
                      {item.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row">{row.funcionario_nome}</StyledTableCell>
                    <StyledTableCell align="left">{row.funcionario_cargo}</StyledTableCell>
                    <StyledTableCell align="left">{mascararCPF(row.funcionario_cpf)}</StyledTableCell>
                    <StyledTableCell align="left">{row.funcionario_lotacao}</StyledTableCell>
                    <StyledTableCell align="right">{moedaBR(row.funcionario_proventos)}</StyledTableCell>
                    <StyledTableCell align="right">{moedaBR(row.funcionario_descontos)}</StyledTableCell>
                    <StyledTableCell align="right">{moedaBR(row.funcionario_liquido)}</StyledTableCell>
                    <StyledTableCell align="right"></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={'Por página'}
          />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> 
        </Paper>
    );
}
