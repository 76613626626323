/* eslint-disable */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import '../../styles/global.css';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { mainListItems, secondaryListItems } from './listmenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Person from '@material-ui/icons/Person';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Icon from '@material-ui/core/Icon';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    appBar: {
        background: '#ffff',
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36,
        left: 60,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButtonHidden: {
        // display: 'none'
        left: 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        background: '#036508',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        }
    },
    iconesHeader: {
        position: 'absolute',
        right: 20
    }
}));

const StyledMenu = withStyles({
    paper: {
        border: 'none',
        marginTop: 20
    }
})((props) => (
    <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        // '&:focus': {
        //     backgroundColor: theme.palette.primary.main,
        //     '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        //         color: theme.palette.common.white
        //     }
        // }
    }
}))(MenuItem);

export default function Index() {
    const classes = useStyles();
    let history = useHistory();
    const [open, setOpen] = useState(true);
    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [listaMenu, setListaMenu] = useState([]);
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };
    useEffect(() => {
      menu()
    }, []);

    function ListItemLink(props) {
      return <ListItem button component="a" {...props} />;
    }

    async function menu(){
      var token = localStorage.getItem('@tokenTransparencia');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('menu', config);
        console.log("Result menu", result)
        // setData(result)
        setListaMenu(result)
      } catch (error) {
        console.log("Erro menu", error, error.response);
      }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function sair() {
        localStorage.removeItem('@tokenTransparencia'); // remover do localStorage
        history.push('/');
    }

    return (
        <>
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
                        <MenuIcon style={{ color: 'rgb(63, 1, 121)' }} />
                    </IconButton>
                    {/* <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}>
                        Dashboard
                    </Typography> */}
                    <div className={classes.iconesHeader}>
                        {/* <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon style={{ color: 'rgb(63, 1, 121)' }} />
                            </Badge>
                        </IconButton> */}
                        <IconButton onClick={sair} color="inherit">
                            <PowerSettingsNewIcon style={{ color: 'rgb(63, 1, 121)' }} />
                        </IconButton>
                        <IconButton color="inherit">
                            <Avatar
                                onClick={handleClick}
                                alt="WebAtiva"
                                src="/static/images/avatar/1.jpg"
                            />
                        </IconButton>
                    </div>
                </Toolbar>
                <StyledMenu
                    // id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <StyledMenuItem onClick={() => {history.push('/perfil');}}>
                        <ListItemIcon style={{minWidth: 30}}>
                            <Person fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Perfil" />
                    </StyledMenuItem>
                </StyledMenu>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
                }}
                open={open}>
                <div className="imgMenu">
                    <img
                        src="https://i1.wp.com/pinheiro.ma.gov.br/wp-content/uploads/2021/02/LOGO-DE-PINHEIRO-1-min-1-1-min.png?fit=800%2C249"
                        alt="Logo"
                    />
                </div>
                {listaMenu.map((item, key) => (
                    <ListItemLink key={key} href={item.link}>
                        <ListItemIcon>
                            <Icon className={'fa fa-' + item.icon} style={{ color: '#ffff', width: 40 }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: '#ffff' }} primary={item.nome} />
                    </ListItemLink>
                ))}
                <Divider />
            </Drawer>
        </>
    );
}
