import React, { Component } from 'react';
/* eslint-disable */
import Dropzone from 'react-dropzone';

import { DropContainer, UploadMessage } from './styles';

export default class Upload extends Component {
    renderDragMessage = (isDragActive, isDragReject) => {
        if (!isDragActive) {
            return <UploadMessage>Arraste arquivos aqui ...</UploadMessage>;
        }

        if (isDragReject) {
            return <UploadMessage type="error">Arquivos não suportados</UploadMessage>;
        }

        return <UploadMessage type="success">Solte os aquivos aqui</UploadMessage>;
    };
    render() {
        const { onUpload } = this.props;
        console.log("onUpload", onUpload)
        return (
            <Dropzone onDropAccepted={onUpload}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <DropContainer
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}>
                        <input {...getInputProps()} />

                        {this.renderDragMessage(isDragActive, isDragReject)}
                    </DropContainer>
                )}
            </Dropzone>
        );
    }
}
