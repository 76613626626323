import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../../Components/Tables/esic';
import api, {linkApi} from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import {format} from 'date-fns';
import Chip from '@material-ui/core/Chip';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];

export default function Index() {
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [dataInteracoes, setDataInteracoes] = useState([]);
    const [meuId, setMeuId] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [textSolicitacao, setTextSolicitacao] = useState('');
    const [statusEsic, setStatusEsic] = useState('');
    const [loading, setLoading] = useState(false);

    let history = useHistory();
    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verSocilitacao(parseInt(location.state.id))
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        uploadArray.push({
          file,
          // id: uniqueId(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: null,
          status: 1,
          type: file.type,
          albun: []
        })
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

      uploadArray.forEach(element => {
        console.log("files dentro do array", element)
        // processUpload(element)
      });
    }

    async function verSocilitacao(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: dados} = await api.get(`/esic/${id}`, config);
        console.log("result edit", dados)
        setData(dados)
        verInterecaoes(parseInt(location.state.id))

        toast.success('🦄 Buscando dados da solicitação!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados da solicitação", error, error.response);
        toast.error('🦄 Erro ao buscar dados da solicitação, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    async function verInterecaoes(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: dados} = await api.get(`/interacoes-esic/${id}`, config);
        console.log("Carregar Interações", dados[0])
        setMeuId(dados[0].user.id)
        setSolicitacao(dados[0])
        setDataInteracoes(dados)
      } catch (error) {
        console.log("Erro buscar dados da interações", error, error.response);
      }
    }


    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }

    function handleChangeStatusEsic(event) {
      setStatusEsic(event.target.value)
    }
    function handleChangeResposta(event) {
      setTextSolicitacao(event.target.value)
    }

    async function submit(event) {
      event.preventDefault();
      setLoading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('status_esic', statusEsic);

      await api
        .post(`/esic/${parseInt(location.state.id)}`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Adicionando esic", res.data)
            setLoading(false);
            toast.success('🦄 Resposta cadastrada com sucesso!', {
              position: "bottom-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            limparCampos()
            enviaInteracao(res.data.id)
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`Ops!, ${error.response.data.message}`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        });
    }

    async function enviaInteracao(id) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('esic_id', id);
      form_data.append('interacao', textSolicitacao);

      await api
        .post(`/interacoes-esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Enviado interações", res.data)
            enviarFiles(res.data.id)
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
  
        });

    }
    async function enviaInteracao(id) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('esic_id', id);
      form_data.append('interacao', textSolicitacao);

      await api
        .post(`/interacoes-esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Enviado interações", res.data)
            verInterecaoes(parseInt(location.state.id))
            enviarFiles(res.data.id)
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
  
        });

    }

    //recebe o id do esic
    async function enviarFiles(id){
      console.log("uploadArray >>", id, uploadArray)
      var token = localStorage.getItem('@tokenTransparencia');
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-esic/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
            },
            // config,
          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviarDadosFile(res.data, id)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            throw error;
          });
      });
    }
    async function enviarDadosFile(res, id) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
    
      form_data.append('interacoes_esic_id', id);
      form_data.append('name_file', res.file.clientName ? res.file.clientName : 'sem-nome');
      form_data.append('type_file', res.file.type+ '/' + res.file.subtype);
      form_data.append('size_file', res.file.size);
      form_data.append('arquivo_esic_link', res.link);

      // form_data_file.append('avatar', {
      //   name: avatar.fileName,
      //   type: avatar.type,
      //   uri: avatar.uri,
      // });

      await api
        .post(`/files-esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Dados do arquivo adicionado com sucesso ", res.data)
            toast.info(`Arquivo ${res.data.name_file} enviado com sucesso`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setTimeout(function () {
            //     history.goBack()
            // }, 6000);
        })
        .catch(error => {
          setLoading(false);
          console.log("error dados do arquivo: ", error, error.response)
          console.log("response: ", error.response.data.message)
        });

    }


    function limparCampos(){
      setStatusEsic('')
      setTextSolicitacao('')
      setUploadedFiles([])
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(statusEsic);
    validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);
  
    //table
    function createData(id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado) {
        return { id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.protocolo_esic, 
              row.orgao.nome_orgao, 
              row.forma_recebimento_esic, 
              row.status_esic, 
              row.identidade_preservada,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'forma_recebimento_esic', numeric: true, disablePadding: false, label: 'Recebimento' },
        { id: 'status_esic', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  // href="/"
                                  onClick={() => {rota('/portal/admin/dashboard')}}
                                >
                                    Dashboard
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  // href="/esic"
                                  onClick={() => {rota('/portal/admin/esic')}}
                                >
                                    e-Sic
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Solicitação
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Acesso à Informação</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                       
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {data.map((item, key) => (
                                    <div key={key}>
                                      <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Sua solicitação. Protocolo <strong>{item.protocolo_esic}</strong>
                                          </Typography>
                                      </div>
                                      <div className="paperContant">
                                        <div className="privado-solicitacao">
                                          <div className="privado">
                                            <p>Gostaria de ter a minha identidade preservada neste pedido, conforme o disposto no art. 10, § 7º da Lei nº 13.460/2017.</p>
                                            {item.identidade_preservada === 'nao' &&(
                                              <Chip label="NÃO" style={{background: 'green', color:'#ffff', marginLeft: 10}} />
                                            )}
                                            {item.identidade_preservada === 'sim' &&(
                                              <Chip label="SIM" style={{background: 'red', color:'#ffff', marginLeft: 10}} />
                                            )}
                                          </div>
                                        </div>
                                        <div className="dadosSolicitante">
                                          <div className="infoSolicitacao">
                                            Solicitante: <strong>{'  '} {item.user.name}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Orgão: <strong>{'  '} {item.orgao.nome_orgao}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Criado em: <strong>{'  '} { format(new Date(item.created_at), 'dd/MM/yyyy')}</strong>
                                          </div>
                                          {format(new Date(item.created_at), 'dd/MM/yyyy') != format(new Date(item.updated_at), 'dd/MM/yyyy') && (
                                            <div className="infoSolicitacao">
                                              Atualidado em: <strong>{'  '} { format(new Date(item.updated_at), 'dd/MM/yyyy')}</strong>
                                            </div>
                                          )}
                                          <div className="infoSolicitacao">
                                            {item.status_esic === 'aberto' &&(
                                              <Chip style={{background: 'blue', color:'white'}} label="Aberto" />
                                            )}
                                            {item.status_esic === 'respondido' &&(
                                              <Chip style={{background:'green', color: 'white'}} label="Atendido" />
                                            )}
                                            {item.status_esic === 'indeferido' &&(
                                              <Chip style={{background:'red', color: 'white'}}  label="Indeferido" />
                                            )}
                                          </div>
                                        </div>
                                        <div className="resposta-solicitacao">
                                          <div className="txt">
                                            {dataInteracoes.map((item, key) => (
                                              <div key={key} className="txt-dois">
                                                <strong style={{width: '100%'}}>{meuId === item.user.id ?  'Solicitação: ' : 'Interação'}</strong>
                                                <div key={item.id} className="respostas">
                                                  <p>{item.interacao}</p>
                                                    {item.fileEsic.map((file) => (
                                                      <div key={item.id} className="files">
                                                        <a href={linkApi + '/files/' + file.arquivo_esic_link} title={file.name_file} target='new'>{file.name_file} </a>
                                                      </div>
                                                    ))}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                        <div className="resposta-solicitacao">
                                          <form onSubmit={submit} noValidate>
                                            <div className="txt">
                                            <FormControl variant="outlined">
                                              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                              <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={statusEsic}
                                                onChange={handleChangeStatusEsic}
                                                label="Status"
                                              >
                                                <MenuItem value={'Respondido'}>Respondido</MenuItem>
                                                <MenuItem value={'Indeferido'}>Indeferido</MenuItem>
                                              </Select>
                                            </FormControl>
                                            </div>
                                            <div className="txt">
                                              <TextField
                                                id="outlined-multiline-static"
                                                label="Resposta"
                                                multiline
                                                rows={4}
                                                defaultValue="Default Value"
                                                variant="outlined"
                                                value={textSolicitacao}
                                                onChange={handleChangeResposta}
                                              />
                                            </div>
                                            <div className="txt">
                                              <Upload onUpload={handleUpload} />
                                            </div>
                                            <div className="files-carregados">
                                              {uploadedFiles.map((item, key) => (
                                                <img key={key} style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.type === 'image/jpeg' || item.type === 'image/png' || item.type === 'image/jpg' ? item.preview : 'https://images.vexels.com/media/users/3/135458/isolated/preview/79ca0179926d5e59f959e7fdb28f5513-iacute-cone-de-arquivo-azul-by-vexels.png'} alt=""/>
                                                // <>
                                                // {/* {item.typ === 'image/png' || item.typo === 'image/jpeg' }
                                                //   <img style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.preview} alt=""/>
                                                // </> */}
                                                // <img style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.preview} alt=""/>
                                              ))}
                                            </div>
                                            <div className="txt">
                                              <Button disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                                Enviar
                                              </Button>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </Paper>
                            )}
                        </Grid>
                      
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                {loading && (
                  <div className="loading">
                    <CircularProgress />
                  </div>
                )}
            </main>
        </div>
    );
}
