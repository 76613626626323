import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
import Header from './Header';
import Footer from './Footer';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import HelpOutline from '@material-ui/icons/HelpOutline';
import Person from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/QuestionAnswer';
import DoneAll from '@material-ui/icons/DoneAll';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Inf from './PPA/infomacoes';
import Ind from './PPA/indentificacao';
import Ques from './PPA/questionario';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import suces from './assets/palmas.gif';

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#784af4'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}>
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <HelpOutline />,
        2: <Person />,
        3: <GroupAddIcon />,
        4: <DoneAll />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}>
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));
let arrayPrioridades = [];

export default function Index() {
    const classes = useStyles();
    const [loadind, setLoading] = useState(0);
    const [activeStep, setActiveStep] = useState(0);

    //indentificação
    const [cpfExiste, setCpfExiste] = useState(false);
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [sexo, setSexo] = useState('');
    const [zona, setZona] = useState('');
    const [bairro, setBairro] = useState('');
    const [idade, setIdade] = useState('');
    const [escolaridade, setEscolaridade] = useState('');
    const [pessoasnacasa, setPessoasnacasa] = useState('');
    const [renda, setRenda] = useState('');

    //questões
    const [prioridades, setPrioridades] = useState([]);
    const [propostaEducacao, setPropostaEducacao] = useState('');
    const [propostaSaude, setPropostaSaude] = useState('');
    const [propostaAssitenciaSocial, setPropostaAssitenciaSocial] = useState('');
    const [propostaAdministracao, setPropostaAdministracao] = useState('');
    const [propostaInfraestrutura, setPropostaInfraestrutura] = useState('');
    const [propostaSeguranca, setPropostaSeguranca] = useState('');
    const [propostaAgricultura, setPropostaAgricultura] = useState('');
    const [propostaCultura, setPropostaCultura] = useState('');
    const [propostaEsporte, setPropostaEsporte] = useState('');
    const [propostaJuventude, setPropostaJuventude] = useState('');
    const [propostaMeioAmbiente, setPropostaMeioAmbiente] = useState('');
    const [propostaSaneamentoBasico, setPropostaSaneamentoBasico] = useState('');
    const [propostaComunicacao, setPropostaComunicacao] = useState('');
    const [direitosHumanos, setDireitosHumanos] = useState('');
    const [propostaOutras, setPropostaOutras] = useState('');
    const [finalizado, setFinalizado] = useState(true);

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        // verificarPermissao()
        // carregarOrgaos()
        // setTimeout(function () {
        //     seSkeleton(false);
        // }, 3000);
        setFinalizado(true);
        checkDevice();
        console.log(checkDevice());
    }, []);

    function checkDevice() {
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true; // está utilizando celular
        } else {
            return false; // não é celular
        }
    }

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep + 1 === 4) {
            cadastrarIdentificacao();
        }
        console.log('prevActiveStep + 1 ', activeStep + 1);
    };

    const handleBack = () => {
        setCpfExiste(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // indentificação
    function handleChangeNome(event) {
        setNome(event.target.value);
    }
    function handleChangeCpf(event) {
        console.log('cpf ', event.target.value.length);
        if (event.target.value.length <= 14) {
            setCpf(event.target.value);
        }
        if (event.target.value.length === 14) {
            console.log('Executa vericação de existencia');
            verificarCPF(event.target.value);
        }
    }
    function handleChangeSexo(event) {
        setSexo(event.target.value);
    }
    function handleChangeZona(event) {
        setZona(event.target.value);
    }
    function handleChangeBairro(event) {
        setBairro(event.target.value);
    }
    function handleChangeIdade(event) {
        setIdade(event.target.value);
    }
    function handleChangeEscolaridade(event) {
        setEscolaridade(event.target.value);
    }
    function handleChangePessoasnacasa(event) {
        setPessoasnacasa(event.target.value);
    }
    function handleChangeRenda(event) {
        setRenda(event);
    }

    //sugestões
    function removerItemArrayPrioridade(dado) {
        var index = arrayPrioridades.indexOf(dado);
        if (index > -1) {
            arrayPrioridades.splice(index, 1);
        }
        var stringArray = arrayPrioridades.join(' - ');
        console.log('remover item do array', stringArray);
        setPrioridades(stringArray);
        // console.log('remover item do array', arrayPrioridades.toString());
        // console.log('remover item do array', dado, arrayPrioridades, index);
    }
    function montarArrayPrioridade(dado) {
        arrayPrioridades.push(dado);
        var stringArray = arrayPrioridades.join(' - ');
        console.log('add item do stringArray', stringArray);
        // console.log('dados do array', dado, arrayPrioridades);
        // console.log('add item do array', stringArray);
        setPrioridades(stringArray);
    }
    function handleChangePrioridades(event) {
        // setPrioridades(event.target.value);
        console.log('Nome', event);
    }
    function handleChangePropostaEducacao(event) {
        setPropostaEducacao(event.target.value);
        console.log('Nome', propostaEducacao);
    }
    function handleChangePropostaSaude(event) {
        setPropostaSaude(event.target.value);
        console.log('Nome', propostaSaude);
    }
    function handleChangePropostaAssitenciaSocial(event) {
        setPropostaAssitenciaSocial(event.target.value);
        console.log('Nome', propostaAssitenciaSocial);
    }
    function handleChangePropostaAdministracao(event) {
        setPropostaAdministracao(event.target.value);
        console.log('Nome', propostaAdministracao);
    }
    function handleChangePropostaInfraestrutura(event) {
        setPropostaInfraestrutura(event.target.value);
        console.log('Nome', propostaInfraestrutura);
    }
    function handleChangePropostaSeguranca(event) {
        setPropostaSeguranca(event.target.value);
        console.log('Nome', propostaSeguranca);
    }
    function handleChangePropostaAgricultura(event) {
        setPropostaAgricultura(event.target.value);
        console.log('Nome', propostaAgricultura);
    }
    function handleChangePropostaCultura(event) {
        setPropostaCultura(event.target.value);
        console.log('Nome', propostaCultura);
    }
    function handleChangePropostaEsporte(event) {
        setPropostaEsporte(event.target.value);
        console.log('Nome', propostaEsporte);
    }
    function handleChangePropostaJuventude(event) {
        setPropostaJuventude(event.target.value);
        console.log('Nome', propostaJuventude);
    }
    function handleChangePropostaMeioAmbiente(event) {
        setPropostaMeioAmbiente(event.target.value);
        console.log('Nome', propostaMeioAmbiente);
    }
    function handleChangePropostaSaneamentoBasico(event) {
        setPropostaSaneamentoBasico(event.target.value);
        console.log('Nome', propostaSaneamentoBasico);
    }
    function handleChangePropostaComunicacao(event) {
        setPropostaComunicacao(event.target.value);
        console.log('Nome', propostaComunicacao);
    }
    function handleChangePropostaDireitosHumanos(event) {
        setDireitosHumanos(event.target.value);
        console.log('Nome', direitosHumanos);
    }
    function handleChangePropostaOutras(event) {
        setPropostaOutras(event.target.value);
        console.log('Nome', propostaOutras);
    }

    async function verificarCPF(cpf) {
        console.log('aqui', cpf);
        try {
            const { data: edit } = await api.get(`/participante-ppa/${cpf}`);
            console.log('result edit', edit);
            if (edit.length != 0) {
                setCpfExiste(true);
                toast.error('🦄 Você já participou, Obrigado!', {
                    position: 'bottom-left',
                    autoClose: 8000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else {
                setCpfExiste(false);
            }
        } catch (error) {
            console.log('Erro buscar dados para editar', error, error.response);
        }
    }

    async function cadastrarQuestoes(id) {
        let form_data = new FormData();

        form_data.append('consulta_ppa_id', 1);
        form_data.append('participantes_ppa_id', id);
        form_data.append('prioridades', prioridades);
        form_data.append('proposta_educacao', propostaEducacao);
        form_data.append('proposta_saude', propostaSaude);
        form_data.append('proposta_assitencia_social', propostaAssitenciaSocial);
        form_data.append('proposta_administracao', propostaAdministracao);
        form_data.append('proposta_infraestrutura', propostaInfraestrutura);
        form_data.append('proposta_seguranca', propostaSeguranca);
        form_data.append('proposta_agricultura', propostaAgricultura);
        form_data.append('proposta_cultura', propostaCultura);
        form_data.append('proposta_esporte', propostaEsporte);
        form_data.append('proposta_juventude', propostaJuventude);
        form_data.append('proposta_meio_ambiente', propostaMeioAmbiente);
        form_data.append('proposta_saneamento_basico', propostaSaneamentoBasico);
        form_data.append('proposta_comunicacao', propostaComunicacao);
        form_data.append('proposta_d_h_f_mulher', direitosHumanos);
        form_data.append('proposta_outras', propostaOutras);

        await api
            .post(`/questoes-ppa`, form_data, {
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log('Questões cadastradas com sucesso', res.data);

                toast.success(`Obrigado por sua participação`, {
                    position: 'bottom-left',
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                // setTimeout(function () {
                //     history.goBack()
                // }, 6000);
            })
            .catch((error) => {
                setLoading(false);
                console.log('error ao cadastara identificação: ', error, error.response);
                toast.error(`Erro ao cadastrar`, {
                    position: 'bottom-left',
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                console.log('response: ', error.response.data.message);
            });
    }
    async function cadastrarIdentificacao() {
        let form_data = new FormData();

        form_data.append('consulta_ppa_id', 1);
        form_data.append('nome', nome);
        form_data.append('cpf', cpf);
        form_data.append('sexo', sexo);
        form_data.append('zona', zona);
        form_data.append('bairro', bairro);
        form_data.append('idade', idade);
        form_data.append('escolaridade', escolaridade);
        form_data.append('pessoas_na_casa', pessoasnacasa);
        form_data.append('renda_faliliar', renda);

        await api
            .post(`/participante-ppa`, form_data, {
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                console.log('Cadastrado com sucesso', res.data);
                cadastrarQuestoes(res.data.id);
                toast.info(`Identificação cadastrada com sucesso!`, {
                    position: 'bottom-left',
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                // setTimeout(function () {
                //     history.goBack()
                // }, 6000);
            })
            .catch((error) => {
                setLoading(false);
                console.log('error ao cadastara identificação: ', error, error.response);
                toast.error(`Erro ao cadastrar identificação`, {
                    position: 'bottom-left',
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                console.log('response: ', error.response.data.message);
            });
    }

    function getSteps() {
        if (checkDevice()) {
            return ['1', '2', '3', '4'];
        } else {
            return ['Infomações', 'Indentificação', 'Responda o questionário', 'Fanalizado'];
        }
    }

    const informacoes = <Inf />;
    const indentifica = (
        <Ind
            handleChangeNome={handleChangeNome}
            handleChangeCpf={handleChangeCpf}
            cpf={cpf}
            handleChangeSexo={handleChangeSexo}
            handleChangeZona={handleChangeZona}
            handleChangeBairro={handleChangeBairro}
            handleChangeIdade={handleChangeIdade}
            handleChangeEscolaridade={handleChangeEscolaridade}
            handleChangePessoasnacasa={handleChangePessoasnacasa}
            handleChangeRenda={handleChangeRenda}
        />
    );
    const questionarios = (
        <Ques
            removerItemArrayPrioridade={removerItemArrayPrioridade}
            montarArrayPrioridade={montarArrayPrioridade}
            handleChangePrioridades={handleChangePrioridades}
            handleChangePropostaEducacao={handleChangePropostaEducacao}
            handleChangePropostaSaude={handleChangePropostaSaude}
            handleChangePropostaAssitenciaSocial={handleChangePropostaAssitenciaSocial}
            handleChangePropostaAdministracao={handleChangePropostaAdministracao}
            handleChangePropostaInfraestrutura={handleChangePropostaInfraestrutura}
            handleChangePropostaSeguranca={handleChangePropostaSeguranca}
            handleChangePropostaAgricultura={handleChangePropostaAgricultura}
            handleChangePropostaCultura={handleChangePropostaCultura}
            handleChangePropostaEsporte={handleChangePropostaEsporte}
            handleChangePropostaJuventude={handleChangePropostaJuventude}
            handleChangePropostaMeioAmbiente={handleChangePropostaMeioAmbiente}
            handleChangePropostaSaneamentoBasico={handleChangePropostaSaneamentoBasico}
            handleChangePropostaComunicacao={handleChangePropostaComunicacao}
            handleChangePropostaDireitosHumanos={handleChangePropostaDireitosHumanos}
            handleChangePropostaOutras={handleChangePropostaOutras}
        />
    );
    const finalizar = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
                padding: 10,
                minHeight: 100,
                flexDirection: 'column'
            }}>
            <img style={{ width: 130 }} src={suces} alt="Girl in a jacket"></img>
            Ficamos felizes que você chegou até aqui, agora só falta um passo: Clique em Finalizar
            aqui em baixo.
        </div>
    );

    function getStepContent(step) {
        switch (step) {
            case 0:
                return informacoes;
            case 1:
                return indentifica;
            case 2:
                return questionarios;
            case 3:
                return finalizar;
            default:
                return 'Página não encontrada';
        }
    }

    //validações
    const validation = [];
    validation.push('...');
    if (activeStep === 1) {
        validation.push(nome && sexo && zona && bairro && idade && pessoasnacasa);
    }
    if (activeStep === 2) {
        validation.push(nome);
        // validation.push(contato && contato.length === 16);
    }
    if (activeStep === 3) {
        // validation.push(valor > 0);
    }

    const validForm = validation.reduce((all, v) => all && v);

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            {!finalizado && (
                <Container maxWidth="lg">
                    <Stepper
                        alternativeLabel
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel
                                    style={{ fontSize: 11 }}
                                    StepIconComponent={ColorlibStepIcon}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div
                                style={{
                                    background: 'white',
                                    padding: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                <Typography className={classes.instructions}>Sucesso</Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reiniciar
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>
                                    {getStepContent(activeStep)}
                                </Typography>{' '}
                                <div
                                    style={{
                                        background: 'white',
                                        padding: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row'
                                    }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}>
                                        Voltar
                                    </Button>
                                    {!cpfExiste && (
                                        <Button
                                            disabled={!validForm}
                                            variant="contained"
                                            style={{
                                                background: !validForm ? 'red' : 'green',
                                                color: 'white',
                                                padding: 10
                                            }}
                                            onClick={handleNext}
                                            className={classes.button}>
                                            {/* {activeStep === steps.length - 1 ? 'Salvar' : ''} */}
                                            {activeStep === 0 ? 'Quero participar' : ''}
                                            {activeStep === 1 || activeStep === 2 ? 'Próximo' : ''}
                                            {activeStep === 3 ? 'Finalizar' : ''}
                                            {/* {activeStep === 3 ? 'Finalizar' : ''} */}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Container>
            )}
            {finalizado && (
                <Container maxWidth="lg">
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            position: 'relative',
                            width: '100%',
                            height: '100vh'
                        }}>
                        <span style={{ fontSize: 21 }}>Consulta encerrada!</span>
                    </div>
                </Container>
            )}
            <Footer />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {loadind && <div></div>}
        </React.Fragment>
    );
}
