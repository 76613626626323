import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Funcionario from '../../../Components/Tables/funcionario';
import api from '../../../services/api';
import {veryAuth} from '../../../auth';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { jsPDF } from "jspdf";
import Pdf from "react-to-pdf";

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const doc = new jsPDF();
    const ref = React.createRef();

    const [total, setTotal] = useState(0);

    let history = useHistory();

    useEffect(() => {
        verificarPermissao()
        // lerXml()
        
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function handleChangeDadosJson(event){
      setDadosJson(event.target.value);
    }

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        // setRoles(result.data.role)
        carregarParticipantesPPA();
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function carregarParticipantesPPA() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`participante-ppa/`);
            console.log('Listar todos os participantes', result);
            setData(result);
            setTotal(result.total);
            // setLoading(false)
        } catch (error) {
            console.log('Erro ao ao listar participantes', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const Interacao = () => {
      return(
        <div ref={ref}>
          {data.map((item, key) => (
            <div key={key} style={{marginBottom: 30, background: '#f9f9f9', padding: 5, borderBottom: 'solid 1px'}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '50%', fontSize: 18,display: 'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                  <span>Nome: <strong>{item.nome}</strong></span><br/>
                  <span>Bairro: <strong>{item.bairro}</strong></span><br/>
                  <span>Zona: <strong>{item.zona}</strong></span><br/>
                  <span>Escolaridade: <strong>{item.escolaridade}</strong></span><br/>
                  <span>Sexo: <strong>{item.sexo}</strong></span><br/>
                </div>
                <div style={{width: '50%', fontSize: 18,display: 'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                  <span>CPF: <strong>{item.cpf}</strong></span><br/>
                  <span>Idade: <strong>{item.idade}</strong></span><br/>
                  <span>Pessoas na casa: <strong>{item.pessoas_na_casa}</strong></span><br/>
                  <span>Renda familiar: <strong>{moedaBR(item.renda_faliliar)}</strong></span><br/>
                </div>
              </div>
              {item.consultaPpaQuestoe && (
                <div>
                  {item.consultaPpaQuestoe.map((item, key) => (
                    <div key={key}>
                      <div>
                        <p style={{fontSize: 17, fontWeight: 'bold'}}>Prioridades</p> 
                        <p style={{fontSize: 17}}>{item.prioridades}</p>
                      </div>
                      <div>
                        {item.proposta_administracao && (
                          <p style={{fontSize: 17}}>{item.proposta_administracao}</p>
                        )}
                        {item.proposta_agricultura && (
                          <p style={{fontSize: 17}}>{item.proposta_agricultura}</p>
                        )}
                        {item.proposta_assitencia_social && (
                          <p style={{fontSize: 17}}>{item.proposta_assitencia_social}</p>
                        )}
                        {item.proposta_comunicacao && (
                          <p style={{fontSize: 17}}>{item.proposta_comunicacao}</p>
                        )}
                        {item.proposta_cultura && (
                          <p style={{fontSize: 17}}>{item.proposta_cultura}</p>
                        )}
                        {item.proposta_d_h_f_mulher && (
                          <p style={{fontSize: 17}}>{item.proposta_d_h_f_mulher}</p>
                        )}
                        {item.proposta_educacao && (
                          <p style={{fontSize: 17}}>{item.proposta_educacao}</p>
                        )}
                        {item.proposta_esporte && (
                          <p style={{fontSize: 17}}>{item.proposta_esporte}</p>
                        )}
                        {item.proposta_infraestrutura && (
                          <p style={{fontSize: 17}}>{item.proposta_infraestrutura}</p>
                        )}
                        {item.proposta_juventude && (
                          <p style={{fontSize: 17}}>{item.proposta_juventude}</p>
                        )}
                        {item.proposta_meio_ambiente && (
                          <p style={{fontSize: 17}}>{item.proposta_meio_ambiente}</p>
                        )}
                        {item.proposta_outras && (
                          <p style={{fontSize: 17}}>{item.proposta_outras}</p>
                        )}
                        {item.proposta_saneamento_basico && (
                          <p style={{fontSize: 17}}>{item.proposta_saneamento_basico}</p>
                        )}
                        {item.proposta_saude && (
                          <p style={{fontSize: 17}}>{item.proposta_saude}</p>
                        )}
                        {item.proposta_seguranca && (
                          <p style={{fontSize: 17}}>{item.proposta_seguranca}</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )
    }

    function gerarPDF(){
      doc.html(Interacao, {
        callback: function (doc) {
          doc.save();
        },
        x: 10,
        y: 10
     });
      doc.save("a4.pdf");
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    PPA
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>PPA</h2>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      {/* <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Funcionários
                                          </Typography>
                                      </div> */}
                                      <div className="paperContant">
                                        <Button onClick={gerarPDF}>
                                          Gerar PDF
                                        </Button>
                                        <Pdf targetRef={ref} filename="code-example.pdf">
        {({ toPdf }) => <button onClick={toPdf}>Generate Pdf</button>}
      </Pdf>
      {/* <div ref={ref}>
        <h1>Hello CodeSandbox</h1>
        <h2>Start editing to see some magic happen!</h2>
      </div> */}
                                        <Interacao />
                                      </div>
                                  </Paper>
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
