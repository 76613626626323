import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Tables from '../../../Components/Tables/licitacao';
import api, {linkApi} from '../../../services/api';
import {veryAuth} from '../../../auth';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@material-ui/core/Modal';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import Close from '@material-ui/icons/Close';
import ModalView from '../../../Components/Modal';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
import CircularProgress from '@material-ui/core/CircularProgress';
import Moeda from '../../../Components/Mask/Moeda';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function Index() {
    let history = useHistory();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [skeleton, seSkeleton] = useState(true);
    const [tipoCrud, setTipoCrud] = useState('');
    const [txtBtn, setTxtBtn] = useState('');
    const [title, setTitle] = useState('Cadastrar novo Documento');
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const [documento, setDocumento] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [dataAbertura, setDataAbertura] = useState();
    const [dataFechamento, setDataFechamento] = useState('');
    const [local, setLocal] = useState('');
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState('Aberta');
    const [objeto, setObjeto] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nomeFile, setNomeFile] = useState('');
    const [arquivosDocumento, setArquivosDocumento] = useState([]);
    const [avisoFile, setAvisoFile] = useState(false);
    const [contador, setContador] = useState(0);
    const [process, setProcess] = useState(0);

    const [tipoDocumento, setTipoDocumento] = useState('');
    const [orgaos, setOrgaos] = useState('');
    const [dataExpedicao, setDataExpedicao] = useState();
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [assunto, setAssunto] = useState('');
    const [situacaoDocumento, setSituacaoDocumento] = useState('');
    const [observacoesDocumento, setObservacoesDocumento] = useState('');

    const [dataResolucao, setDataResolucao] = useState('');
    const [citePendencia, setCitePendencia] = useState('');
    const [citeSitucao, setCiteSitucao] = useState('');
    const [dataConclusao, setDataConclusao] = useState('');

    const [situacaoComunicacao, setSituacaoComunicacao] = useState('');
    const [possuiEscola, setPossuiEscola] = useState('');

    const [stateSwitch, setStateSwitch] = useState({
      checkedA: true,
      checkedB: false,
    });
    const [stateReligiao, setStateReligiao] = useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });
    let count = 0;
    useEffect(() => {
        verificarPermissao()
        if(location.state){
          setTipoCrud(location.state.type)
          setTxtBtn(location.state.type === 'edit' ? 'Editar' : 'Salvar')
        }else{
          history.push('/')
        }
        if(location.state.type === 'edit'){
          editar(parseInt(location.state.id))
          setTitle('Editar documento')
        }else{
          // setLoading(false)
        }
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }


    function gerarAno(t, v){
      const result = new Date().getFullYear();
      var result2
      if(t === 'add'){
        result2 = parseInt(result) + v;
      }else{
        result2 = parseInt(result) - v;
      }
      return result2; 
    }

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    //recebe o id do esic
    async function enviarFiles(id, tipoCad){
      // setLoading(true)
      setProcess(0)
      console.log("uploadArray >>", id, uploadArray)
      var token = localStorage.getItem('@tokenTransparencia');
      if(uploadedFiles.length === 0){
        history.push('/portal/admin/documentos');
      }
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-documento/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
              // Old version
              onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total))
                console.log("Progresso: ", progress)
                setProcess(progress)
                // updateFile(uploadedF.id, {
                //   progress,
                // })
              },
            },
            // config,
          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviarDadosFile(res.data, id, tipoCad)
            editar(id)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            toast.error(`Erro ao enviar arquivo`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false)
            throw error;
          });
      });
    }
    async function enviarDadosFile(res, id, tipoCad) {
      var token = localStorage.getItem('@tokenTransparencia');
      console.log("res.file.", res, id, tipoCad)
      let form_data = new FormData();
      form_data.append('documento_id', id);
      form_data.append('name_file', prepararFileName(res.file.clientName));
      form_data.append('type_file', res.file.type+ '/' + res.file.subtype);
      form_data.append('size_file', res.file.size);
      form_data.append('arquivo_link', res.link);

      // form_data_file.append('avatar', {
      //   name: avatar.fileName,
      //   type: avatar.type,
      //   uri: avatar.uri,
      // });

      await api
        .post(`/files-documento`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            setLoading(false)
            setUploadedFiles([])
            // editar(parseInt(location.state.id))
            console.log("Dados do arquivo adicionado com sucesso ", res.data)
            toast.info(`Arquivo ${res.data.name_file} enviado com sucesso`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if(tipoCad === 'new'){
              toast.success(`Licitação cadastrada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(function () {
                  limpaCampos()
                  history.push('/portal/admin/documentos');
              }, 1000);
            }else{
              toast.success(`Licitação editada com sucesso`, {
                position: "bottom-left",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error dados do arquivo: ", error, error.response)
          console.log("response: ", error.response.data.message)
        });

    }

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        // if(file.type === "application/pdf"){
          setAvisoFile(false)
          setNomeFile(prepararFileName(file.name))
          uploadArray.push({
            file,
            // id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null,
            status: 1,
            type: file.type,
            albun: []
          })
        // }else{
        //   setAvisoFile(true)
        // }
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

      // uploadArray.forEach(element => {
      //   console.log("files dentro do array", element)
      //   // processUpload(element)
      // });
    }

    const conteudoModal = (
      <Paper className={'paper'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                label="Nome do arquivo"
                value={nomeFile}
                onChange={handleChangeNomeFile}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>Somente arquivo pdf</div>
            {avisoFile && (
              <div style={{color: 'red'}}>Seu aquivo não é pdf</div>
            )}
          </Grid>
          {uploadedFiles.length === 0 &&(
            <Grid item xs={12} sm={12}>
              <Upload onUpload={handleUpload} />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            {uploadedFiles.map((item, key) => (
              <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <PictureAsPdf />
                <div style={{margin: 10}}>{item.name}</div>
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={() => setUploadedFiles([]) } variant="contained" color="secondary" disableElevation>
                Remover arquivo
              </Button>
            )}
            {uploadedFiles.length > 0 &&(
              <Button style={{margin: 5}} onClick={handleClose} variant="contained" color="primary" disableElevation>
                OK
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    );

    function handleChangeTipoDocumento(event){
      setTipoDocumento(event.target.value);
    }

    function handleChangeOrgaos(event){
      setOrgaos(event.target.value);
    }

    function handleChangeDataExpedicao(date){
      setDataExpedicao(date);
    }

    function handleChangeNumeroDocumento(event){
      setNumeroDocumento(event.target.value);
    }

    function handleChangeAssunto(event){
      setAssunto(event.target.value);
    }

    function handleChangeSituacaoDocumento(event){
      setSituacaoDocumento(event.target.value);
    }

    function handleChangeObservacoesDocumento(event){
      setObservacoesDocumento(event.target.value);
    }

    function handleChangeDataResolucao(date){
      setDataResolucao(date);
    }

    function handleChangeCitePendencia(event){
      setCitePendencia(event.target.value);
    }

    function handleChangeCiteSitucao(event){
      setCiteSitucao(event.target.value);
    }

    function handleChangeDataConclusao(event){
      setDataConclusao(event.target.value);
    }

    function handleChangeNomeFile(event){
      setNomeFile(prepararFileName(event.target.value));
    }

    const handleChangeSwitch = (event) => {
      setDataFechamento(new Date())
      setStateSwitch({ ...stateSwitch, [event.target.name]: event.target.checked });
    };
    const handleChangeReligiao = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };

    function prepararFileName(name){
      var resultado = name.replace(".pdf", "");
      console.log("resultado prepararFileName", resultado)
      return resultado
    }
    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }

    async function editar(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: result} = await api.get(`/documento/${id}`, config);
        console.log("edit aqui", result)
        var resultEdit = result[0];
        setTipoDocumento(resultEdit.documento_tipo);
        setOrgaos(resultEdit.documento_orgao);
        setDataExpedicao(resultEdit.documento_data_expedicao)
        setDataAbertura(resultEdit.documento_data_expedicao)
        setDataResolucao(resultEdit.documento_data_resolucao)
        setDataConclusao(resultEdit.documento_data_conclusao)
        setCitePendencia(resultEdit.documento_citacao_pendencias)
        setCiteSitucao(resultEdit.documento_citacao_situacao)
        setNumeroDocumento(resultEdit.documento_numero)
        setAssunto(resultEdit.documento_assunto)
        setSituacaoDocumento(resultEdit.documento_situacao)
        setObservacoesDocumento(resultEdit.documento_obs)
        setArquivosDocumento(resultEdit.fileDocumento)

        toast.info('🦄 Carregando dados!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados para editar", error, error.response);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // setLoading(false);
      }
    }
    

    async function salvar(event) {
      event.preventDefault();
      setLoading(true)
  
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      // form_data.append("orgao_id", orgao);
      form_data.append("documento_tipo", tipoDocumento);
      form_data.append("documento_orgao", orgaos);
      form_data.append("documento_data_expedicao", dataExpedicao);
      form_data.append("documento_data_resolucao", dataResolucao);
      form_data.append("documento_data_conclusao", dataConclusao);
      form_data.append("documento_numero", numeroDocumento);
      form_data.append("documento_assunto", assunto);
      form_data.append("documento_situacao", situacaoDocumento);
      form_data.append("documento_citacao_pendencias", citePendencia);
      form_data.append("documento_citacao_situacao", citeSitucao);
      form_data.append("documento_obs", observacoesDocumento);

      if(tipoCrud === 'new'){
        await api
          .post(`/documento`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando documentos", res.data)
              if(uploadedFiles.length === 0){
                limpaCampos();
                setLoading(false);
                toast.success('🦄 Documento cadastrado com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(function () {
                  limpaCampos()
                  history.push('/portal/admin/documentos');
                }, 1000);
              }else(
                enviarFiles(res.data.id, 'new')
              )

          })
          .catch(error => {
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`🦄 Erro ao cadatrar Documento, ${error.response.data.message}!`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
            // throw error;
          });
  
      }else if(tipoCrud === 'edit'){
  
        await api
          .post(`/documento/${parseInt(location.state.id)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Cliente editado", res.data, parseInt(location.state.id))
              // editar(res.data.id)
              if(uploadedFiles.length === 0){
                editar(res.data.id)
                setLoading(false);
                toast.success('🦄 Documento editado com sucesso!', {
                  position: "bottom-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                
              }else{
                enviarFiles(res.data.id, 'edit')
              }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
            toast.error('🦄 Erro ao editar Documento, tente novamente ou fale com o suporte!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setLoading(false);
          });
  
      }
  
    }

    async function deletarRegistroFile(bucket, linkFile, id) {     
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/files-documento/${id}`, config);
        console.log("prod", prod)
        removeFile(bucket, linkFile)
        toast.success('🦄 Arquivo deletado com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        console.log("Erro ao deletar Arquivo", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao deletar arquivo, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    async function removeFile(bucket, linkFile) {

      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("bucket", bucket);
      form_data.append("linkFile", linkFile);
      await api
        .post(`/file-remove`, form_data, {
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
            Accept: "application/json"
          }
        })
        .then(res => {
            editar(parseInt(location.state.id))
            console.log("Arquivo removido", res.data)
            toast.success('🦄 Arquivo removido!', {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`🦄 Erro ao remover file, ${error.response.data.message}!`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoading(false);
          // throw error;
        });
    }

    function limpaCampos(){
      setTipoDocumento('');
      setOrgaos('');
      setDataExpedicao('')
      setDataAbertura('')
      setDataConclusao('')
      setCitePendencia('')
      setCiteSitucao('')
      setNumeroDocumento('')
      setAssunto('')
      setObservacoesDocumento('')
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(
      tipoDocumento,
      orgaos,
      // dataExpedicao,
      numeroDocumento,
      assunto,
      situacaoDocumento
    );
    // validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/documentos">
                                    Documento
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    {tipo === 'new' ? 'Cadastrar' : 'Editar'}
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>{title}</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <Paper className={'paper'}>
                                  <div className="paperContant">
                                    <form noValidate autoComplete="off" onSubmit={salvar}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                          <span style={{fontSize: 12}}>Tipo de documento</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="ano-label">Estado</InputLabel> */}
                                            <Select
                                              labelId="ano-label"
                                              id="ano-label"
                                              value={tipoDocumento}
                                              onChange={handleChangeTipoDocumento}
                                              // label="Estado"
                                            >
                                                <MenuItem value="">---</MenuItem>	
                                                <MenuItem value="1">Oficio recebido</MenuItem>
                                                <MenuItem value="2">Oficio expedido</MenuItem>
                                                <MenuItem value="3">Oficio circular expedido</MenuItem>
                                                <MenuItem value="4">Oficio circular recebido</MenuItem>
                                                <MenuItem value="5">Memorando recebido</MenuItem>
                                                <MenuItem value="6">Memorando expedido</MenuItem>
                                                <MenuItem value="7">Memorando circular recebido</MenuItem>
                                                <MenuItem value="8">Memorando circular expedido</MenuItem>
                                                <MenuItem value="9">Convocações emitidas</MenuItem>
                                                <MenuItem value="10">Convocações recebidos</MenuItem>
                                                <MenuItem value="11">Convites</MenuItem>
                                                <MenuItem value="12">Outros</MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Órgão expedidor</span>
                                          <TextField
                                              autoComplete="fdistancia"
                                              name="distancia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Distância entre a Comunidade e a Sede"
                                              value={orgaos}
                                              onChange={handleChangeOrgaos}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                              fullWidth 
                                              margin="normal"
                                              id="date-picker-exepdicao"
                                              label="Data da expedição"
                                              format="dd/MM/yyyy"
                                              value={dataExpedicao}
                                              onChange={handleChangeDataExpedicao}
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                              }}
                                            />
                                          </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                          <span style={{fontSize: 12}}>Número do documento</span>
                                          <TextField
                                              autoComplete="facesso"
                                              placeholder="ex: 0000/21"
                                              name="acesso"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Acesso à Comunidade"
                                              value={numeroDocumento}
                                              onChange={handleChangeNumeroDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <span style={{fontSize: 12}}>Assunto do documento</span>
                                          <TextField
                                              autoComplete="ftempoexistencia"
                                              name="tempoexistencia"
                                              variant="outlined"
                                              required
                                              fullWidth
                                              // label="Tempo de existência da Comunidade"
                                              value={assunto}
                                              onChange={handleChangeAssunto}
                                          />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={3}>
                                        <span style={{fontSize: 12}}>Situação do documento</span>
                                          <FormControl fullWidth variant="outlined">
                                            {/* <InputLabel id="situacao-label">Situação da Comunidade</InputLabel> */}
                                            <Select
                                              labelId="situacao-label"
                                              id="situacao-label"
                                              value={situacaoDocumento}
                                              onChange={handleChangeSituacaoDocumento}
                                              // label="Situação da Comunidade"
                                            >
                                              <MenuItem value={''}>Nenhuma</MenuItem>
                                              <MenuItem value="pendente">Pendente de resolução</MenuItem>   
                                              <MenuItem value="andamento">Resolução em andamento</MenuItem>
                                              <MenuItem value="concluido">Concluído/finalizado</MenuItem> 

                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        {situacaoDocumento === "pendente" && (
                                          <Grid style={{background: '#eaf5f9', margin: 10}} container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                              <span style={{fontSize: 12}}>Prazo para resolução</span>
                                              {/*<TextField
                                                fullWidth
                                                id="dateResolucao"
                                                label="-"
                                                type="date"
                                                value={dataResolucao}
                                                onChange={handleChangeDataResolucao}
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              /> */}
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                  fullWidth
                                                  margin="normal"
                                                  id="date-picker-prazo-resolucao"
                                                  // label="Prazo para resolução"
                                                  format="dd/MM/yyyy"
                                                  value={dataResolucao}
                                                  onChange={handleChangeDataResolucao}
                                                  KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                              <TextField
                                                autoComplete="fpendencia"
                                                name="pendencia"
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Citar pendência"
                                                value={citePendencia}
                                                onChange={handleChangeCitePendencia}
                                              />
                                            </Grid>
                                          </Grid>
                                        )}
                                        {situacaoDocumento === "andamento" && (
                                          <Grid style={{background: '#eaf5f9', margin: 10}} container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                              {/* <span style={{fontSize: 12}}>Prazo para resolução</span>
                                              <TextField
                                                fullWidth
                                                id="dateExpedicao"
                                                label="-"
                                                type="date"
                                                value={dataResolucao}
                                                onChange={handleChangeDataExpedicao}
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              /> */}
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                  fullWidth
                                                  margin="normal"
                                                  id="date-picker-prazo-expedicao"
                                                  label="Prazo para resolução"
                                                  format="dd/MM/yyyy"
                                                  value={dataResolucao}
                                                  onChange={handleChangeDataExpedicao}
                                                  KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                              <TextField
                                                autoComplete="fsitucao"
                                                name="situacao"
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                required
                                                fullWidth
                                                label="Citar situação"
                                                value={citeSitucao}
                                                onChange={handleChangeCiteSitucao}
                                              />
                                            </Grid>
                                          </Grid>
                                        )}
                                        {situacaoDocumento === "concluido" && (
                                          <Grid style={{background: '#eaf5f9', margin: 10}} container spacing={2}>
                                            <Grid item xs={12} sm={12}>
                                              {/* <span style={{fontSize: 12}}>Data da conclusão</span>
                                              <TextField
                                                fullWidth
                                                id="dateSolucao"
                                                label="-"
                                                type="date"
                                                onChange={handleChangeDataConclusao}
                                                // defaultValue="2017-05-24"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                              /> */}
                                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                  fullWidth
                                                  margin="normal"
                                                  id="date-picker-prazo-conclusao"
                                                  label="Prazo para resolução"
                                                  format="dd/MM/yyyy"
                                                  value={dataConclusao}
                                                  onChange={handleChangeDataConclusao}
                                                  KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                  }}
                                                />
                                              </MuiPickersUtilsProvider>
                                            </Grid>
                                          </Grid>
                                        )}
                                        <Grid item xs={12} sm={12}>
                                          <TextField
                                              autoComplete="flocal"
                                              name="observacoes"
                                              multiline
                                              rows={4}
                                              variant="outlined"
                                              required
                                              fullWidth
                                              label="Observações"
                                              value={observacoesDocumento}
                                              onChange={handleChangeObservacoesDocumento}
                                          />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {arquivosDocumento.length != 0 &&(
                                            <div>Arquivos cadastrados: </div>
                                          )}
                                          {arquivosDocumento.map((item, key) => (
                                              <div 
                                                key={key} 
                                                style={{
                                                  display: 'flex', 
                                                  justifyContent: 'flex-start', 
                                                  alignItems: 'center',
                                                  width: '100%',
                                                  padding: 5,
                                                  background: '#f7f7f7',
                                                  marginTop: 10,
                                                  marginBottom: 15
                                                }}
                                              >
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>
                                                <a href={linkApi + '/files/' + item.arquivo_link} target="_blank" title={item.name_file}>{item.name_file}</a>
                                              </div>
                                              <div style={{margin: 10}}>
                                                <Close 
                                                  onClick={() => 
                                                    deletarRegistroFile('licitacao', item.arquivo_link, item.id)
                                                    // removeFile('licitacao', item.name_file)
                                                  }
                                                  style={{
                                                    cursor: 'pointer',
                                                    color: 'red'
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                          {uploadedFiles.length != 0 &&(
                                            <div>Novos arquivos: </div>
                                          )}
                                          {uploadedFiles.map((item, key) => (
                                            <div key={key} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                              <PictureAsPdf />
                                              <div style={{margin: 10}}>{item.name}</div>
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid style={{background: '#f7f7f7', padding: 5}} item xs={12} sm={12}>
                                          <Button style={{margin: 5}} disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                            {txtBtn}
                                          </Button>
                                          <Button disabled={!validForm} onClick={handleOpen} variant="contained" color="secondary" disableElevation>
                                            Enviar arquivos
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </form>
                                    <ToastContainer
                                      position="bottom-left"
                                      autoClose={5000}
                                      hideProgressBar={false}
                                      newestOnTop={false}
                                      closeOnClick
                                      rtl={false}
                                      pauseOnFocusLoss
                                      draggable
                                      pauseOnHover
                                    />
                                  </div>
                              </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                <ModalView 
                  open={open} 
                  handleClose={handleClose}
                  conteudoModal={conteudoModal}
                />


            </main>
            {loading && (
              <div className="loading">
                <CircularProgress />
                <div style={{fontSize: 21}}>Aguarde, estamos enviando seu arquivo ... {process} {'%'}</div>
              </div>
            )}
        </div>
    );
}
