import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../style.css';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';
// import IntlCurrencyInput from 'react-intl-currency-input';
// import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    },
    rootCard: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
}));

const currencyConfig = {
    locale: 'pt-BR',
    formats: {
        number: {
            BRL: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }
        }
    }
};

export default function Index(props) {
    const classes = useStyles();
    var handleProps = props;
    console.log('Props indentificação', handleProps.handleChangeNome);

    const handleChange = (event, value) => {
        event.preventDefault();
        // props.handleChangeValor(value);
        handleProps.handleChangeRenda(value);
        // console.log(value); // value without mask (ex: 1234.56)
        // console.log(maskedValue); // masked value (ex: R$1234,56)
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div className="header-topo">PRECISAMOS DE ALGUMAS INFORMAÇOES SUAS</div>
                        <div className="header-topo">Campos obrigatórios *</div>
                        <br />
                        <br />
                        <div className="content-view">
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        id="outlined-nome"
                                        label="Seu Nome *"
                                        variant="outlined"
                                        onChange={handleProps.handleChangeNome}
                                    />
                                </Grid>
                                {/* <Grid item md={4} xs={12}>
                                    <CpfCnpj
                                        className="customizedInput"
                                        placeholder="Digite um CPF *"
                                        type="tel"
                                        value={handleProps.cpf}
                                        onChange={handleProps.handleChangeCpf}
                                    />
                                </Grid> */}
                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}>
                                        <InputLabel id="sexo-label">Sexo *</InputLabel>
                                        <Select
                                            labelId="sexo-label"
                                            id="sexo"
                                            // value={age}
                                            onChange={handleProps.handleChangeSexo}
                                            label="Sexo *">
                                            <MenuItem value={'Masculino'}>Masculino</MenuItem>
                                            <MenuItem value={'Feminino'}>Feminino</MenuItem>
                                            <MenuItem value={'Outro Gênero'}>Outro Gênero</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}>
                                        <InputLabel id="sexo-label">Zona *</InputLabel>
                                        <Select
                                            labelId="zona-label"
                                            id="zona"
                                            // value={age}
                                            onChange={handleProps.handleChangeZona}
                                            label="Zona *">
                                            <MenuItem value={'Urbana'}>Urbana</MenuItem>
                                            <MenuItem value={'Rural'}>Rural</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        id="outlined-bairro"
                                        label="Seu Bairro *"
                                        variant="outlined"
                                        onChange={handleProps.handleChangeBairro}
                                    />
                                </Grid>
                                <Grid item md={3} xs={12}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}>
                                        <InputLabel id="idade-label">Idade *</InputLabel>
                                        <Select
                                            labelId="idade-label"
                                            id="idade"
                                            // value={age}
                                            onChange={handleProps.handleChangeIdade}
                                            label="Idade *">
                                            <MenuItem value={'16-24'}>De 16 até 24 anos</MenuItem>
                                            <MenuItem value={'25-34'}>De 25 até 34 anos</MenuItem>
                                            <MenuItem value={'35-44'}>De 35 até 44 anos</MenuItem>
                                            <MenuItem value={'45-59'}>De 45 até 59 anos</MenuItem>
                                            <MenuItem value={'60+'}>60 ou mais</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item md={3} xs={12}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}>
                                        <InputLabel id="escolaridade-label">
                                            Escolaridade *
                                        </InputLabel>
                                        <Select
                                            labelId="escolaridade-label"
                                            id="escolaridade"
                                            // value={age}
                                            onChange={handleProps.handleChangeEscolaridade}
                                            label="Escolaridade *">
                                            <MenuItem value={'Sem esolaridade'}>
                                                Sem esolaridade
                                            </MenuItem>
                                            <MenuItem value={'Ensino fundamental completo'}>
                                                Ensino fundamental completo
                                            </MenuItem>
                                            <MenuItem value={'Ensino fundamental incompleto'}>
                                                Ensino fundamental incompleto
                                            </MenuItem>
                                            <MenuItem value={'Ensino médio completo'}>
                                                Ensino médio completo
                                            </MenuItem>
                                            <MenuItem value={'Ensino médio incompleto'}>
                                                Ensino médio incompleto
                                            </MenuItem>
                                            <MenuItem value={'Superior completo'}>
                                                Superior completo
                                            </MenuItem>
                                            <MenuItem value={'Superior incompleto'}>
                                                Superior incompleto
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid> */}
                                <Grid item md={6} xs={12}>
                                    {/* <div style={{ paddingBottom: 10 }}>
                                        Contando com você, quantas pessoas moram na sua casa?
                                    </div> */}
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        className={classes.formControl}>
                                        <InputLabel id="pessoasnacasa-label">Contando com você, quantas pessoas moram na sua casa? *</InputLabel>
                                        <Select
                                            labelId="pessoasnacasa-label"
                                            id="pessoasnacasa"
                                            // value={age}
                                            onChange={handleProps.handleChangePessoasnacasa}
                                            label="Contando com você, quantas pessoas moram na sua casa? *">
                                            <MenuItem value={'01 Pessoa'}>01 Pessoa</MenuItem>
                                            <MenuItem value={'02 Pessoa'}>02 Pessoas</MenuItem>
                                            <MenuItem value={'03 Pessoa'}>03 Pessoas</MenuItem>
                                            <MenuItem value={'04 Pessoa'}>04 Pessoas</MenuItem>
                                            <MenuItem value={'05 Pessoa'}>05 Pessoas</MenuItem>
                                            <MenuItem value={'06 Pessoa'}>06 Pessoas</MenuItem>
                                            <MenuItem value={'07 Pessoa'}>07 Pessoas</MenuItem>
                                            <MenuItem value={'08 Pessoa'}>08 Pessoas</MenuItem>
                                            <MenuItem value={'09 Pessoa'}>09 Pessoas</MenuItem>
                                            <MenuItem value={'10 ou mais'}>10 ou mais</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item md={12} xs={12}>
                                    <div style={{ paddingBottom: 10 }}>
                                        Qual o valor total da renda familiar de sua familia *
                                    </div>
                                    <IntlCurrencyInput
                                        currency="BRL"
                                        style={{
                                            width: '100%',
                                            border: '#afaeae solid 1px',
                                            height: 55,
                                            padding: 10,
                                            fontSize: 18,
                                            borderRadius: 5,
                                            background: '#fff'
                                        }}
                                        config={currencyConfig}
                                        onChange={handleChange}
                                    />
                                </Grid> */}
                            </Grid>
                            <br />
                            <br />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
