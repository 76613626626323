import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import '../style.css';
// import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    },
    rootCard: {
        maxWidth: 345
    },
    media: {
        height: 140
    }
}));

export default function Index() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <div style={{ fontSize: 25 }} className="header-topo">
                            <strong>
                                Plano Plurianual de Pinheiros (PPA) 2022/2025 Consulta Pública
                            </strong>
                        </div>
                        <div style={{ fontSize: 25 }} className="header-topo">
                            <strong>Consulta Pública</strong>
                        </div>

                        <div className="content-view">
                            {/* <p>AUDIÊNCIA PÚBLICA</p> */}
                            <br />
                            <p style={{ color: 'red' }}>
                                <strong>O que é ?</strong>
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                O Plano Plurianual (PPA) o instrumento de Planejamento Governamental
                                de médio prazo, previsto no artigo 165 da Constituição Federal, que
                                estabelece de forma regionalizada, quais serão os objetivos e metas
                                da Administração Pública, Organizado em programas e estruturado em
                                ações, que resultam em bens serviços para a população. Esse plano
                                tem duração De quatro anos, começando no início do segundo ano do
                                mandato do Chefe do Poder Executivo e terminando no fim do primeiro
                                ano de seu sucessor.
                            </p>

                            <p style={{ color: 'red' }}>
                                <strong>Quem faz o PPA?</strong>
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                A Prefeitura Municipal de Pinheiro-MA, através da Secretaria de
                                Administração, com a participação popular por meio de consulta
                                Pública.
                            </p>
                            <p style={{ color: 'red' }}>
                                <strong>Como funciona ? </strong>
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Este ano a consulta será realizada exclusivamente pela internet por
                                conta das medidas sanitárias impostas pelas autoridades de saúde. 0
                                cidadão pinheirense entra no site oficial da Prefeitura de Pinheiro,
                                preenche o questionário onde ele poderá dar sua Sugestão de ação
                                para potencializar desenvolvimento da cidade.
                            </p>
                            <p style={{ color: 'red' }}>
                                <strong>Quem pode participar ? </strong>
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Todos os cidadãos de Pinheiro estão convidados à participar e
                                contribuir com ideias para que as prioridades estejam de acordo com
                                aquilo que a população deseja. De 14 a 25 de julho de 2021, o
                                questionário ficará disponível para que os cidadãos possam fazer
                                suas sugestões nas diversas áreas que envolvem os gastos públicos do
                                município. As propostas serão analisadas e caso tenham viabilidade e
                                sejam compatíveis com o orçamento público serão incluídas no Projeto
                                de Lei do Plano Plurianual- PPA 2022/2025, que posteriormente será
                                enviado para apreciação da Câmara Municipal de Pinheiro-MA.
                            </p>

                            <p style={{ fontStyle: 'italic' }}>
                                Para mais informações ou em caso de dúvidas ligue para Secretaria
                                Municipal de Administração, (98) 98819 0259.
                            </p>
                            <br />
                            <div className="container-video">
                                <iframe
                                    className="responsive-iframe"
                                    src="https://www.youtube.com/embed/peU14SLDEhE"
                                    title="TUDO SOBRE O PPA - Participe!"
                                />
                            </div>
                            {/* <br /> */}
                            {/* <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <Button
                                    variant="contained"
                                    style={{ background: 'green', padding: 10, color: 'white' }}
                                    disableElevation>
                                    Quero participar
                                </Button>
                            </div> */}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
