import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
// import AssignmentIcon from '@material-ui/icons/Assignment';

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

export const mainListItems = (
    <div>
        <ListItemLink href="/">
            <ListItemIcon>
                <DashboardIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Dashboard" />
        </ListItemLink>
        <ListItemLink href="/modelo-um">
            <ListItemIcon>
                <ShoppingCartIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Modelo Um" />
        </ListItemLink>
        <ListItemLink href="/table">
            <ListItemIcon>
                <PeopleIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Tabelas" />
        </ListItemLink>
    </div>
);

export const secondaryListItems = (
    <div>
        <ListSubheader style={{ color: '#ffff' }} inset>
            Saved reports
        </ListSubheader>
        {/* <ListItem button>
            <ListItemIcon>
                <AssignmentIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Current month" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Last quarter" />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <AssignmentIcon style={{ color: '#ffff' }} />
            </ListItemIcon>
            <ListItemText style={{ color: '#ffff' }} primary="Year-end sale" />
        </ListItem> */}
    </div>
);
