import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../Components/Tables/esic';
import api, {linkApi} from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import {format} from 'date-fns';
import Chip from '@material-ui/core/Chip';

export default function Index() {
    const location = useLocation();
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [dataInteracoes, setDataInteracoes] = useState([]);
    const [meuId, setMeuId] = useState('');


    let history = useHistory();
    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verSocilitacao(parseInt(location.state.id))
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    async function verSocilitacao(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: dados} = await api.get(`/ouvidoria/${id}`, config);
        console.log("result edit", dados)
        setMeuId(dados[0].user_id)
        setData(dados)
        // verInterecaoes(parseInt(location.state.id))
        toast.success('🦄 Buscando dados da solicitação!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados da ouvidoria", error, error.response);
        toast.error('🦄 Erro ao buscar dados da solicitação, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // async function verInterecaoes(id){
    //   var token = localStorage.getItem('@tokenTransparencia');
  
    //   var config = {
    //     headers: {Authorization: 'bearer ' + token},
    //   };
    //   console.log("aqui", id)
    //   try {
    //     const {data: dados} = await api.get(`/ouvidoria/${id}`, config);
    //     console.log("Carregar Ouvidoria", dados[0])
    //     setMeuId(dados[0].user.id)
    //     setSolicitacao(dados[0])
    //     setDataInteracoes(dados)
    //   } catch (error) {
    //     console.log("Erro buscar dados da interações", error, error.response);
    //   }
    // }


    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
  
    //table
    function createData(id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado) {
        return { id, protocolo_esic, orgao, recebimento, status, preservado, criado, atualizado };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.protocolo_esic, 
              row.orgao.nome_orgao, 
              row.forma_recebimento_esic, 
              row.status_esic, 
              row.identidade_preservada,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'protocolo', numeric: false, disablePadding: true, label: 'Protocolo' },
        { id: 'orgao', numeric: false, disablePadding: true, label: 'Orgão' },
        { id: 'forma_recebimento_esic', numeric: true, disablePadding: false, label: 'Recebimento' },
        { id: 'status_esic', numeric: true, disablePadding: false, label: 'Status' },
        { id: 'identidade_preservada', numeric: true, disablePadding: false, label: 'Privado' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  // href="/"
                                  onClick={() => {rota('/')}}
                                >
                                    Dashboard
                                </Link>
                                <Link
                                  className="linkBreadcrumbs"
                                  color="inherit"
                                  // href="/esic"
                                  onClick={() => {rota('/portal/ouvidoria')}}
                                >
                                    Ouvidoria
                                </Link>
                                <Typography variant="body2" color="textPrimary">
                                    Solicitação
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Ouvidoria</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                       
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                                <Paper className={'paper'}>
                                  {data.map((item, key) => (
                                    <div key={key}>
                                      <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Sua solicitação. Protocolo <strong>{item.ouvidoria_protocolo}</strong>
                                          </Typography>
                                      </div>
                                      <div className="paperContant">
                                        <div className="dadosSolicitante">
                                          <div className="infoSolicitacao">
                                            Solicitante: <strong>{'  '} {item.user.name}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Orgão: <strong>{'  '} {item.orgao.nome_orgao}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Criado em: <strong>{'  '} { format(new Date(item.created_at), 'dd/MM/yyyy')}</strong>
                                          </div>
                                          {format(new Date(item.created_at), 'dd/MM/yyyy') != format(new Date(item.updated_at), 'dd/MM/yyyy') && (
                                            <div className="infoSolicitacao">
                                              Atualidado em: <strong>{'  '} { format(new Date(item.updated_at), 'dd/MM/yyyy')}</strong>
                                            </div>
                                          )}
                                          <div className="infoSolicitacao">
                                            {item.ouvidoria_status === 'Aberto' &&(
                                              <Chip style={{background: 'blue', color:'white'}} label="Aberto" />
                                            )}
                                            {item.ouvidoria_status === 'Respondido' &&(
                                              <Chip style={{background:'green', color: 'white'}} label="Atendido" />
                                            )}
                                          </div>
                                        </div>

                                        <div className="dadosSolicitante">
                                          <div className="infoSolicitacao">
                                            Tipo: <strong>{'  '} {item.ouvidoria_tipo}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Assunto: <strong>{'  '} {item.ouvidoria_assunto}</strong>
                                          </div>
                                          <div className="infoSolicitacao">
                                            Privado: <strong>{'  '} {item.ouvidoria_privado}</strong>
                                          </div>
                                        </div>

                                        <div className="resposta-solicitacao">
                                          <div className="txt">
                                              <div className="txt-dois">
                                                <strong style={{width: '100%'}}>{item.ouvidoria_tipo}</strong>
                                                <div className="respostas">
                                                  <p>{item.ouvidoria_manifestacao}</p>
                                                  <div className="files">
                                                    <a href={linkApi + '/files/' + item.ouvidoria_file} title={'Arquivo'} target='new'>Arquivo</a>
                                                  </div>
                                                </div>
                                              </div>
                                              {item.ouvidoria_manifestacao_resposta != '' && (
                                                <div className="txt-dois">
                                                  <strong style={{width: '100%'}}>Resposta</strong>
                                                  <div className="respostas">
                                                    <p>{item.ouvidoria_manifestacao_resposta}</p>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            </main>
        </div>
    );
}
