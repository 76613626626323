import React from 'react';
/* eslint-disable */
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles(() => ({
  mainFeaturedPost: {
      marginBottom: 30,
      padding: 0,
      boxShadow: 'none'
  },
  mainFeaturedPostContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      color: 'white',
      textShadow: '1px 1px 2px black'
  }
}));

export default function MainFeaturedPost(props) {
    const classes = useStyles();
    const { post } = props;
    // console.log("post", post)
    return (
        <Paper
            className={classes.mainFeaturedPost}>
            {/* Increase the priority of the hero background image */}
            {/* <img style={{ display: 'none' }} src={post.image} alt={post.imageText} /> */}
            <Grid style={{backgroundImage: `url(${post.image})`, minHeight: 150}} container>
                <Grid item md={12}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography component="h2" variant="h4" color="inherit" gutterBottom>
                            {post.title}
                        </Typography>
                        {post.description && (
                          <Typography style={{textAlign: 'center', padding: 10}} variant="h5" color="inherit" paragraph>
                              {post.description}
                          </Typography>
                        )}
                        
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
}

MainFeaturedPost.propTypes = {
    post: PropTypes.object
};
