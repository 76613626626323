import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../../Components/Tables/licitacao';
import api from '../../../services/api';
import {veryAuth} from '../../../auth';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');

    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        licitacoes();
        // lici();
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function licitacoes() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('licitacao', config);
            // console.log('Listar todas as licitações', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao ao listar ouvidoria', error, error.response);
        }
    }

    async function lici() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('files-licitacao', config);
            for (let l = 0; l < result.length; l++) {
              const element = result[l];
              var idfile = element.id;
              var newUrl = element.arquivo_esic_link;

              const linkReplace = newUrl.replace('/arquivos-pdf/', 'licitacao/') 
              console.log('Listar dados de arquivos', idfile, linkReplace);
              editarFileLicitacao(idfile, linkReplace)
              // editarLicitacao(element)
            }
            // setData(result);
        } catch (error) {
            console.log('Erro ao ao listar files', error, error.response);
        }
    }

    async function editarLicitacao(v){
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("orgao_id", '');
      form_data.append("licitacao_situacao", v.licitacao_situacao);
      form_data.append("licitacao_numero_documento", v.licitacao_numero_documento);
      form_data.append("licitacao_ano", v.licitacao_ano);
      form_data.append("licitacao_modalidade", v.licitacao_modalidade);
      form_data.append("licitacao_tipo", v.licitacao_tipo);
      form_data.append("licitacao_data_abertura", v.licitacao_data_abertura ? v.licitacao_data_abertura : '0000-00-00');
      form_data.append("licitacao_data_fechamento", v.licitacao_data_fechamento ? v.licitacao_data_fechamento : '0000-00-00');
      form_data.append("licitacao_local", v.licitacao_local);
      form_data.append("licitacao_valor", v.licitacao_valor);
      form_data.append("licitacao_descricao", v.licitacao_descricao);

      await api
          .post(`/licitacao/${parseInt(v.id)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Licitacao editado", res.data)
              // editar(res.data.id)
              // if(uploadedFiles.length === 0){
              //   editar(res.data.id)
              //   setLoading(false);
              //   toast.success('🦄 Licitação editada com sucesso!', {
              //     position: "bottom-left",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
                
              // }else{
              //   enviarFiles(res.data.id, 'edit')
              // }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
          
          });
    }
    async function editarFileLicitacao(vid, newlink){
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("arquivo_esic_link", newlink);

      await api
          .post(`/files-licitacao/${parseInt(vid)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("File Licitacao editado", res.data)
              // editar(res.data.id)
              // if(uploadedFiles.length === 0){
              //   editar(res.data.id)
              //   setLoading(false);
              //   toast.success('🦄 Licitação editada com sucesso!', {
              //     position: "bottom-left",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
                
              // }else{
              //   enviarFiles(res.data.id, 'edit')
              // }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
          
          });
    }
    async function searchSearch(event) {
      var termo = encodeURI(event.target.value)
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`/search-licitacao/${termo}`, config);
            console.log('Resultado da pesquisa de licitação', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(
      id, 
      licitacao_situacao, 
      licitacao_numero_documento, 
      licitacao_ano, 
      licitacao_modalidade, 
      licitacao_tipo, 
      licitacao_data_abertura, 
      licitacao_data_fechamento,
      licitacao_local,
      licitacao_valor,
      licitacao_descricao,
      criado, 
      atualizado
    ) {
        return { 
          id, 
          licitacao_situacao, 
          licitacao_numero_documento, 
          licitacao_ano, 
          licitacao_modalidade, 
          licitacao_tipo, 
          licitacao_data_abertura, 
          licitacao_data_fechamento,
          licitacao_local,
          licitacao_valor,
          licitacao_descricao,
          criado, 
          atualizado 
        };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.licitacao_situacao, 
              row.licitacao_numero_documento, 
              row.licitacao_ano, 
              row.licitacao_modalidade, 
              row.licitacao_tipo,
              row.licitacao_data_abertura,
              row.licitacao_data_fechamento,
              row.licitacao_local,
              row.licitacao_valor,
              row.licitacao_descricao,
              row.created_at,
              row.updated_at
            );
        });
    }

    const columns = [
        { id: 'doc', numeric: false, disablePadding: true, label: 'Licitação' },
        { id: 'modalidade', numeric: false, disablePadding: true, label: 'Modalidade' },
        { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
        { id: 'local', numeric: false, disablePadding: true, label: 'Local' },
        { id: 'licitacao_situacao', numeric: false, disablePadding: true, label: 'Situação' },
        { id: 'valor', numeric: true, disablePadding: false, label: 'Valor' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Data' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Licitação
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Licitação</h2>
                                <Button 
                                  className="button-add" 
                                  variant="contained" 
                                  color="primary"
                                  onClick={() => { 
                                    history.push({
                                        pathname: '/portal/admin/licitacao/crud',
                                        // search: '?query=abc',
                                        state: { type: 'new' }
                                    });
                                  }}
                                  >
                                    Novo
                                </Button>
                            </div>
                        </Grid>
                       
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <div className="paperHeader">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Filtrar por protocolo
                                    </Typography>
                                </div>
                                <TextField
                                    className="textField"
                                    label="Digite o protocolo da solicitação!"
                                    variant="outlined"
                                    type="text"
                                    onChange={searchSearch}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Suas solicitações
                                          </Typography>
                                      </div>
                                      <div className="paperContant">
                                        <Tables columns={columns} rows={rows} roles={roles} />
                                      </div>
                                  </Paper>
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
