import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Breadcrumbs } from '@material-ui/core';
// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'Perguntas e Respostas',
    description:"Listamos aqui as principais perguntas e respostas",
    image: 'https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png',
};

export default function Blog() {
    let history = useHistory();
    // const classes = useStyles();

    function rota(rota){
      history.push(rota);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <span color="textPrimary">Perguntas e Respostas</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                        {/* {featuredPosts.map((post) => (
                            <FeaturedPost key={post.title} post={post} />
                        ))} */}
                        <Grid item xs={12} md={12} lg={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>O que é a LC nº131/2009?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            A Lei Complementar 131, de 27 de maio de 2009, alterou a redação da Lei de Responsabilidade Fiscal no que se refere à transparência da gestão fiscal. A Lei inovou ao determinar a disponibilização, em tempo real, de informações pormenorizadas sobre a execução orçamentária e financeira da União, dos Estados, do Distrito Federal e dos Municípios, em meios eletrônicos de acesso público.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header1"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>Quais os dados que devem ser divulgados na internet?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            Conforme determinado pela LC nº 131/2009, todos os entes deverão divulgar: - Quanto à despesa: todos os atos praticados pelas unidades gestoras no decorrer da execução da des pesa, no momento de sua realização, com a disponibilização mínima dos dados referentes ao número do correspondente processo, ao bem fornecido ou ao serviço prestado, à pessoa física ou jurídica beneficiária do pagamento e, quando for o caso, ao procedimento licitatório realizado;
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header2"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>Qual legislação regulamenta a Lei Complementar nº 131/2009?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            Em consonância com o disposto pela Lei Complementar nº 131/2009, foi editado o Decreto nº 7.185, de 27 de maio de 2010 (http://www.planalto.gov.br/ccivil_03/_Ato2007-2010/2010/Decreto/D7185.htm), que define o padrão mínimo de qualidade do sistema integrado de administração financeira e controle, nos termos do inciso III, parágrafo único, do art. 48 da Lei de Responsabilidade Fiscal. A Secretaria do Tesouro Nacional/Ministério da Fazenda também editou a Portaria nº 548, de 22 de novembro de 2010 (http://www.tesouro.fazenda.gov.br/legislacao/download/contabilidade/PorMF_548_2010. pdf), que estabelece os requisitos mínimos de segurança e contábeis do sistema integrado de administração financeira e controle utilizado no âmbito de cada ente da Federação. São requisitos adicionais aos previstos no Decreto nº 7.185, de 27 de maio de 2010.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header3"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>Além do Governo Federal, todos os Estados e Municípios são obrigados a desenvolver Portal da Transparência?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            Conforme definido pela LC nº 131/2009, todos os entes possuem obrigação de liberar ao pleno conheci- mento e acompanhamento da sociedade, em tempo real, informações pormenorizadas sobre a execução 25 26 orçamentária e financeira, em meios eletrônicos de acesso público. Essas informações precisam estar disponíveis na internet, não necessariamente em um Portal da Transparência. Contudo, considerando as boas práticas, é desejável concentrar as informações em um só local
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header4"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>Quais os prazos para o cumprimento da LC nº 131/2009?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            A LC nº 131/2009 definiu os seguintes prazos, a contar da data de sua publicação (28/05/2009): I – 1 (um) ano para a União, os Estados, o Distrito Federal e os Municípios com mais de 100.000 (cem mil) habitantes – maio de 2010; II – 2 (dois) anos para os Municípios que tenham entre 50.000 (cinquenta mil) e 100.000 (cem mil) habi- tantes – maio de 2011; III – 4 (quatro) anos para os Municípios que tenham até 50.000 (cinquenta mil) habitantes – maio de 2013.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header5"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>O que é considerado “tempo real”, para fins da LC nº 131/2009?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            Conforme definido pelo Decreto nº 7.185/2010, a liberação em tempo real se refere à disponibilização das informações, em meio eletrônico que possibilite amplo acesso público, até o primeiro dia útil subsequente à data do registro contábil no respectivo sistema, sem prejuízo do desempenho e da preservação das rotinas de segurança operacional necessários ao seu pleno funcionamento.
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header6"
                            style={{background: '#f7f7f7f7'}}
                          >
                            <Typography>Quais as penalidades para Estados e Municípios que não cumprirem a Lei Complementar nº 131/2009?</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                            Segundo a LC nº 131/2009, o ente que não disponibilizar as informações no prazo estabelecido ficará im- pedido de receber transferências voluntárias. Além disso, os titulares do Poder Executivo dos entes estarão sujeitos a responder por crime de responsabilidade (Art. 10, Itens 4 e 12, Lei nº 1.079, de 10 de abril de 1950 e Art. 1º, Incisos VII e XXIII, Decreto-Lei nº 201, de 27 de fevereiro de 1967, respectivamente).
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        </Grid>
                    </Grid>
                </main>
            </Container>
            <Footer />
        </React.Fragment>
    );
}
