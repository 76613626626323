import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import Icon from '@material-ui/core/Icon';
import { useHistory } from 'react-router-dom';

export default function Index() {
  let history = useHistory();
  const [skeleton, seSkeleton] = useState(true);
  const [montarTela, setMontarTela] = useState(false);
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState('');
  const [nomeLogado, setNomeLogado] = useState('');


    useEffect(() => {
        verificarPermissao()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);
    function verificarPermissao(){
      setLoading(true)
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        setNomeLogado(result.data.nome)
        setLoading(false)
        if(result.data.role === 'usuario'){
          setMontarTela(true)
        }else{
          history.push('/');
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    return (
        <div className={'root'}>
            <CssBaseline />
            {montarTela && (
              <>
                <Header />
                <main className="content">
                    <div className="appBarSpacer" />
                    <Container maxWidth="lg" className="container">
                        <Grid container spacing={3}>
                            {/* Chart */}
                            <Grid item xs={12} md={12} lg={12}>
                                <div className="headerPage">
                                    {skeleton && (
                                        <Skeleton className={'skelTitlePage'} variant="text" />
                                    )}
                                    {!skeleton && <h2>Bem Vindo (a), {nomeLogado}</h2>}
                                </div>
                            </Grid>
    
           
                            <Grid item xs={12} md={3} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper onClick={() => {history.push('/portal/esic')}} className={'paperDashboard'}>
                                        <Icon className={'fa fa-info'} style={{fontSize: 40, width: 'auto', margin: 10}} />
                                        <div style={{fontSize: 21}}>e-Sic</div>
                                    </Paper>
                                )}
                            </Grid>
                            <Grid item xs={12} md={3} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper onClick={() => {history.push('/portal/ouvidoria')}} className={'paperDashboard'}>
                                        <Icon className={'fa fa-assistive-listening-systems'} style={{fontSize: 40, width: 'auto', margin: 10}} />
                                        <div style={{fontSize: 21}}>Ouvidoria</div>
                                    </Paper>
                                )}
                            </Grid>
                            {/* Recent Deposits */}
                            {/* <Grid item xs={12} md={4} lg={3}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper className={'paper'}>
                                        <div className="paperHeader">
                                            <Typography variant="subtitle1" gutterBottom>
                                                Title Paper
                                            </Typography>
                                        </div>
                                        <div className="paperContant">{'Content'}</div>
                                    </Paper>
                                )}
                            </Grid> */}
                            {/* Recent Orders */}
                            {/* <Grid item xs={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper className={'paper'}>
                                        <div className="paperHeader">
                                            <Typography variant="subtitle1" gutterBottom>
                                                Title Paper
                                            </Typography>
                                        </div>
                                        <div className="paperContant">{'Content'}</div>
                                    </Paper>
                                )}
                            </Grid> */}
                        </Grid>
                        <Box pt={4}>
                            <Footer />
                        </Box>
                    </Container>
                </main>
              </>
            )}
        </div>
    );
}
