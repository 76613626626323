import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import api, {linkApi} from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import TableLicitacao from '../../Components/Tables/licitacaoSite';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {format, parseISO} from 'date-fns';

// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'Licitações',
    // description:
    //     "Sistema eletrônico de informação ao cidadão.",
};

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openResetSenha, setOpenResetSenha] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [data, setData] = useState([]);
    const [roles, setRoles] = useState('');
    const [documento, setDocumento] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [modalidade, setModalidade] = useState('');
    const [tipo, setTipo] = useState('');
    const [dataAbertura, setDataAbertura] = useState(new Date());
    const [dataFechamento, setDataFechamento] = useState(new Date());
    const [local, setLocal] = useState('');
    const [valor, setValor] = useState(0);
    const [situacao, setSituacao] = useState('');
    const [objeto, setObjeto] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nomeFile, setNomeFile] = useState('');
    const [arquivosLicitacao, setArquivosLicitacao] = useState([]);
    const [avisoFile, setAvisoFile] = useState(false);
    const [orgaos, setOrgaos] = useState([]);
    const [orgao, setOrgao] = useState('');
    const [publicacao, setPublicacao] = useState(new Date());

    let history = useHistory();
    let location = useLocation();
    // const classes = useStyles();

    useEffect(() => {
      editar(location.state.id)
      //
    }, []);

    async function editar(id){
      var token = localStorage.getItem('@tokenTransparencia');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      console.log("aqui", id)
      try {
        const {data: resultEdit} = await api.get(`/licitacao/${id}`, config);
        console.log("edit aqui", resultEdit)
        var edit = resultEdit[0];
        setLoading(false);
        if(edit.orgao){
          setOrgao(edit.orgao.nome_orgao);
        }
        setDocumento(edit.licitacao_numero_documento);
        setAno(edit.licitacao_ano);
        setModalidade(edit.licitacao_modalidade);
        setTipo(edit.licitacao_tipo)
        setDataAbertura(edit.licitacao_data_abertura);
        setDataFechamento(edit.licitacao_data_fechamento);
        setLocal(edit.licitacao_local);
        setValor(edit.licitacao_valor);
        setSituacao(edit.licitacao_situacao);
        setObjeto(edit.licitacao_descricao);
        setArquivosLicitacao(edit.fileLicitacao)
        setPublicacao(edit.created_at)
      
        toast.info('🦄 Carregando dados!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Erro buscar dados", error, error.response);
        toast.error('🦄 Erro ao buscar dados, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  
    function rota(rota){
      history.push(rota);
    }

    function formatDate(date){
      var dataInput = date;
      var data = new Date(dataInput);
      var dataFormatada = data.toLocaleDateString('pt-BR', {timeZone: 'UTC'});
      return dataFormatada  
    }
 
    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
                <main>
                    {/* <MainFeaturedPost post={mainFeaturedPost} /> */}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="paperConteudo">
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={12}>
                                <div>
                                  <Button
                                    style={{background: 'green', color: 'white'}}
                                    onClick={() => {history.goBack()}}
                                  >
                                    Voltar
                                  </Button>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Licitação: </p>
                                  <span>
                                    {documento}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Orgão: </p>
                                  <span>
                                    {orgao ? orgao : 'Não Definido'}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Ano: </p>
                                  <span>
                                    {ano}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Data de publicação</p>
                                  <span>
                                    {/* {format(new Date(publicacao), 'dd/MM/yyyy')} */}
                                    {formatDate(publicacao)}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Data de abertura / Data Fechamento </p>
                                  <span>
                                    {/* {dataAbertura != '0000-00-00' ? format(new Date(dataAbertura), 'dd/MM/yyyy') : ' - '} {' '} {dataFechamento ? format(new Date(dataFechamento), 'dd/MM/yyyy') : ' - Não definida '} */}
                                    {
                                    dataAbertura != '0000-00-00' ? 
                                    formatDate(dataAbertura) : 
                                    ' - '
                                    } 
                                    {' '} 
                                    {dataFechamento ? 
                                    formatDate(dataFechamento) : 
                                    ' - Não definida '}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Local: </p>
                                  <span>
                                    {local}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Modalidade: </p>
                                  <span>
                                    {modalidade}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Tipo: </p>
                                  <span>
                                    {tipo}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Situação: </p>
                                  <span>
                                    {situacao}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Objeto: </p>
                                  <span>
                                    {objeto}
                                  </span>
                                </div>
                              </Grid>
                              <Grid style={{paddingTop: 0, paddingRight: 10, paddingBottom: 6, paddingLeft: 10}} item xs={12} md={12}>
                                <div className="viewItem">
                                  <p style={{color: '#02315a'}}>Editais: </p>
                                  {arquivosLicitacao.map((item, key) => (
                                    <div key={key} className="files">
                                      <a href={linkApi + '/files/' + item.arquivo_esic_link} title={item.name_file} target={'_blank'}>
                                        <p>{item.name_file}</p>
                                      </a>
                                      <div>
                                        <a href={linkApi + '/files/' + item.arquivo_esic_link} title={item.name_file} target={'_blank'}>
                                          <PictureAsPdf />
                                        </a>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <div>
                                  <Button
                                    style={{background: 'green', color: 'white'}}
                                    onClick={() => {history.goBack()}}
                                  >
                                    Voltar
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                </main>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
