import React from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/global.css';
/* eslint-disable */
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/api';
import {format, subDays, addDays, isAfter, parseISO} from 'date-fns';
import Visibility from '@material-ui/icons/Visibility';
import Refresh from '@material-ui/icons/Refresh';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    container: {
        overflow: 'none'
    }
});

export default function Index(props) {
    console.log('Propos orgaos', props);
    const classes = useStyles();
    let history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const columns = props.columns

    const rows = props.rows

    const roles = props.roles

    function verFatura(){
      alert("Em desenvolvimento")
    }
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    async function deletar(id) {
      
      // setloading(true)
      var token = localStorage.getItem('@tokenGerenciador');
  
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data: prod} = await api.delete(`/fatura/${id}`, config);
        console.log("prod", prod)
        // limpaCampos();
        // setloading(false);
        toast.success('🦄 Fatura deletada com sucesso!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // props.deletando()
      } catch (error) {
        console.log("Erro ao deletar", error, error.response);
        // setloading(false);
        toast.error('🦄 Erro ao buscar dados para editar, volte e tente novamente!', {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    function moedaBR(amount, decimalCount = 2, decimal = ",", thousands = "."){
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
  
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    }

    function verLicitacao(id){
      history.push({
          pathname: '/licitacao',
          // search: '?query=abc',
          state: { id: id }
      });
    }

    return (
        <Paper className={'paper'}>
          <div className="tabelaNova">
            <div className="itemTabelaHeade">
              {columns.map((item, key) => (
                //align={key === 3 || key >= 4 ? 'right' : 'left'}
                <div key={key} style={{width: key == 0 ? 250 : 100, textAlign: 'left'}} className="it">
                  {item.label}
                </div>
              ))}
            </div>
            {rows.map((row, key) => (
              <div key={key} className="tab">
                <div className="itemTabela">
                  <div style={{width: 250}} className="it">
                    <Tooltip onClick={() => {verLicitacao(row.id)}} title={row.licitacao_numero_documento} arrow>
                      <div>
                        {row.licitacao_numero_documento}
                      </div>
                    </Tooltip>
                  </div>
                  <div className="it">
                    {row.licitacao_modalidade}
                  </div>
                  <div className="it">
                    {row.licitacao_tipo}
                  </div>
                  {/* <div className="it">
                    {row.licitacao_local}
                  </div> */}
                  <div style={{width: 100}} className="it">
                    {row.licitacao_situacao}
                  </div>
                  <div style={{width: 100}} className="it">
                    {moedaBR(row.licitacao_valor)}
                  </div>
                  <div style={{width: 100}} className="it">
                    {row.licitacao_data_abertura}
                    {/* {row.licitacao_data_abertura !== '0000-00-00' ? format(new Date(row.licitacao_data_abertura), 'dd/MM/yyyy') : '-'} */}
                  </div>
                  <div style={{width: 100}} className="it">
                    <Tooltip title="Abrir licitação" aria-label="add">
                        <div style={{width: 30, height: 30, background: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff', borderRadius: 8}} onClick={() => {verLicitacao(row.id)}}>
                          Ver
                          {/* <Icon className={'fa fa-eye'} style={{ color: '#073e84', fontSize: 20, margin: 5, width: 25, cursor: 'pointer' }} /> */}
                        </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="objeto">
                    <p style={{fontWeight: 'bold'}}>Objeto</p>
                    <p>{row.licitacao_descricao}</p>
                  </div>
              </div>
            ))}
          </div>
            {/* <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, key) => (
                                <TableCell
                                    key={column.id}
                                    align={key === 3 || key >= 4 ? 'right' : 'left'}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow
                                        className="tableRow"
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}>
                                          <TableCell>{row.licitacao_numero_documento}</TableCell>
                                          <TableCell align="left">{row.licitacao_modalidade}</TableCell>
                                          <TableCell align="left">{row.licitacao_tipo}</TableCell>
                                          <TableCell align="right">{row.licitacao_local}</TableCell>
                                          <TableCell align="right">{row.licitacao_situacao}</TableCell>
                                          <TableCell align="right">{moedaBR(row.licitacao_valor)}</TableCell>
                                          <TableCell align="right">
                                            <div>Criado: { format(new Date(row.criado), 'dd/MM/yyyy')} </div>
                                            {format(new Date(row.criado), 'dd/MM/yyyy') != format(new Date(row.atualizado), 'dd/MM/yyyy') && (
                                              <div>Atualizado: { format(new Date(row.atualizado), 'dd/MM/yyyy')} </div>
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            <Visibility className="btnView" onClick={() => verLicitacao(row.id)} />
                                          </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={'Por página'}
            />
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            /> */}
        </Paper>
    );
}
