/* eslint-disable */
import React from "react"
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const BrlCurrencyComponent = (props) => {
  console.log(" props ", props)
  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();
    props.handleChangeValor(value)
    console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  return(
    <IntlCurrencyInput 
      currency="BRL" 
      style={{
        width: '100%',
        border: '#afaeae solid 1px',
        height: 55,
        padding: 10,
        fontSize: 18,
        borderRadius: 5,
        background: '#fff',
      }}
      config={currencyConfig}
      value={props.value}
      onChange={handleChange} 
    />
  );
}

export default BrlCurrencyComponent;
