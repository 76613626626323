import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Tables from '../../../Components/Tables/documentos';
import api from '../../../services/api';
import {veryAuth} from '../../../auth';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  list: {
    width: 350,
  },
  fullList: {
    width: 'auto',
  },
});
export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [dataPendente, setDataPendente] = useState([]);
    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');
    const classes = useStyles();

    const [state, setState] = useState(false);

    const [open, setOpen] = useState(true);

    const toggleDrawer = (anchor, open) => (event) => {
      console.log("event.type", event.type)
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState(false)
      // setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{padding: 10}}>Precisando de andamento</div>
        {dataPendente.map((item, key) => (
          <List key={key}>
            <ListItem onClick={() => verDocumento(item.id)} button>
              <ListItemIcon>{key + 1}</ListItemIcon>
              <ListItemText primary={item.documento_assunto + ' - ' + item.documento_numero} />
            </ListItem>
          </List>
        ))}
      </div>
    );

    let history = useHistory();
    useEffect(() => {
        verificarPermissao()
        documentos();
        documentosData();
        // lici();
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    async function documentos() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('documento', config);
            console.log('Listar todos os documentos', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao ao listar ouvidoria', error, error.response);
        }
    }

    async function documentosData() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`documentos-data/${encodeURI(new Date())}`, config);
            console.log('Listar todos os documentos precisando de andamento', result);
            setDataPendente(result);
        } catch (error) {
            console.log('Erro ao ao listar documentos precisando de andamento', error, error.response);
        }
    }

    async function lici() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('files-licitacao', config);
            for (let l = 0; l < result.length; l++) {
              const element = result[l];
              var idfile = element.id;
              var newUrl = element.arquivo_esic_link;

              const linkReplace = newUrl.replace('/arquivos-pdf/', 'licitacao/') 
              console.log('Listar dados de arquivos', idfile, linkReplace);
              editarFileLicitacao(idfile, linkReplace)
              // editarLicitacao(element)
            }
            // setData(result);
        } catch (error) {
            console.log('Erro ao ao listar files', error, error.response);
        }
    }

    async function editarLicitacao(v){
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("orgao_id", '');
      form_data.append("licitacao_situacao", v.licitacao_situacao);
      form_data.append("licitacao_numero_documento", v.licitacao_numero_documento);
      form_data.append("licitacao_ano", v.licitacao_ano);
      form_data.append("licitacao_modalidade", v.licitacao_modalidade);
      form_data.append("licitacao_tipo", v.licitacao_tipo);
      form_data.append("licitacao_data_abertura", v.licitacao_data_abertura ? v.licitacao_data_abertura : '0000-00-00');
      form_data.append("licitacao_data_fechamento", v.licitacao_data_fechamento ? v.licitacao_data_fechamento : '0000-00-00');
      form_data.append("licitacao_local", v.licitacao_local);
      form_data.append("licitacao_valor", v.licitacao_valor);
      form_data.append("licitacao_descricao", v.licitacao_descricao);

      await api
          .post(`/licitacao/${parseInt(v.id)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Licitacao editado", res.data)
              // editar(res.data.id)
              // if(uploadedFiles.length === 0){
              //   editar(res.data.id)
              //   setLoading(false);
              //   toast.success('🦄 Licitação editada com sucesso!', {
              //     position: "bottom-left",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
                
              // }else{
              //   enviarFiles(res.data.id, 'edit')
              // }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
          
          });
    }
    async function editarFileLicitacao(vid, newlink){
      //setloading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
  
      form_data.append("arquivo_esic_link", newlink);

      await api
          .post(`/files-licitacao/${parseInt(vid)}`, form_data, {
            headers: {
              "content-type": "application/json",
              Authorization: "bearer " + token,
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("File Licitacao editado", res.data)
              // editar(res.data.id)
              // if(uploadedFiles.length === 0){
              //   editar(res.data.id)
              //   setLoading(false);
              //   toast.success('🦄 Licitação editada com sucesso!', {
              //     position: "bottom-left",
              //     autoClose: 5000,
              //     hideProgressBar: false,
              //     closeOnClick: true,
              //     pauseOnHover: true,
              //     draggable: true,
              //     progress: undefined,
              //   });
                
              // }else{
              //   enviarFiles(res.data.id, 'edit')
              // }
          })
          .catch(error => {
            console.log("error editar licitação: ", error, error.response)
          
          });
    }
    async function searchSearch(event) {
      var termo = encodeURI(event.target.value)
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`/search-licitacao/${termo}`, config);
            console.log('Resultado da pesquisa de licitação', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    function verDocumento(id){
      history.push({
          pathname: '/portal/admin/documento/crud',
          // search: '?query=abc',
          state: { type: 'edit', id: id }
      });
    }
    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(
      id, 
      documento_tipo, 
      documento_orgao, 
      documento_data_expedicao, 
      documento_numero, 
      documento_assunto, 
      documento_situacao, 
      created_at,
      updated_at
    ) {
        return { 
          id, 
          documento_tipo, 
          documento_orgao, 
          documento_data_expedicao, 
          documento_numero, 
          documento_assunto, 
          documento_situacao, 
          created_at,
          updated_at
        };
    }

    var rows = [];
    if (!data) {
        rows = [createData('carregando')];
    }
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.documento_tipo, 
              row.documento_orgao, 
              row.documento_data_expedicao, 
              row.documento_numero, 
              row.documento_assunto,
              row.documento_situacao,
              row.created_at,
              row.updated_at,
            );
        });
    }

    const columns = [
        { id: 'doc', numeric: false, disablePadding: true, label: 'Tipo de Documento	' },
        { id: 'modalidade', numeric: false, disablePadding: true, label: 'Orgão Expedidor	' },
        { id: 'tipo', numeric: false, disablePadding: true, label: 'Data de Expedição	' },
        { id: 'local', numeric: false, disablePadding: true, label: 'Número Documento	' },
        { id: 'licitacao_situacao', numeric: false, disablePadding: true, label: 'Assunto Documento	' },
        { id: 'valor', numeric: true, disablePadding: false, label: 'Situção Documento	' },
        { id: 'criado', numeric: true, disablePadding: false, label: 'Criado em	' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];
    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Documentos
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Documentos</h2>
                                <Button 
                                  className="button-add" 
                                  variant="contained" 
                                  color="primary"
                                  onClick={() => { 
                                    history.push({
                                        pathname: '/portal/admin/documento/crud',
                                        // search: '?query=abc',
                                        state: { type: 'new' }
                                    });
                                  }}
                                  >
                                    Novo
                                </Button>
                            </div>
                        </Grid>
{/*                        
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <div className="paperHeader">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Filtrar por protocolo
                                    </Typography>
                                </div>
                                <TextField
                                    className="textField"
                                    label="Digite o protocolo da solicitação!"
                                    variant="outlined"
                                    type="text"
                                    onChange={searchSearch}
                                />
                            </Paper>
                        </Grid> */}
                        {dataPendente.length != 0 && (
                          <Grid item xs={12} md={12} lg={12}>
                            <div className={classes.root}>
                              <Collapse in={open}>
                                <Alert
                                  severity="warning"
                                  action={
                                    <>
                                      <Button 
                                        onClick={() => {
                                          setState(true)
                                        }}
                                        style={{marginRight: 10}} 
                                        variant="contained" 
                                        color="secondary"
                                      >
                                      VER DOCUMENTOS
                                      </Button>
                                      <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                          setOpen(false);
                                        }}
                                      >
                                        <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                    </>
                                  }
                                >
                                  VOCÊ TEM ({dataPendente.length}) DOCUMENTO(S) PRECISANDO ANDAMENTO
                                </Alert>
                              </Collapse>
                            </div>
                          </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContant">
                                        <Tables columns={columns} rows={rows} roles={roles} />
                                      </div>
                                  </Paper>
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                    <Drawer anchor={'right'} open={state} onClose={toggleDrawer('right', false)}>
                      {list('right')}
                    </Drawer>
                </Container>
                {/* {['left', 'right', 'top', 'bottom'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))} */}
            </main>
            <div>
    </div>
        </div>
    );
}
