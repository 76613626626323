import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import Chart from "react-google-charts";

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { Breadcrumbs } from '@material-ui/core';
// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'e-Sic',
    description:
        "Sistema eletrônico de informação ao cidadão.",
    image: "https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png",
    imgText: 'main image description',
};

const featuredPosts = [
    {
        title: 'Portal da Transparência',
        description:
            'Acesso ao sistema externo do portal da transparência do município.',
    },
    {
        title: 'e-Sic',
        description:
            'Através deste formulário, pessoas físicas ou jurídicas podem encaminhar pedidos de acesso à informação.',
    }
];

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ano, setAno] = useState(2023);
    const [dadosSelect, setDadosSelect] = useState([]);
    let history = useHistory();
    // const classes = useStyles();

    useEffect(() => {
      setDadosSelect(totalPedidos.sete)
      //   setTimeout(function () {
      //       // history.push('/portal/esic')
      //   }, 6000);
      // }
    }, []);

    function rota(rota){
      history.push(rota);
    }

    const totalPedidos = { 
        'um':{
          'total': 38, 
          'atendido': 35, 
          'indeferido': 3, 
          'homem': 25, 
          'mulher': 10, 
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Ouvidoria', 8],
            ['Cultura', 16],
            ['Procuradoria', 5],
            ['Saúde', 10],
            ['Comunicação',7],
          ]
        },
        'dois':{
          'total': 28, 
          'atendido': 17, 
          'indeferido': 1, 
          'homem': 16, 
          'mulher': 2, 
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Direitos Humanos', 3],
            ['Juventude', 2],
            ['Procuradoria', 7],
            ['Saúde', 10],
            ['Infraestrutura', 6],
          ]
        },
        'tres':{
          'total': 24, 
          'atendido': 20, 
          'indeferido': 4, 
          'homem': 18, 
          'mulher': 6, 
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Meio Ambiente', 5],
            ['Esporte', 3],
            ['Ouvidoria', 6],
            ['Saúde', 3],
            ['Esporte',7],
          ]
        },
        'quatro':{
          'total': 25, 
          'atendido': 23, 
          'indeferido': 2, 
          'homem': 11, 
          'mulher': 14,
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Educação', 5],
            ['Esporte', 3],
            ['Procuradoria', ],
            ['Saúde', 3],
            ['Comunicação', 4],
          ]
        },
        'cinco':{
          'total': 6,
          'atendido': 5, 
          'indeferido': 1, 
          'homem': 5, 
          'mulher': 2,
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Educação', 2],
            ['Procuradoria', 2],
            ['Saúde', 1],
            ['Comunicação', 1],
          ]
        } ,
        'seis':{
          'total': 23,
          'atendido': 19, 
          'indeferido': 4, 
          'homem': 14, 
          'mulher': 9,
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Direitos Humanos', 4],
            ['Juventude', 3],
            ['Procuradoria', 6],
            ['Saúde', 4],
            ['Infraestrutura', 6],
          ]
        } ,
        'sete':{
          'total': 31,
          'atendido': 26, 
          'indeferido': 5, 
          'homem': 15, 
          'mulher': 16,
          'orgao':[
            ['Task', 'Por Orgão'],
            ['Educação', 11],
            ['Esporte', 5],
            ['Procuradoria', 1],
            ['Saúde', 9],
            ['Comunicação', 5],
          ]
        } ,
    }

    console.log("totalPedidos", totalPedidos)

    function handleChangeAno(event) {
      setAno(event.target.value);
      if(event.target.value === 2017){
        setDadosSelect(totalPedidos.um)
      }else if(event.target.value === 2018){
        setDadosSelect(totalPedidos.dois)
      }else if(event.target.value === 2019){
        setDadosSelect(totalPedidos.tres)
      }else if(event.target.value === 2020){
        setDadosSelect(totalPedidos.quatro)
      }else if(event.target.value === 2021){
        setDadosSelect(totalPedidos.cinco)
      }else if(event.target.value === 2022){
        setDadosSelect(totalPedidos.seis)
      }else if(event.target.value === 2023){
        setDadosSelect(totalPedidos.sete)
      }
    }
    function handleChangeCpfCnpj(event) {
        setCpfCnpj(event.target.value);
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container className="containerPrincipal" maxWidth="lg">
              <Grid container style={{marginBottom: 10}} spacing={3}>
                <Grid item xs={12} md={12}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <a color="inherit" href="https://pinheiro.ma.gov.br/" title="Site">Site</a>
                    <a color="inherit" href="/" title="Transparência">Transparência</a>
                    <a color="inherit" href="/esic" title="Transparência">E-Sic</a>
                    <span color="textPrimary">Estatísticas</span>
                  </Breadcrumbs>
                </Grid>
              </Grid>
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className={'paperHeader'}>
                          <h2>Estatísticas</h2>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">Ano</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={ano}
                            onChange={handleChangeAno}
                            label="Ano"
                          >
                            <MenuItem value={2017}>2017</MenuItem>
                            <MenuItem value={2018}>2018</MenuItem>
                            <MenuItem value={2019}>2019</MenuItem>
                            <MenuItem value={2020}>2020</MenuItem>
                            <MenuItem value={2021}>2021</MenuItem>
                            <MenuItem value={2022}>2022</MenuItem>
                            <MenuItem value={2023}>2023</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid> 
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Quantidade de pedidos
                          </div>
                          <div className="paperConteudoChart">
                            <p>Confira a quantidade de pedidos de informação e respostas registrados no e-SIC Livre.</p>
                            <Chart
                              width={'500px'}
                              height={'300px'}
                              chartType="PieChart"
                              loader={<div>Carregando...</div>}
                              data={[
                                ['Task', 'Por Ano'],
                                ['Pedidos Atendidos', dadosSelect.atendido],
                                ['Pedidos Indeferidos', dadosSelect.indeferido]
                              ]}
                              options={{
                                title: ` Números de Pedidos ${dadosSelect.total}`,
                                is3D: true,
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Por Sexo
                          </div>
                          <div className="paperConteudoChart">
                            <p>Confira a quantidade de pedidos de informação e respostas registrados no e-SIC por sexo.</p>
                            <Chart
                              width={'500px'}
                              height={'300px'}
                              chartType="PieChart"
                              loader={<div>Carregando...</div>}
                              data={[
                                ['Task', 'Por Sexo'],
                                ['Homens', dadosSelect.homem],
                                ['Mulheres', dadosSelect.mulher]
                              ]}
                              options={{
                                title: ` Total ${dadosSelect.total}`,
                                is3D: true,
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Paper className="paper">
                          <div className="headerPaperTitle">
                            Por Orgão
                          </div>
                          <div className="paperConteudoChart">
                            <p>Confira a quantidade de pedidos de informação e respostas registrados no e-SIC por Orgão.</p>
                            <Chart
                              width={'500px'}
                              height={'300px'}
                              chartType="PieChart"
                              loader={<div>Carregando...</div>}
                              data={dadosSelect.orgao}
                              options={{
                                title: ` Números de Pedidos ${dadosSelect.total}`,
                                is3D: true,
                              }}
                              rootProps={{ 'data-testid': '1' }}
                            />
                          </div>
                        </Paper>
                      </Grid>
                    </Grid> 
                </main>
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
