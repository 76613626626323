import React, { useState, useEffect } from 'react';
import '../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import {veryAuth} from '../../auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Upload from '../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
export default function Index() {
    let history = useHistory(); 
    const [montarTela, setMontarTela] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState('');
    const [skeleton, seSkeleton] = useState(true);
    const [orgaos, setOrgaos] = useState([]);
    const [orgao, setOrgao] = useState('');
    const [retorno, setRetorno] = useState('');
    const [textSolicitacao, setTextSolicitacao] = useState('');
    const [files, setFiles] = useState('');
    const [state, setState] = useState({
      checkedA: true,
      checkedB: false,
    });
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        // Atualiza o titulo do documento usando a API do browser
        verificarPermissao()
        carregarOrgaos()
        setTimeout(function () {
            seSkeleton(false);
        }, 3000);
    }, []);

    function verificarPermissao(){
      setLoading(true)
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
        setLoading(false)
        if(result.data.role === 'usuario'){
          setMontarTela(true)
        }else{
          history.push('/');
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    function rota(rota){
      history.push(rota);
    }

    async function carregarOrgaos() {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get('orgaos', config);
            console.log('Result', result);
            setOrgaos(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }
    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };
    function handleChangeOrgao(event) {
        setOrgao(event.target.value)
    }
    function handleChangeRetorno(event) {
        setRetorno(event.target.value)
    }
    function handleChangeSolicitacao(event) {
        setTextSolicitacao(event.target.value)
    }
    function handleFiles(event) {
      console.log("File", event.target.value)
      setFiles(event.target.value)
    }

    function handleUpload(files){
      console.log("filessssssssss, ", files[0] )
      uploadArray = [];
      files.map(file => {
        uploadArray.push({
          file,
          // id: uniqueId(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: null,
          status: 1,
          type: file.type,
          albun: []
        })
      })

      setUploadedFiles(uploadArray.concat(uploadedFiles))

      uploadArray.forEach(element => {
        console.log("files dentro do array", element)
        // processUpload(element)
      });
    }

    async function submit(event) {
      event.preventDefault();
      setLoading(true);
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('orgao_id', orgao);
      form_data.append('forma_recebimento_esic', retorno);
      form_data.append('solicitacao_esic', textSolicitacao);
      form_data.append('identidade_preservada', state.checkedB ? 'sim' : 'nao');

      await api
        .post(`/esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Adicionando esic", res.data)
            setLoading(false);
            toast.success('🦄 Solicitação feito com sucesso, aguarde nosso retorno!', {
              position: "bottom-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            limparCampos()
            enviaInteracao(res.data.id)
            
        })
        .catch(error => {
          setLoading(false);
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
          toast.error(`Ops!, ${error.response.data.message}`, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        });

    }
    async function enviaInteracao(id) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
      form_data.append('esic_id', id);
      form_data.append('interacao', textSolicitacao);

      await api
        .post(`/interacoes-esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Enviado interações", res.data)
            enviarFiles(res.data.id)
        })
        .catch(error => {
          console.log("error: ", error, error.response)
          console.log("response: ", error.response.data.message)
  
        });

    }

    //recebe o id do esic
    async function enviarFiles(id){
      console.log("uploadArray >>", id, uploadArray)
      var token = localStorage.getItem('@tokenTransparencia');
      uploadedFiles.forEach(element => {
        console.log("element >>", element)
        let form_data_file = new FormData();
        form_data_file.append('arquivo', element.file, element.name);

        api
          .post(
            `/file-esic/`,
            form_data_file,
            {
              headers: {
                'content-type': 'multipart/form-data',
                Authorization: 'bearer ' + token,
                Accept: 'application/json',
              },
            },
            // config,
          )
          .then((res) => {
            console.log('res imagem, ', res);
            enviarDadosFile(res.data, id)
          })
          .catch((error) => {
            console.log('Erro subir imagem --->', error.response, error);
            throw error;
          });
      });
    }
    async function enviarDadosFile(res, id) {
      var token = localStorage.getItem('@tokenTransparencia');

      let form_data = new FormData();
    
      form_data.append('interacoes_esic_id', id);
      form_data.append('name_file', res.file.clientName ? res.file.clientName : 'sem-nome');
      form_data.append('type_file', res.file.type+ '/' + res.file.subtype);
      form_data.append('size_file', res.file.size);
      form_data.append('arquivo_esic_link', res.link);

      // form_data_file.append('avatar', {
      //   name: avatar.fileName,
      //   type: avatar.type,
      //   uri: avatar.uri,
      // });

      await api
        .post(`/files-esic`, form_data, {
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            Authorization: 'bearer ' + token
          }
        })
        .then(res => {
            console.log("Dados do arquivo adicionado com sucesso ", res.data)
            toast.info(`Arquivo ${res.data.name_file} enviado com sucesso`, {
              position: "bottom-left",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // setTimeout(function () {
            //     history.goBack()
            // }, 6000);
        })
        .catch(error => {
          setLoading(false);
          console.log("error dados do arquivo: ", error, error.response)
          console.log("response: ", error.response.data.message)
        });

    }


    function limparCampos(){
      setOrgao('')
      setRetorno('')
      setTextSolicitacao('')
      setUploadedFiles([])
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(orgao);
    validation.push(retorno);
    validation.push(textSolicitacao);

    const validForm = validation.reduce((all, v) => all && v);
    return (
        <div className={'root'}>
            <CssBaseline />
            {montarTela && (
              <>
                <Header />
                <main className="content">
                    <div className="appBarSpacer" />
                    <Container maxWidth="lg" className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                      className="linkBreadcrumbs"
                                      color="inherit"
                                      // href="/"
                                      onClick={() => {rota('/')}}
                                    >
                                        Dashboard
                                    </Link>
                                    <Link
                                      className="linkBreadcrumbs"
                                      color="inherit"
                                      // href="/esic"
                                      onClick={() => {rota('/portal/esic')}}
                                    >
                                        e-Sic
                                    </Link>
                                    <Typography variant="body2" color="textPrimary">
                                        Nova solicitação
                                    </Typography>
                                </Breadcrumbs>
                                <div className="headerPage">
                                    <h2>Acesso à Informação</h2>
                                    {/* <Button className="button-add" variant="contained" color="primary">
                                        Novo
                                    </Button> */}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={'paper'}>
                                  <div className="info">
                                    <p style={{fontWeight: 'bold'}}>Dicas para o seu pedido</p>
                                    <p>1) O e-SIC não é o canal adequado para apresentação de reclamações, solicitação de serviços, consultas sobre interpretação de normativos ou denúncias.</p>
                                    <p>2) Informações pessoais, inclusive identificação, não devem ser inseridas no detalhamento da solicitação a não ser que sejam essenciais para a caracterização do seu pedido.</p>
                                    <p>3) Especifique detalhadamente sua demanda, seja claro e objetivo. É importante que o órgão compreenda corretamente qual é o seu pedido para lhe enviar uma resposta adequada.</p>
                                    <p></p>
                                  </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                {skeleton && <Skeleton variant="rect" className="paper" />}
                                {!skeleton && (
                                    <Paper className={'paper'}>
                                        <div className="paperHeader">
                                            <Typography variant="subtitle1" gutterBottom>
                                                Cadastre uma solicitação
                                            </Typography>
                                        </div>
                                        <form onSubmit={submit} noValidate>
                                          <div className="paperContant">
                                            <Grid container spacing={3}>
                                              <Grid className="infoPrivado" item xs={12} md={12}>
                                                <Switch
                                                  checked={state.checkedB}
                                                  onChange={handleChange}
                                                  color="primary"
                                                  name="checkedB"
                                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                                <div className="info-privado">
                                                  Gostaria de ter a minha identidade preservada neste pedido, conforme o disposto no art. 10, § 7º da Lei nº 13.460/2017.
                                                </div>
                                              </Grid>
                                              <Grid item xs={12} md={6}>
                                                <FormControl fullWidth className="formControl" variant="outlined">
                                                  <InputLabel id="demo-simple-select-outlined-label">Orgão Público</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={orgao}
                                                    onChange={handleChangeOrgao}
                                                    label="Orgão Público"
                                                  >
                                                    <MenuItem value="">Selecione o orgão</MenuItem>
                                                    {orgaos.map((item) => (
                                                      <MenuItem key={item.id} value={item.id}>{item.nome_orgao}</MenuItem>
                                                    ))}
                                                    
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={6}>
                                                <FormControl fullWidth className="formControl" variant="outlined">
                                                  <InputLabel id="demo-simple-select-outlined-label">Forma de retorno</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={retorno}
                                                    onChange={handleChangeRetorno}
                                                    label="Forma de retorno"
                                                  >
                                                    <MenuItem value="email">Email</MenuItem>
                                                    <MenuItem value="correio">Correio</MenuItem>
                                                  </Select>
                                                </FormControl>
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <TextField
                                                  fullWidth
                                                  id="outlined-multiline-static"
                                                  label="Descreva de forma detalhada sua solicitação"
                                                  multiline
                                                  rows={4}
                                                  placeholder={'Descreva de forma detalhada sua solicitação'}
                                                  variant="outlined"
                                                  value={textSolicitacao}
                                                  onChange={handleChangeSolicitacao}
                                                />
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <Upload onUpload={handleUpload} />
                                                {uploadedFiles.map((item, key) => (
                                                  <img key={key} style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.type === 'image/jpeg' || item.type === 'image/png' || item.type === 'image/jpg' ? item.preview : 'https://images.vexels.com/media/users/3/135458/isolated/preview/79ca0179926d5e59f959e7fdb28f5513-iacute-cone-de-arquivo-azul-by-vexels.png'} alt=""/>
                                                  // <>
                                                  // {/* {item.typ === 'image/png' || item.typo === 'image/jpeg' }
                                                  //   <img style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.preview} alt=""/>
                                                  // </> */}
                                                  // <img style={{width: 100, height: 100, margin: 5, background: '#f5f5f5', objectFit: 'contain'}} src={item.preview} alt=""/>
                                                ))}
                                              </Grid>
                                              <Grid item xs={12} md={12}>
                                                <Button disabled={!validForm} type="submit" variant="contained" color="primary" disableElevation>
                                                  Enviar
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </form>
                                        
                                    </Paper>
                                )}
                            </Grid>
                        </Grid>
                        <Box pt={4}>
                            <Footer />
                        </Box>
                        <ToastContainer
                          position="bottom-left"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                    </Container>
                    {loading && (
                      <div className="loading">
                        <CircularProgress />
                      </div>
                    )}
                </main>
              </>
            )}
        </div>
    );
}
