import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import './style.css';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Footer from './Footer';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import LockOpen from '@material-ui/icons/LockOpen';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
// import Main from './Main';
// import Sidebar from './Sidebar';


const mainFeaturedPost = {
    title: 'Cadastro Único',
    description:
        "Um único cadastro para todos os serviços do cidadão",
    image: 'https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/barra-pinheiro-min-1-min.png',
    imgText: 'main image description',
};

const featuredPosts = [
    {
        title: 'Portal da Transparência',
        description:
            'Acesso ao sistema externo do portal da transparência do município.',
    },
    {
        title: 'e-Sic',
        description:
            'Através deste formulário, pessoas físicas ou jurídicas podem encaminhar pedidos de acesso à informação.',
    }
];

export default function Blog() {
    // const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    //Seus Dados
    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [mask, setMask] = useState("");
    const [email, setEmail] = useState('');
    const [contato, setContato] = useState('');
    const [whatsApp, setWhatsApp] = useState('');
    const [sexo, setSexo] = useState('');
    //endereços
    const [cep, setCep] = useState('');
    const [pais, setPais] = useState('Brasil');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');
    const [rua, setRua] = useState('');
    const [bairro, setBairro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [checked, setChecked] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState('CPF');
    //finalizar
    const [senha, setSenha] = useState('');

    let history = useHistory();
    // const classes = useStyles();

    useEffect(() => {
      const authenticated = localStorage.getItem('@tokenTransparencia');
      if(authenticated){
        toast.success(`Voaê está logado no sistema, será redirecionado.`, {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });  
        setTimeout(function () {
            history.push('/portal/esic')
        }, 6000);
      }
    }, []);

    function rota(rota){
      history.push(rota);
    }
    const handleOpen = (value) => {
      setOpen(true);
      if(value === 1){
        setInfo(value)
      }else if(value === 2){
        setInfo(value)
      }
    };

    //seus dados
    function handleChangeNomeCompleto(event){
      setNome(event.target.value);
    }
    function handleChangeCpf(event){
      setCpf(event.target.value);
    }
    function handleChangeEmail(event){
      setEmail(event.target.value);
    }
    function handleChangeContato(event){
      let textoAjustado;
      let textoAtual = event.target.value
      if(event.target.value.length <= 11) {
        //(98) 99999-9999 11 números
          const parte1 = textoAtual.slice(0,5);
          const parte2 = textoAtual.slice(5,9);
          textoAjustado = textoAtual       
          // textoAjustado = `${parte1}-${parte2}`        
          setContato(textoAjustado);
      }
      // setContato(event.target.value);
    }
    function handleChangeWhatsApp(event){
      let textoAjustado;
      let textoAtual = event.target.value
      if(event.target.value.length <= 11) {
        //(98) 99999-9999 11 números
          const parte1 = textoAtual.slice(0,5);
          const parte2 = textoAtual.slice(5,9);
          textoAjustado = textoAtual       
          // textoAjustado = `${parte1}-${parte2}`        
          setWhatsApp(textoAjustado);
      }
    }
    function handleChangeSexo(event){
      setSexo(event.target.value)
    }
    // Endereços
    function handleChangeCep(event){
      setCep(event.target.value);
    }
    function handleChangePais(event){
      setPais(event.target.value);
    }
    function handleChangeEstado(event){
      setEstado(event.target.value);
    }
    function handleChangeCidade(event){
      setCidade(event.target.value);
    }
    function handleChangeRua(event){
      setRua(event.target.value);
    }
    function handleChangeBairro(event){
      setBairro(event.target.value);
    }
    function handleChangeNumero(event){
      setNumero(event.target.value);
    }
    //Finaliza
    function handleChangeSenha(event){
      setSenha(event.target.value);
    }
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleChange = (event) => {
      setSelectedRadio(event.target.value);
    };

    async function salvar(event) {
      // event.preventDefault();
      setLoading(true)
  
      let form_data = new FormData();
  
      form_data.append("name", nome);
      form_data.append("email", email);
      form_data.append("password", senha);
      form_data.append("tipo_conta", selectedRadio);
      form_data.append("cpf_cnpj", cpf);
      form_data.append("cep", cep);
      form_data.append("pais", pais);
      form_data.append("estado", estado);
      form_data.append("cidade", cidade);
      form_data.append("rua", rua);
      form_data.append("numero", numero);
      form_data.append("bairro", bairro);
      form_data.append("telefone", contato);
      form_data.append("celular_whatsapp", whatsApp);
      form_data.append("sexo", sexo);
  
      await api
          .post(`/perfil`, form_data, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json"
            }
          })
          .then(res => {
              console.log("Adicionando usuarios", res.data)
              setLoading(false);
              toast.success('🦄 Cadastro feito com sucesso, vamos redirecionar.!', {
                position: "bottom-left",
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(function () {
                  history.goBack()
              }, 6000);
          })
          .catch(error => {
            setLoading(false);
            console.log("error: ", error, error.response)
            console.log("response: ", error.response.data.message)
            toast.error(`Ops!, ${error.response.data.message}`, {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          });
    }

    //validações
    const validation = [];
    validation.push('...');
    validation.push(nome);
    validation.push(cpf);
    validation.push(email);
    validation.push(contato);
    validation.push(senha);

    const validForm = validation.reduce((all, v) => all && v);


    const body = (
      <div className={"paperModal"}>
        <div className="simple-modal-title">
          {info === 1 ? "Como fazer o cadastro" : "Como fazer um pedido"}
          <Cancel onClick={handleClose} style={{color: '#fff', cursor: 'pointer'}} color="action" />
        </div>
        <div className="simple-modal-conteudo">
          {info === 1 && (
            <>
              <p>
                1. Entre na página do Lei de Acesso e clique no link "Cadastre-se", localizado à direita da página, dentro da caixa de login;
              </p>
              <p>
                2. A tela seguinte é a de cadastro do solicitante, nela o usuário irá preencher os dados pessoais e em seguida clicar no botão salvar ao final da página para que o seu cadastro seja efetuado;
              </p>
              <p>
                3. O usuário receberá uma notificação via e-mail, portanto é de suma importância que na tela de cadastro de solicitante o usuário insira um e-mail válido. Obs: Ao entrar na caixa de entrada do seu webmail se não encontrar o e-mail de confirmação, entre na caixa “Lixo” se você inseriu todos os dados de forma correta possivelmente o e-mail de confirmação poderá estar lá;
              </p>
              <p>
                4. No e-mail de confirmação, estará contido um link no qual o usuário deverá clicar, para que só assim seu cadastro seja validado;
              </p>
              <p>
                5. O link que o solicitante clicou no passo anterior irá redirecioná-lo (em nova janela) para a página confirmação de cadastro;
              </p>
            </>
          )}
          {info === 2 && (
            <>
              <p>
              1. Faça seu cadastro no sistema, e acesse o sistema com seu nome de usuário e senha;
              </p>
              <p>
                2. Acesse a aba "Fazer Solicitação" e descreva detalhadamente seu pedido;
              </p>
              <p>
                3. Abaixo, escolha a forma de retorno que deseja apos informar seu pedido;
              </p>
              <p>
                4. Apos descrever sua solicitação e definir a forma de retorno, o usuário deve clicar em enviar para registrar sua solicitação, uma tela de confimação deve aparecer;
              </p>
              <p>
                5. Acesse a aba "Solicitações Realizadas" para acompanhar suas solicitações e aguarde ate que seu pedido seja respondido.
              </p>
            </>
          )}
        </div>
      </div>
    );

    return (
        <React.Fragment>
            <CssBaseline />
            <Header />
            <Container maxWidth="lg">
                <main>
                    <MainFeaturedPost post={mainFeaturedPost} />
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <Paper className={'paperHeader'}>
                          <h2>Cadastre-se!</h2>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Paper className="paper">
                          <div className="formLogin">
                          <div className="formLoginHeader">
                            * 
                          </div>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Tipo de cadastro *</FormLabel>
                            <RadioGroup row aria-label="position" name="position" defaultValue="top">
                              <FormControlLabel
                                value="start"
                                control={
                                  <Radio
                                    color="primary" 
                                    checked={selectedRadio === 'CPF'}
                                    onChange={handleChange}
                                    value="CPF"
                                  />
                                }
                                label="Pessoa Física"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                value="start"
                                control={
                                  <Radio
                                    color="primary" 
                                    checked={selectedRadio === 'CNPJ'}
                                    onChange={handleChange}
                                    value="CNPJ"
                                  />
                                }
                                label="Pessoa Juridica"
                                labelPlacement="start"
                              />
                            </RadioGroup>
                          </FormControl>
                          <form noValidate>
                            <Grid container spacing={3}>
                              <Grid item xs={12} md={8}>
                                <TextField
                                  required
                                  variant="outlined"
                                  id="nome"
                                  name="nome"
                                  label={selectedRadio === 'CPF' ? 'Nome completo' : 'Razão Social'}
                                  fullWidth
                                  autoComplete="name"
                                  onChange={handleChangeNomeCompleto}
                                  value={nome}
                                />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <CpfCnpj
                                  className="customizedInput"
                                  placeholder={selectedRadio === 'CPF' ? 'Digite seu CPF' : 'Digite o CNPJ'}
                                  type="tel"
                                  value={cpf}
                                  onChange={(event, type) => {
                                    console.log("type", type)
                                    setCpf(event.target.value);
                                    setSelectedRadio(type)
                                    setMask(type === "CPF");
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField
                                  required
                                  variant="outlined"
                                  id="email"
                                  name="email"
                                  label="Email"
                                  fullWidth
                                  autoComplete="email"
                                  onChange={handleChangeEmail}
                                  type="email"
                                  value={email}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="formControl" variant="outlined">
                                  <InputLabel id="demo-simple-select-outlined-label">Sexo</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    onChange={handleChangeSexo}
                                    value={sexo}
                                    label="Estado"
                                  >
                                    <MenuItem value="Feminino">Feminino</MenuItem>
                                    <MenuItem value="Masculino">Masculino</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField
                                  required
                                  variant="outlined"
                                  id="senha"
                                  name="senha"
                                  label="Defina uma senha"
                                  fullWidth
                                  autoComplete="senha"
                                  onChange={handleChangeSenha}
                                  value={senha}
                                  type="password"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  required
                                  variant="outlined"
                                  id="contato"
                                  name="contato"
                                  label="Telefone/Celular"
                                  fullWidth
                                  autoComplete="tel"
                                  onChange={handleChangeContato}
                                  value={contato}
                                  type="number"
                                  placeholder="0000000000"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  variant="outlined"
                                  id="whatsapp"
                                  name="whatsapp"
                                  label="Whatsapp"
                                  fullWidth
                                  autoComplete="tel"
                                  onChange={handleChangeWhatsApp}
                                  value={whatsApp}
                                  type="number"
                                  placeholder="00000000000"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  variant="outlined"
                                  id="cep"
                                  name="cep"
                                  label="CEP"
                                  fullWidth
                                  autoComplete="postal-code"
                                  onChange={handleChangeCep}
                                  value={cep}
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  variant="outlined"
                                  id="bairro"
                                  name="bairro"
                                  label="Bairro"
                                  fullWidth
                                  // autoComplete="address-line2"
                                  onChange={handleChangeBairro}
                                  value={bairro}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  variant="outlined"
                                  id="rua"
                                  name="rua"
                                  label="Rua"
                                  fullWidth
                                  // autocomplete="street-address"
                                  onChange={handleChangeRua}
                                  value={rua}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <TextField
                                  variant="outlined"
                                  id="numero"
                                  name="numero"
                                  label="Número"
                                  fullWidth
                                  // autoComplete="cc-number"
                                  onChange={handleChangeNumero}
                                  value={numero}
                                  type="number"
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FormControl fullWidth className="formControl" variant="outlined">
                                  <InputLabel id="demo-simple-select-outlined-label">Estado</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    onChange={handleChangeEstado}
                                    value={estado}
                                    label="Estado"
                                  >
                                    <MenuItem value="AC">Acre</MenuItem>
                                    <MenuItem value="AL">Alagoas</MenuItem>
                                    <MenuItem value="AP">Amapá</MenuItem>
                                    <MenuItem value="AM">Amazonas</MenuItem>
                                    <MenuItem value="BA">Bahia</MenuItem>
                                    <MenuItem value="CE">Ceará</MenuItem>
                                    <MenuItem value="DF">Distrito Federal</MenuItem>
                                    <MenuItem value="ES">Espírito Santo</MenuItem>
                                    <MenuItem value="GO">Goiás</MenuItem>
                                    <MenuItem value="MA">Maranhão</MenuItem>
                                    <MenuItem value="MT">Mato Grosso</MenuItem>
                                    <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                                    <MenuItem value="MG">Minas Gerais</MenuItem>
                                    <MenuItem value="PA">Pará</MenuItem>
                                    <MenuItem value="PB">Paraíba</MenuItem>
                                    <MenuItem value="PR">Paraná</MenuItem>
                                    <MenuItem value="PE">Pernambuco</MenuItem>
                                    <MenuItem value="PI">Piauí</MenuItem>
                                    <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                                    <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                                    <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                                    <MenuItem value="RO">Rondônia</MenuItem>
                                    <MenuItem value="RR">Roraima</MenuItem>
                                    <MenuItem value="SC">Santa Catarina</MenuItem>
                                    <MenuItem value="SP">São Paulo</MenuItem>
                                    <MenuItem value="SE">Sergipe</MenuItem>
                                    <MenuItem value="TO">Tocantins</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <div className="btns">
                              <Button 
                                disabled={!validForm}
                                onClick={() =>{
                                  salvar()
                                }}
                                variant="contained" 
                                color="primary" 
                                className={"btnEntrar"}
                              >
                                Cadastrar
                              </Button>
                              <Button onClick={() => rota('esic')} variant="contained" color="primary" >
                                Já tenho cadastro, fazer login
                              </Button>
                            </div>
                          </form>
                          </div>
                        </Paper>
                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                        <Paper>xs=3</Paper>
                      </Grid> */}
                    </Grid>
                </main>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className={"modal"}
                >
                  {body}
                </Modal>
                <ToastContainer
                  position="bottom-left"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            </Container>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
            <Footer />
        </React.Fragment>
    );
}
