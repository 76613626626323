import React, { useState, useEffect } from 'react';
/* eslint-disable */
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import LockOpen from '@material-ui/icons/LockOpen';
import api from '../../services/api';

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {'Copyright © '}
//             <Link color="inherit" href="https://material-ui.com/">
//                 Your Website
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export default function SignIn() {
    const classes = useStyles();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [mask, setMask] = useState("");
    const [senha, setSenha] = useState('');

    useEffect(() => {
      const authenticated = localStorage.getItem('@tokenTransparencia');
      if(authenticated){
        history.push('/portal/admin/dashboard')
      }
    }, []);

    function rota(rota){
      history.push(rota);
    }

    function handleChangeSenha(event) {
      setSenha(event.target.value);
    }


    async function login(event) {
        event.preventDefault();
        if(cpfCnpj === ''){
          toast.error(`O CPF ou CNPJ é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        if(senha === ''){
          toast.error(`Sua senha é necessário.`, {
              position: 'bottom-left',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
          }); 
          return;
        }
        setLoading(true);
        try {
            const response = await api.post('/sessions', {
                cpf_cnpj: cpfCnpj.trim(),
                password: senha
            });
            console.log('Sucesso, ', response.data.token);
            //Alert.alert("Sucesso", response.data.token);
            localStorage.setItem('@tokenTransparencia', response.data.token);
            // buscarPerfil(response.data.token);
            // await AsyncStorage.setItem('@tokenEmpresa', response.data.token);
            setLoading(false);
            history.push('/portal/admin/dashboard');
        } catch (error) {
            setLoading(false);
            // setOpenAlert(true);
            // setTypeAlert('error');
            // setMsgAlert('Login ou senha, incorretos!');
            console.log('Error', error, error.response);
            if(error.response.data[0].field === 'cpf_cnpj' && error.response.data[0].message === 'Cannot find user with provided cpf_cnpj'){
              toast.error(`O CPF ou CNPJ nao foi encontrado.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
            if(error.response.data[0].field === 'password' && error.response.data[0].message === 'Invalid user password'){
              toast.error(`Você está usando uma senha inválida.`, {
                  position: 'bottom-left',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined
              });  
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <form onSubmit={login} className={classes.form} noValidate>
                    <FormControl fullWidth className="formControl" variant="outlined">
                        <CpfCnpj
                          className="customizedInput"
                          placeholder="Digite um CPF ou CNPJ"
                          type="tel"
                          value={cpfCnpj}
                          onChange={(event, type) => {
                            setCpfCnpj(event.target.value);
                            setMask(type === "CPF");
                          }}
                        />
                    </FormControl>
                    <FormControl fullWidth className="formControl"  variant="outlined">
                        <InputLabel htmlFor="outlined-senha">Senha de acesso</InputLabel>
                        <OutlinedInput
                          id="outlined-senha"
                          value={senha}
                          required
                          onChange={handleChangeSenha}
                          startAdornment={
                            <InputAdornment position="start">
                              <LockOpen color="action" />
                            </InputAdornment>
                          }
                          label={'Senha de acesso'}
                        />
                      </FormControl>
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Lembrar"
                    /> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Entrar
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Esqueci minha senha!
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/cadastro" variant="body2">
                                {'Criar conta'}
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={8}>{/* <Copyright /> */}</Box>
            {loading && (
              <div className="loading">
                <CircularProgress />
              </div>
            )}
        </Container>
    );
}
