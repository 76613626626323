import React, { useState, useEffect } from 'react';
import '../../../styles/global.css';
import './style.css';
// import { makeStyles } from '@material-ui/core/styles';
/* eslint-disable */
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Header from '../../../Components/Header';
import Footer from '../../../Components/Footer';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import Funcionario from '../../../Components/Tables/funcionario';
import api from '../../../services/api';
import {veryAuth} from '../../../auth';
import Upload from '../../../Components/Upload';
import filesize from 'filesize';
let uploadArray = [];
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns'
import { FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

export default function Index() {
    const [skeleton, seSkeleton] = useState(true);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [total, setTotal] = useState(0);
    const [lotacao, setLotacao] = useState(null);
    // const [ano, setAno] = useState(format(new Date(), 'yyyy'));

    const [termoSearch, setTermoSearch] = useState('');
    const [search, setSearch] = useState([]);
    const [roles, setRoles] = useState('');

    const [dadosJson, setDadosJson] = useState([]);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [nomeFile, setNomeFile] = useState('');
    const [process, setProcess] = useState(0);

    const [nome, setNome] = useState('Nome');
    const [mes, setMes] = useState(new Date().getMonth() + 1);
    const [ano, setAno] = useState(new Date().getFullYear());

    var ArrayFuncionario = [];

    const list = '<hello>World</hello>'

    let history = useHistory();
    useEffect(() => {
      aplicarMes()
      verificarPermissao()
      carregarFuncionarios(ano, nome);
      // lerXml()
      
      setTimeout(function () {
          seSkeleton(false);
      }, 3000);
    }, []);

    function aplicarMes(){
      var mesAtual = new Date().getMonth() + 1
      console.log('mesAtual', mesAtual)
    }

    function handleChangeDadosJson(event){
      setDadosJson(event.target.value);
    }
    function onchangeLotacao(event){
      setLotacao(event.target.value);
    }
    function onchangeMes(event){
      setMes(event.target.value);
    }
    function onchangeAno(event){
      setAno(event.target.value);
    }

    function verificarPermissao(){
      veryAuth()
      .then((result) => {
        console.log("Resultado de roles", result);
        setRoles(result.data.role)
      })
      .catch((error) => {
        console.error('Opa! Houve um erro ao buscar permissão:', error.message);
      });
    }

    // function toJson(file){
    //   xlsxParser
    //     .onFileSelection(file)
    //     .then(data => {
    //       var parsedData = data;
    //       console.log("parsedData ", parsedData)
    //       // clean(parsedData)
    //     });
    // }

    // function handleUpload(files){
    //   console.log("filessssssssss, ", files[0] )
    //   files.map(file => {
    //     if(file.type === "application/pdf"){
    //       // setAvisoFile(false)
    //       setNomeFile(prepararFileName(file.name))
    //       uploadArray.push({
    //         file,
    //         // id: uniqueId(),
    //         name: file.name,
    //         readableSize: filesize(file.size),
    //         preview: URL.createObjectURL(file),
    //         progress: 0,
    //         uploaded: false,
    //         error: false,
    //         url: null,
    //         status: 1,
    //         type: file.type,
    //         albun: []
    //       })
    //     }else{
    //       // setAvisoFile(true)
    //     }
    //   })

    //   setUploadedFiles(uploadArray.concat(uploadedFiles))
    // }

    // async function enviarJson(){
    //   // setLoading(true)
    //   setProcess(0)
    //   var token = localStorage.getItem('@tokenTransparencia');
    //   uploadedFiles.forEach(element => {
    //     console.log("element >>", element)
    //     let form_data_file = new FormData();
    //     form_data_file.append('arquivo', element.file, element.name);

    //     api
    //       .post(
    //         `/funcionario/`,
    //         form_data_file,
    //         {
    //           headers: {
    //             'content-type': 'multipart/form-data',
    //             Authorization: 'bearer ' + token,
    //             Accept: 'application/json',
    //           },
    //           // Old version
    //           onUploadProgress: e => {
    //             const progress = parseInt(Math.round((e.loaded * 100) / e.total))
    //             console.log("Progresso: ", progress)
    //             setProcess(progress)
    //             // updateFile(uploadedF.id, {
    //             //   progress,
    //             // })
    //           },
    //         },
    //         // config,
    //       )
    //       .then((res) => {
    //         console.log('res json, ', res);
    //       })
    //       .catch((error) => {
    //         console.log('Erro subir json --->', error.response, error);
    //         throw error;
    //       });
    //   });
    // }

    // usar o site https://products.aspose.app/cells/pt/conversion/xlsx-to-json para converter em json, testar com https://jsononline.net/pt/string-to-json
    function clean() {
      var json = JSON.parse(dadosJson)
      console.log("obj ", typeof dadosJson, JSON.parse(dadosJson))
      // if(json === null) return
      for (let o = 0; o < json.length; o++) {
        const element = json[o];
        if(element != null){
          console.log('element', element)
          let obj = element;
          let cleanObj = {};
  
          Object.keys(obj).forEach(val => {
            const newVal = obj[val];
            cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
          });
  
          // console.log("cleanObj", cleanObj)
          if(JSON.stringify(cleanObj).length > 10){
            // console.log("cleanObj===>", cleanObj)
            ArrayFuncionario.push(cleanObj)
            // cadastrarFuncionario()
          }
        }
      }
    }

    async function cadastrarFuncionario(){
      // var valueObjeto = Object.values(jsn);
      console.log("cleanObj cadastrarFuncionario", ArrayFuncionario)

      // 0: "Nome Cargo"
      // 1: "Dt. Admiss�o"
      // 2: "CPF"
      // 3: "Matr�cula"
      // 4: "Nome"
      // 5: "Proventos"
      // 6: "Descontos"
      // 7: "L�quido"

      for (let f = 0; f < ArrayFuncionario.length; f++) {
        const element = ArrayFuncionario[f];
        console.log("cleanObj element", element)
        var valueObjeto = Object.values(element);
        console.log("valueObjeto", valueObjeto)
        cadatrarBanco(valueObjeto)
      }

    }

    async function cadatrarBanco(valueObjeto){
      var token = localStorage.getItem('@tokenTransparencia');
      console.log('valueObjeto', valueObjeto)
      if(valueObjeto[0] && valueObjeto[1] && valueObjeto[2] && valueObjeto[3] && valueObjeto[4] && valueObjeto[5] && valueObjeto[6] && valueObjeto[7]){
        var newData = valueObjeto.length === 9 ?  valueObjeto[2] : valueObjeto[1];
        console.log('value', valueObjeto[1], valueObjeto.length)
        console.log('newData obg', newData, mes, ano)
        var newDataArray = newData.split("/");
        var newDataFormatada = newDataArray[2]+'-'+newDataArray[1]+'-'+newDataArray[0];

        // console.log('cadatrarBanco ====> ', valueObjeto, newDataFormatada)
        var funcionario_nome = valueObjeto[4];
        var funcionario_cargo = valueObjeto[0];
        var funcionario_funcao = valueObjeto[0];
        var funcionario_data_admissao = newDataFormatada;
        var funcionario_cpf = valueObjeto[2];
        var funcionario_matricula = valueObjeto[3];
        var funcionario_proventos = valueObjeto[5];
        var funcionario_descontos = valueObjeto[6];
        var funcionario_liquido = valueObjeto[7];
        var funcionario_tipo = 'Efetivo'

        let form_data = new FormData();
        form_data.append('funcionario_nome', funcionario_nome);
        form_data.append('funcionario_cargo', funcionario_cargo);
        form_data.append('funcionario_funcao', funcionario_funcao);
        form_data.append('funcionario_data_admissao', funcionario_data_admissao);
        form_data.append('funcionario_mes', mes);
        form_data.append('funcionario_ano', ano);
        form_data.append('funcionario_cpf', funcionario_cpf);
        form_data.append('funcionario_matricula', funcionario_matricula ? funcionario_matricula : '');
        form_data.append('funcionario_proventos', funcionario_proventos ? funcionario_proventos : 0);
        form_data.append('funcionario_descontos', funcionario_descontos ? funcionario_descontos : 0);
        form_data.append('funcionario_liquido', funcionario_liquido ? funcionario_liquido : 0);
        form_data.append('funcionario_tipo', funcionario_tipo);
        form_data.append('funcionario_lotacao', lotacao);

        await api
          .post(`/funcionario`, form_data, {
            headers: {
              "content-type": "application/json",
              Accept: "application/json",
              Authorization: 'bearer ' + token
            }
          })
          .then(res => {
              console.log("Salvando funcionario ", res.data)
              setDadosJson([])
              
          })
          .catch(error => {
            console.log('valueObjeto[0]', valueObjeto[0])
            console.log('valueObjeto[1]', valueObjeto[1])
            console.log('valueObjeto[2]', valueObjeto[2])
            console.log('valueObjeto[3]', valueObjeto[3])
            console.log('valueObjeto[4]', valueObjeto[4])
            console.log('valueObjeto[5]', valueObjeto[5])
            console.log('valueObjeto[6]', valueObjeto[6])
            console.log('valueObjeto[7]', valueObjeto[7])
            console.log("error dados do arquivo: ", error, error.response)
          });
      }else{
        console.log('VAzio')
      }

    }

    function chamou(newPagina, newPerPage){
      console.log("newPagina, newPerPage ", newPagina, newPerPage)
      carregarFuncionarios(ano, nome, newPagina, newPerPage)
    }

    async function carregarFuncionarios(anoV, nomeV, pagina = page, porPagina = perPage) {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`funcionario/${anoV}/${nomeV}/${pagina}/${porPagina}`);
            console.log('Listar todos os funcionario', result);
            setData(result.data);
            setLastPage(result.lastPage);
            setPage(result.page);
            setTotal(result.total);
            // setLoading(false)
        } catch (error) {
            console.log('Erro ao ao listar funcionarios', error, error.response);
        }
    }
    async function searchEsic(event) {
        var token = localStorage.getItem('@tokenTransparencia');
        var config = {
            headers: { Authorization: 'bearer ' + token }
        };
        try {
            const { data: result } = await api.get(`/search-esic/${event.target.value}`, config);
            // console.log('Resultado da pesquisa de esic', result);
            setData(result);
        } catch (error) {
            console.log('Erro ao deletar', error, error.response);
        }
    }

    
    // const classes = useStyles();
    function rota(rota){
      history.push(rota);
    }
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    //table
    function createData(
      id,
      funcionario_cargo,
      funcionario_cpf,
      funcionario_data_admissao,
      funcionario_descontos,
      funcionario_funcao,
      funcionario_liquido,
      funcionario_matricula,
      funcionario_nome,
      funcionario_proventos,
      funcionario_tipo,
      funcionario_lotacao,
    ) {
        return {
          id,
          funcionario_cargo,
          funcionario_cpf,
          funcionario_data_admissao,
          funcionario_descontos,
          funcionario_funcao,
          funcionario_liquido,
          funcionario_matricula,
          funcionario_nome,
          funcionario_proventos,
          funcionario_tipo,
          funcionario_lotacao,
        };
    }

    var rows = [];
    if (data) {
        rows = data.map((row) => {
            return createData(
              row.id, 
              row.funcionario_cargo, 
              row.funcionario_cpf,
              row.funcionario_data_admissao, 
              row.funcionario_descontos, 
              row.funcionario_funcao,
              row.funcionario_liquido,
              row.funcionario_matricula,
              row.funcionario_nome,
              row.funcionario_proventos,
              row.funcionario_tipo,
              row.funcionario_lotacao,
            );
        });
    }

    const columns = [
        { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
        { id: 'funcionario_cargo', numeric: false, disablePadding: true, label: 'Cargo/Função/Lotacão' },
        { id: 'funcionario_cpf', numeric: true, disablePadding: false, label: 'CPF' },
        { id: 'funcionario_data_admissao', numeric: true, disablePadding: false, label: 'Admissão' },
        { id: 'funcionario_proventos', numeric: true, disablePadding: false, label: 'R$ Proventos' },
        { id: 'funcionario_descontos', numeric: true, disablePadding: false, label: 'R$ Desconto' },
        { id: 'funcionario_liquido', numeric: true, disablePadding: false, label: 'R$ Liquído' },
        { id: 'funcionario_tipo', numeric: true, disablePadding: false, label: 'Tipo' },
        { id: 'acao', numeric: true, disablePadding: false, label: '' },
    ];

    const mesList = [
      {
        'value': 1,
      },
      {
        'value': 2,
      },
      {
        'value': 3,
      },
      {
        'value': 4,
      },
      {
        'value': 5,
      },
      {
        'value': 6,
      },
      {
        'value': 7,
      },
      {
        'value': 8,
      },
      {
        'value': 9,
      },
      {
        'value': 10,
      },
      {
        'value': 11,
      },
      {
        'value': 12,
      }
    ]
    const anoList = [
      {
        'value': '2024',
      },
      {
        'value': '2023',
      },
      {
        'value': '2022',
      },
      {
        'value': '2021',
      },
      {
        'value': '2020',
      },
      {
        'value': '2019',
      },
    ]
    const departamentoLotacao = [
      {
        'value': 'MDE',
      },
      {
        'value': 'AGENTE COMUNITARIO DE SAUDE',
      },
      {
        'value': 'SEC. DE SAUDE',
      },
      {
        'value': 'PSF PROGRAMA SAUDE DA FAMILIA',
      },
      {
        'value': 'SEC. DESENV. SOCIAL',
      },
      {
        'value': 'SEC. AGRICULTURA',
      },
      {
        'value': 'SEC. EDUCAÇÂO',
      },
      {
        'value': 'SEC. MEIO AMBIENTE',
      },
      {
        'value': 'PROCURADORIA',
      },
      {
        'value': 'SEC. ADMINISTRAÇÃO',
      },
      {
        'value': 'SEC. SEGURANÇA',
      },
      {
        'value': 'CONSELHO TUTELAR',
      },
      {
        'value': 'SEC. INFRAESTRUTURA',
      },
      {
        'value': 'SEC. INDUSTRIA E COMERCIO',
      },
      {
        'value': 'GABINENTE',
      },
      {
        'value': 'CSL – Comissões Setoriais de Licitação',
      },
      {
        'value': 'AGENTE COMUNITÁRIO',
      },
      {
        'value': '',
      },
    ]

    return (
        <div className={'root'}>
            <CssBaseline />
            <Header />
            <main className="content">
                <div className="appBarSpacer" />
                <Container maxWidth="lg" className="container">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/portal/admin/dashboard">
                                    Dashboard
                                </Link>
                                {/* <Link
                                    className="linkBreadcrumbs"
                                    color="inherit"
                                    href="/getting-started/installation/"
                                    onClick={handleClick}>
                                    Core
                                </Link> */}
                                <Typography variant="body2" color="textPrimary">
                                    Recursos Humanos
                                </Typography>
                            </Breadcrumbs>
                            <div className="headerPage">
                                <h2>Recursos Humanos</h2>
                                {/* <Button className="button-add" variant="contained" color="primary">
                                    Novo
                                </Button> */}
                            </div>
                        </Grid>
                       
                        {/* <Grid item xs={12} md={12} lg={12}>
                            <Paper className={'paperSearch'}>
                                <div className="paperHeader">
                                    <Typography variant="subtitle1" gutterBottom>
                                      Filtrar Solicitação
                                    </Typography>
                                </div>
                                <TextField
                                  fullWidth
                                  className="textField"
                                  label="Digite o protocolo da solicitação!"
                                  variant="outlined"
                                  type="number"
                                  onChange={searchEsic}
                                />
                            </Paper>
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          <FormControl style={{width: '100%'}} variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Lotação</InputLabel>
                            <Select
                              style={{width: '100%'}}
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={lotacao}
                              onChange={onchangeLotacao}
                              label="Lotação"
                            >
                              {departamentoLotacao.map((item, key) => (
                                <MenuItem key={key} value={item.value}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl style={{width: '100%'}} variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-ano">Ano</InputLabel>
                            <Select
                              style={{width: '100%'}}
                              labelId="demo-simple-select-outlined-ano"
                              id="demo-simple-select-outlined"
                              value={ano}
                              onChange={onchangeAno}
                              label="Ano"
                            >
                              {anoList.map((item, key) => (
                                <MenuItem key={key} value={item.value}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl style={{width: '100%'}} variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-mes">Mês</InputLabel>
                            <Select
                              style={{width: '100%'}}
                              labelId="demo-simple-select-outlined-mes"
                              id="demo-simple-select-outlined"
                              value={mes}
                              onChange={onchangeMes}
                              label="Mês"
                            >
                              {mesList.map((item, key) => (
                                <MenuItem key={key} value={item.value}>{item.value}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {/* <Upload onUpload={handleUpload} /> */}
                          <TextField
                            fullWidth
                            id="outlined-multiline-static"
                            label="Dados Json"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={dadosJson}
                            onChange={handleChangeDadosJson}
                          />
                        </Grid>
                        {/* <Grid item md={4}>
                          <TextField 
                            id="outlined-basic" 
                            label="Outlined" 
                            variant="outlined" 
                          />
                        </Grid>  */}
                        <Grid item xs={12} sm={12}>
                          {/* <Upload onUpload={handleUpload} /> */}
                          <Button onClick={() => {clean()}}>
                            Montar Array
                          </Button>
                          <Button onClick={() => {cadastrarFuncionario()}}>
                            Enviar
                          </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            {skeleton && <Skeleton variant="rect" className="paper" />}
                            {!skeleton && (
                              <>
                                {data.length !== 0 && (
                                  <Paper className={'paper'}>
                                      {/* <div className="paperHeader">
                                          <Typography variant="subtitle1" gutterBottom>
                                              Funcionários
                                          </Typography>
                                      </div> */}
                                      <div className="paperContant">
                                        <Funcionario 
                                        columns={columns} 
                                        rows={rows} 
                                        roles={roles} 
                                        lastPage={lastPage}
                                        page={page}
                                        perPage={perPage}
                                        total={total}
                                        chamou={chamou}
                                        />
                                      </div>
                                  </Paper>
                                )}
                                {data.length === 0 && (
                                  <Paper className={'paper'}>
                                      <div className="paperContantDois">
                                          <div>Nenhum registro encontrado</div>
                                      </div>
                                  </Paper>
                                )}
                              </>
                            )}
                        </Grid>
                    </Grid>
                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}
