import React from 'react';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import '../../styles/global.css';
import Modal from '@material-ui/core/Modal';
import Cancel from '@material-ui/icons/Cancel';



export default function Index(props) {
    return (
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={"modal"}
      >
        <div className={"paperModal"}>
          <div className="simple-modal-title">
            Arquivos para a licitação
            <Cancel onClick={props.handleClose} style={{color: '#fff', cursor: 'pointer'}} color="action" />
          </div>
          <div className="simple-modal-conteudo">
            {props.conteudoModal}
          </div>
        </div>
      </Modal>
    );
}
